import './index.scss';

import { inject, observer } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Modal } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import cloneDeep from 'lodash/cloneDeep';

import CloseWithRoundIcon from '../../assets/icons/ai_close_icon.svg?react';

import HelpModal from '../HelpModal';
import ProfileFilter from '../ProfileFilter';
import Profile from '../Profile';
import ProfileNew from '../ProfileNew';
import CompanyProfile from '../CompanyProfile';
import PaywallSpeedBump from '../Paywall/PaywallSpeedBump';

const ModalViews = (props) => {
  const location = useLocation();
  const posthog = usePostHog();
  const flags = useFlags();

  useEffect(() => {
    window.addEventListener('popstate', onBrowserBackClick);
    return () => {
      window.removeEventListener('popstate', onBrowserBackClick);
    };
  }, []);

  useEffect(() => {
    if (props.modalDataStore.profileModal.isVisible) {
      posthog?.capture('prospect_profile_opened');
    }
    if (props.modalDataStore.companyProfileModal.isVisible)
      posthog?.capture('company_profile_opened');
  }, [
    posthog,
    props.modalDataStore.companyProfileModal.isVisible,
    props.modalDataStore.profileModal.isVisible,
  ]);

  useEffect(() => {
    /**
     * fromQuery is set to true, when the profile is opened
     * based on the query params in URL, and in that case for
     * history change we don't need to close the profile modal
     * In all other cases, when route changes, close profile modal
     */
    if (
      !props.modalDataStore.profileModal.fromQuery &&
      !props.modalDataStore.companyProfileModal.fromQuery
    ) {
      handleCancel();
      props.modalDataStore.eventHelpModalVisible = false;
      props.modalDataStore.taxDescriptionModalVisible = false;
      props.modalDataStore.wealthModalVisible = false;
    } else {
      props.modalDataStore.profileModal.fromQuery = false;
      props.modalDataStore.companyProfileModal.fromQuery = false;
    }
  }, [location]);

  const onBrowserBackClick = (e) => {
    /** close modal on browser back click, keeping the search criterias in advancedFilter.
     * so add same path in history to avoid url change(added in ComponentDidmount of each Modal Component)
     * browser back click will work as same removing the dummy path added  */
    if (
      props.modalDataStore.companyProfileModal.isVisible ||
      props.modalDataStore.profileModal.isVisible
    ) {
      handleCancel();
    } else {
      return;
    }
  };

  const handleCancel = () => {
    props.modalDataStore.resetAll();
  };

  const onBackArrowClick = () => {
    const { trackModal } = props.modalDataStore;
    posthog?.capture('profile_back_clicked');
    if (trackModal.length > 1) {
      const removed = trackModal.pop();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      removed.modal === 'company'
        ? props.modalDataStore.resetCompanyProfileModal()
        : props.modalDataStore.resetProfileModal();

      const lastItem = trackModal[trackModal.length - 1];

      if (lastItem && lastItem.data) {
        if (lastItem.modal === 'company') {
          props.modalDataStore.companyProfileModal.id = lastItem.data.id;
          props.modalDataStore.companyProfileModal.orgName =
            lastItem.data.orgName;
          props.modalDataStore.companyProfileModal.isVisible = true;
        } else {
          props.modalDataStore.profileModal = cloneDeep(lastItem.data);
          props.modalDataStore.profileModal.isVisible = true;
          props.modalDataStore.profileModal.fetchProfileData = true;
        }
      }
    } else {
      handleCancel();
    }
  };

  const handleOnUpgradePremium = () => {
    console.log('paywall upgrade to premium');
  };

  const handleProfileUnlock = () => {
    console.log('paywall unlock profile');
  };

  if (
    !props.modalDataStore.companyProfileModal.isVisible &&
    !props.modalDataStore.profileModal.isVisible &&
    !props.modalDataStore.eventHelpModalVisible &&
    !props.modalDataStore.taxDescriptionModalVisible &&
    !props.modalDataStore.paywallSpeedBump.visible &&
    !props.modalDataStore.wealthModalVisible &&
    !props.modalDataStore.filterModal.isVisible
  )
    return null;

  return (
    <>
      {(props.modalDataStore.companyProfileModal.isVisible ||
        props.modalDataStore.profileModal.isVisible) && (
        <Modal
          width="100%"
          wrapClassName={
            flags && flags.dntfd8933NewProfileView
              ? 'parent-profile-modal parent-profile-modal-new'
              : 'parent-profile-modal'
          }
          open
          footer={null}
          mask={false}
          maskClosable={false}
          onCancel={onBackArrowClick}
          closable={false}
          closeIcon={<CloseWithRoundIcon />}
        >
          {props.modalDataStore.trackModal.length > 1 &&
            (!flags || !flags.dntfd8933NewProfileView) && (
              <div className="multiple-profiles-stack" />
            )}

          {props.modalDataStore.companyProfileModal.isVisible && (
            <CompanyProfile
              className="company-profile"
              onBackArrowClick={onBackArrowClick}
            />
          )}

          {props.modalDataStore.profileModal.isVisible && (
            <div id="Profile">
              {flags && flags.dntfd8933NewProfileView ? (
                <ProfileNew onBackArrowClick={onBackArrowClick} />
              ) : (
                <Profile onBackArrowClick={onBackArrowClick} />
              )}
            </div>
          )}
        </Modal>
      )}
      {props.modalDataStore.eventHelpModalVisible && (
        <HelpModal
          type="event"
          onClose={() => {
            props.modalDataStore.eventHelpModalVisible = false;
          }}
        />
      )}
      {props.modalDataStore.taxDescriptionModalVisible && (
        <HelpModal
          type="tax"
          onClose={() => {
            props.modalDataStore.taxDescriptionModalVisible = false;
          }}
        />
      )}
      {props.modalDataStore.wealthModalVisible && (
        <HelpModal
          type="wealth"
          onClose={() => {
            props.modalDataStore.wealthModalVisible = false;
          }}
        />
      )}
      {props.modalDataStore.paywallSpeedBump.visible && (
        <Modal
          width="40%"
          wrapClassName="paywall-speed-bump-modal"
          open
          centered
          footer={null}
          mask={true}
          maskClosable={true}
          onCancel={() =>
            (props.modalDataStore.paywallSpeedBump.visible = false)
          }
          closable={false}
        >
          <PaywallSpeedBump
            handleOnClose={() =>
              (props.modalDataStore.paywallSpeedBump.visible = false)
            }
            content={props.modalDataStore.paywallSpeedBump.content}
            handleProfileUnlock={handleProfileUnlock}
            handleOnUpgradePremium={handleOnUpgradePremium}
          />
        </Modal>
      )}
      {props.modalDataStore.filterModal.isVisible && (
        <ProfileFilter
          onClose={() => {
            props.modalDataStore.filterModal.isVisible = false;
          }}
          from={props.modalDataStore.filterModal.from}
        />
      )}
    </>
  );
};

export default inject('modalDataStore')(observer(ModalViews));

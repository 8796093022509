import './index.css';

import { Button, Input, message, Tooltip } from 'antd';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import namecase from 'namecase';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { autorun } from 'mobx';

import { SearchOutlined } from '@ant-design/icons';
import ConnectivityIcon from '../../assets/icons/ai_meter_signal.svg?react';
import HelpIcon from '../../assets/icons/help-icon.svg?react';
import RecentlyViewedIcon from '../../assets/icons/ai-ic-recently-viewed.svg?react';

import { requestWithAuth, updateUserInfo } from '../../services/api';
import {
  formatEventDescription,
  formatEventName,
  formatEventSource,
  formatPhoneNumber,
  formatValue,
  getRecentlyViewedText,
  getSubscription,
  isEqual,
  isLowerCase,
  titleCase,
  commarize,
} from '../../utils/general';
import { getBodyParams } from '../../utils/getBodyParams';
import filterStates from '../../utils/Statelist';
import {
  FunctionFlags,
  PremiumProspectFinderColumns,
  salaryFilter,
  wealthScoreRanges,
} from '../../utils/constants';

import ExportTableNew from '../../components/ExportTableNew';
import WarningMessage from '../../components/WarningMessage';
import DiscoverBackground from '../../components/DiscoverBackground';
import RowExtra from '../../components/RowExtra';
import TagsList from '../../components/TagsFolder/TagsList';
import TagsNew from '../../components/TagsNew';
import LoaderAnimation from '../../components/AidLoader';
import BlurredContent from '../../components/BlurredContent';
import {
  hasEntitlement,
  isPremiumWealthTrigger,
  isPremiumContentPurchased,
} from '../../utils/entitlements';
import { PremiumLabel } from '../../components/AiFunctionalComponents';
import InsightsView from './InsightsView';

message.config({
  top: 80,
});

let source;
let rowKeyArray = [];
const sortInfo = {
  column: '',
  order: '',
};

const PeopleView = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const navigate = useNavigate();
    const [listPagination, setListPagination] = useState({
      total: 0,
      pageSize: props.dataStore.currentUser.pagination_count || 100,
      current: 1,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    /** To reset selected rows when applying filters and table change(sort, pagination) */
    const [refreshSelectedRows, setRefreshSelectedRows] = useState(false);
    const [crmCredentials, setCrmCredentials] = useState(null);
    const [relLinks, setRelLinks] = useState([]);
    const [selectedProfileIds, setSelectedProfileIds] = useState([]);
    const [showRecentlyViewedIcon, setShowRecentlyViewedIcon] = useState(false);
    const flags = useFlags();
    const [exportFromRow, setExportFromRow] = useState(null);

    const searchInputRef = useRef(null);
    /** peopleStore is used instead of regular state for networklist since networklist updates are not reflecting inside ExportTable */
    const peopleStore = useLocalObservable(() => ({
      networkList: [],
    }));

    const newProfileActive = flags && flags.dntfd8933NewProfileView;
    const { currentUser } = props.dataStore;
    const wealthPropertyColumns = Object.values(PremiumProspectFinderColumns);
    const hasPremiumWealthTriggers = hasEntitlement(
      currentUser,
      FunctionFlags.premium_wealth_triggers,
    );
    const hasPremWeathProperties = hasEntitlement(
      currentUser,
      FunctionFlags.wealth_properties,
    );

    useEffect(() => {
      props.dataStore.lookUpLists.isPeoplePage = true;
      // eslint-disable-next-line import/no-named-as-default-member
      const { CancelToken } = axios;
      source = CancelToken.source();

      if (
        props.dataStore.networkFilters.relmap_org &&
        props.dataStore.networkFilters.relmap_org.length > 0 &&
        props.dataStore.networkFilters.filter_type !== 'all'
      ) {
        props.dataStore.networkFilters.filter_type = 'all';
      }

      if (
        (props.dataStore.isNetworkFilterApplied ||
          (props.dataStore.networkFilters.mapQuery &&
            Object.keys(props.dataStore.networkFilters.mapQuery).length > 0)) &&
        getSubscription(props.dataStore.currentUser)
      )
        fetchListData();

      const autoUpdateDisposer = autorun(() => {
        if (
          props.dataStore.selectedGroupByParam &&
          props.dataStore.selectedGroupByParam.length > 0 &&
          props.dataStore.isShowingInsightsView
        )
          return;
        if (
          !Object.values(props.dataStore.table_search).length &&
          !props.dataStore.isNetworkFilterApplied &&
          (!props.dataStore.networkFilters.mapQuery ||
            Object.keys(props.dataStore.networkFilters.mapQuery).length <= 0) &&
          !props.dataStore.mappedRelationshipsFilter.filterName
        ) {
          /** Clear mapped connection filter */
          peopleStore.networkList = [];
          props.dataStore.networkFilters.fetchData = false;
        } else if (
          props.dataStore.networkFilters.fetchData &&
          props.dataStore.currentUser.activePlan
        ) {
          if (getSubscription(props.dataStore.currentUser)) {
            setListPagination({
              ...listPagination,
              current: 1,
            });

            fetchListData();
          }
        }

        if (props.modalDataStore.profileModal.onDelete) {
          if (props.modalDataStore.profileModal.b2bId) {
            peopleStore.networkList = peopleStore.networkList.filter((data) => {
              return data.b2b_id !== props.modalDataStore.profileModal.b2bId;
            });
          } else if (props.modalDataStore.profileModal.b2cId) {
            peopleStore.networkList = peopleStore.networkList.filter((data) => {
              return data.b2c_id !== props.modalDataStore.profileModal.b2cId;
            });
          }
          setTotalCount(totalCount - 1);
          props.modalDataStore.resetAll();
        }
      });
      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
        autoUpdateDisposer();
        if (props.dataStore.currentUser.ownerId)
          updateUserInfo(props.dataStore.currentUser.ownerId, {
            prospect_header_order:
              props.dataStore.currentUser.userSelectedColumns.filter(
                (item) =>
                  item !== 'relationship_status' &&
                  item !== 'relationship_type',
              ),
          });
        props.dataStore.lookUpLists.isPeoplePage = false;
        props.dataStore.companyFilter = null;
      };
    }, []);

    useEffect(() => {
      if (flags && flags.prospectFinderRecentlyViewedIconDntfd8663)
        setShowRecentlyViewedIcon(true);
    }, [flags.prospectFinderRecentlyViewedIconDntfd8663]);

    useEffect(() => {
      /**fetching column order from session in the event of page refresh */
      const columnOrder = sessionStorage.getItem('user-columns-order');
      if (columnOrder) {
        /** This block will execute on refreshing the page */
        const columnOrderArray = columnOrder.split(',');
        if (columnOrderArray && columnOrderArray.length > 0) {
          props.dataStore.currentUser.userSelectedColumns = columnOrderArray;
        }
      }
      return () => {
        sessionStorage.removeItem('user-columns-order');
      };
    }, []);

    useEffect(() => {
      window.sessionStorage.setItem(
        'user-columns-order',
        props.dataStore.currentUser.userSelectedColumns,
      );
    }, [props.dataStore.currentUser.userSelectedColumns]);

    useEffect(() => {
      if (listPagination.total === 0) return;
      fetchListData();
    }, [listPagination.pageSize, listPagination.current]);

    useEffect(() => {
      if (props.modalDataStore.profileModal.fetchOnChange) {
        fetchListData();
      }
    }, [props.modalDataStore.profileModal.fetchOnChange]);

    useEffect(() => {
      if (props.dataStore.lookUpLists.insightHistory) {
        fetchListData();
        props.dataStore.lookUpLists.insightHistory = false;
      }
    }, [props.dataStore.lookUpLists.insightHistory]);

    const fetchListData = () => {
      setRefreshSelectedRows(!refreshSelectedRows);
      setIsLoading(true);

      if (source) source.cancel('Operation canceled by the user.');

      // eslint-disable-next-line import/no-named-as-default-member
      const { CancelToken } = axios;
      source = CancelToken.source();

      const body = getBodyParams(
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        props.dataStore.superConnectorFilter,
        props.dataStore.teamFilters,
        props.dataStore.currentUser,
        props.dataStore.mappedRelationshipsFilter,
        props.modalDataStore.profileModal,
        props.dataStore.groupByValues,
      );

      type Params = {
        page_size: number;
        from: number;
        notes_column?: number;
        tags_column?: number;
        triggers_view?: number;
        quick_search?: string;
        sort_key?: string;
        sort_order?: string;
        filter_type?: string;
        auto_second_degree?: number;
      };
      const params: Params = {
        page_size: listPagination.pageSize,
        from: (listPagination.current - 1) * listPagination.pageSize,
      };
      if (props.dataStore.currentUser.userSelectedColumns.includes('note'))
        params.notes_column = 1;
      if (props.dataStore.currentUser.userSelectedColumns.includes('tags'))
        params.tags_column = 1;
      if (props.dataStore.isTriggerFilterApplied) params.triggers_view = 1;
      // if anything is searched, pass that
      if (props.dataStore.quick_search)
        params.quick_search = props.dataStore.quick_search;

      if (sortInfo.column && sortInfo.order) {
        params.sort_key = sortInfo.column;
        params.sort_order = sortInfo.order;
      }

      if (props.dataStore.networkFilters.filter_type) {
        params.filter_type = props.dataStore.networkFilters.filter_type;
      }

      if (props.dataStore.networkFilters.auto_second_degree)
        params.auto_second_degree = 0;

      setIsLoading(true);
      peopleStore.networkList = [];
      requestWithAuth(
        'network-list-new',
        'POST',
        params,
        body,
        source.token,
        'progress_people_list',
      ).then((response) => {
        if (!response) return;
        const paginationLimit = response.pagination_limit || 10000;
        setListPagination((prevState) => ({
          ...prevState,
          total:
            response.count > paginationLimit ? paginationLimit : response.count,
        }));
        setTotalCount(response.count);
        if (response?.all_links?.length > 0)
          setRelLinks(
            response.all_links.reduce((acc, curr) => {
              if (curr)
                return [
                  ...acc,
                  { text: curr.toString().toTitleCase(), value: curr },
                ];
            }, []),
          );
        rowKeyArray = [];
        peopleStore.networkList = response.results.map((obj) => {
          return { ...obj, ...obj.event };
        });
        setIsLoading(false);
      });
      props.dataStore.networkFilters.fetchData = false;
      props.modalDataStore.profileModal.fetchOnChange = false;
    };

    const ManagementDescription = (record) => {
      let manage;
      if (
        record &&
        record.management_changes &&
        record.management_changes.length > 0
      ) {
        manage = record.management_changes.map((change) => {
          const previousCompany = isLowerCase(change.previous_org_name)
            ? titleCase(change.previous_org_name)
            : change.previous_org_name;
          const currentCompany = isLowerCase(change.current_org_name)
            ? titleCase(change.current_org_name)
            : change.current_org_name;
          const previousTitle = isLowerCase(change.previous_title)
            ? titleCase(change.previous_title)
            : change.previous_title;
          const currentTitle = isLowerCase(change.current_title)
            ? titleCase(change.current_title)
            : change.current_title;
          if (change.management_change_type === 'Company Change') {
            if (!currentCompany && !previousCompany) {
              return <span>Changed Company</span>;
            }
            if (!currentCompany)
              return <span>{`Changed from ${previousCompany}`}</span>;

            if (!previousCompany || isEqual(previousCompany, currentCompany))
              return <span>{`Changed to ${currentCompany}`}</span>;

            if (currentCompany && previousCompany) {
              return (
                <span>{`Changed from ${previousCompany} to ${currentCompany}`}</span>
              );
            }
          } else {
            if (!currentTitle && !previousTitle) {
              return <span>Promoted</span>;
            }
            if (!currentTitle)
              return <span>{`Promoted from ${previousTitle}`}</span>;

            if (!previousTitle || isEqual(previousTitle, currentTitle))
              return <span>{`Promoted to ${currentTitle}`}</span>;

            if (currentTitle && previousTitle) {
              return (
                <span>{`Promoted from ${previousTitle} to ${currentTitle}`}</span>
              );
            }
          }
        });
        return (
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            {manage}
          </span>
        );
      }
    };

    const eventDescription = (record) => {
      if (!record) return;
      let manage;
      if (
        record.org_name &&
        record.event_org_name &&
        record.org_name.toLowerCase() !== record.event_org_name.toLowerCase() &&
        record.description_text !== null &&
        record.description_text !== ''
      ) {
        manage = record.description_text;
      } else {
        manage = record.event_description;
        if (manage && (record.event_source || record.event_data_source_name)) {
          manage = `${manage} (Source: ${formatEventSource(
            record.event_source || record.event_data_source_name,
          )})`;
        }
      }

      return formatEventDescription(manage, record.event_type);
    };

    // Method to handle Export csv click to show popup
    const handleExportCsvClick = (recordb2b: string, recordb2c: string) => {
      if (!props.dataStore.currentUser.crm) {
        navigate('/connect/export');
        return;
      }

      setCrmCredentials({
        id1: recordb2b || '',
        id2: recordb2c || '',
      });
    };

    function showPersonProfile(b2bId: string, b2cId: string) {
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }

      props.modalDataStore.resetCompanyProfileModal();
      props.modalDataStore.resetProfileModal();
      const { profileModal } = props.modalDataStore;
      profileModal.from = 'n2';
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;

      props.modalDataStore.trackModal.push({
        modal: 'profile',
        data: {
          from: 'n2',
          b2bId,
          b2cId,
        },
      });
    }

    const handleTableChange = (pagination, filtersArg, sorter) => {
      setRefreshSelectedRows(!refreshSelectedRows);

      Object.keys(filtersArg).map((key) => {
        if (filtersArg[key] !== null) {
          const salary = [];
          if (key === 'inferred_salary') {
            filtersArg[key].map((item) => {
              salary.push(
                salaryFilter.filter((salary) => salary.value === item)[0],
              );
            });
            props.dataStore.networkFilters['inferred_salary_range'] = salary;
            props.dataStore.table_search['inferred_salary_range'] = salary;
          }
          if (key === 'wealth_estimate') {
            filtersArg[key].map((item) => {
              salary.push(
                wealthScoreRanges.filter((salary) => salary.value === item)[0],
              );
            });
            props.dataStore.networkFilters[key] = salary;
            props.dataStore.table_search[key] = salary;
          }
          if (key === 'location_state') {
            filtersArg[key].map((item) => {
              salary.push(
                filterStates.filter((state) => state.value === item)[0],
              );
            });
            props.dataStore.networkFilters[key] = salary;
            props.dataStore.table_search[key] = salary;
          }
          if (key === 'relationship_type') {
            props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_types =
              filtersArg[key];
          }
          if (key === 'relationship_status') {
            props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_weights =
              filtersArg[key];
          }
        } else if (key == 'inferred_salary') {
          if (props.dataStore.table_search.inferred_salary_range)
            props.dataStore.networkFilters.inferred_salary_range = [];
          delete props.dataStore.table_search['inferred_salary_range'];
        } else if (key == 'relationship_type')
          props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_types =
            [];
        else if (key == 'relationship_status')
          props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_weights =
            [];
        else {
          if (props.dataStore.table_search[key])
            props.dataStore.networkFilters[key] = [];
          delete props.dataStore.table_search[key];
        }
      });

      if (sorter && sorter.order) {
        sortInfo.column = sorter.columnKey;
        sortInfo.order = sorter.order === 'ascend' ? 'asc' : 'desc';
      } else {
        sortInfo.column = '';
        sortInfo.order = '';
      }

      if (
        pagination.pageSize === listPagination.pageSize &&
        pagination.current === listPagination.current
      ) {
        fetchListData();
      }
      if (pagination.pageSize !== listPagination.pageSize) {
        updateUserInfo(props.dataStore.currentUser.ownerId, {
          pagination_count: pagination.pageSize,
        });
        props.dataStore.currentUser.pagination_count = pagination.pageSize;
      }

      setListPagination({
        ...pagination,
        pageSize: pagination.pageSize,
        current: pagination.current,
      });
    };

    const rowKeyArrayAdd = (record) => {
      if (!record) return;

      if (
        record.org_name &&
        record.event_org_name &&
        record.description_text &&
        record.org_name.toLowerCase() !== record.event_org_name.toLowerCase()
      )
        rowKeyArray.push(
          `${record.events_id || record.event_id}${record.b2b_id}`,
        );
      else if (
        record.management_changes &&
        record.management_changes.length > 0
      ) {
        rowKeyArray.push(`${record.b2b_id}${record.b2c_id}`);
      }
    };

    const paginationProps = {
      ...listPagination,
      total: listPagination.total,
      pageSize: listPagination.pageSize,
      current: listPagination.current,
    };

    const handleDeleteData = (b2bId, b2cId, length) => {
      peopleStore.networkList = peopleStore.networkList.filter((item) => {
        if (!b2bId.includes(item.b2b_id) && !b2cId.includes(item.b2c_id))
          return item;
        setTotalCount(totalCount - length);
      });
    };

    const showHelpModal = (e) => {
      e.stopPropagation();
      props.modalDataStore.eventHelpModalVisible = true;
    };

    const titleCaps = (title) => {
      const abbreviations = [
        'Avp',
        'Cdo',
        'Ceo',
        'Cfo',
        'Cio',
        'Cmo',
        'Coo',
        'Cro',
        'Cso',
        'Cto',
        'Cxo',
        'Dvp',
        'Evp',
        'Md',
        'Vp',
        'Svp',
      ];
      if (title) {
        abbreviations.map((abbre) => {
          if (title.includes(abbre)) {
            title = title.replace(abbre, abbre.toUpperCase());
          }
        });
      }
      return title;
    };

    const renderEmail = (email: string) => {
      return (
        <div>
          <a
            className="link"
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="link-value">{email}</span>
          </a>
        </div>
      );
    };

    const handleSearch = (selectedKeys: string[], dataIndex: string) => {
      const searchData = selectedKeys[0];
      if (searchData !== undefined) {
        props.dataStore.networkFilters[dataIndex] = [
          {
            label: searchData,
            value: searchData,
            __isNew__: true,
          },
        ];
        props.dataStore.table_search[dataIndex] = searchData;
        props.dataStore.table_search = { ...props.dataStore.table_search };
        fetchListData();
      }
    };

    const handleReset = (clearFilters: () => void, dataIndex: string) => {
      clearFilters();

      if (dataIndex) {
        props.dataStore.networkFilters[dataIndex] =
          props.dataStore.networkFilters[dataIndex].filter((item) => {
            return item.value !== props.dataStore.table_search[dataIndex];
          });
        delete props.dataStore.table_search[dataIndex];
        fetchListData();
      }
    };

    const renderRecentlyViewed = (profile_history_updated: string) => {
      const lastViewed = getRecentlyViewedText(profile_history_updated);
      if (lastViewed)
        return (
          <div
            className="popUp-recently"
            style={{ marginLeft: '-10px', lineHeight: 1 }}
          >
            <RecentlyViewedIcon width={30} />
            <div
              className="popUp-inner-recently"
              style={{
                display: 'none',
              }}
            >
              Viewed {lastViewed}
            </div>
          </div>
        );
      return <div style={{ width: 20 }}></div>;
    };

    const getColumnSearchProps = (label: string, dataIndex: string) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder={`Search ${label}`}
            value={selectedKeys ? selectedKeys : ''}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined />,

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputRef.current.select());
        }
      },
      filteredValue: props.dataStore.table_search[dataIndex] || null,
    });

    /*
      Need to consider:
        Columns: 
          Trigger Description, Trigger Key
          Blur When:
            user does not have the premium wealth triggers (ex: PRO user)
          See When:
            user has premium wealth triggers function flag (PREM +)
        Columns:
          Weath Segment, Inferred Salaray:
          Blur When:
            user does not have wealth_properties function flag (PRO User)
            user has not purchased wealth_properties for profile (PRO user)
          See When:
            user has wealth_propertis function flag
            user has purchased wealth properties on a profile
    */
    const isContentBlurred = (columnKey: string, record) => {
      if (!newProfileActive) return false;

      // the record has ai_org_id, b2b, b2c id's. need to be able to translate that to a
      // profile to see if it's purchased -- unless the api is going to supply that information
      const profilePremiumBitsPurchased = isPremiumContentPurchased(
        currentUser,
        record,
      );

      const wealthColumn = wealthPropertyColumns.includes(columnKey);

      if (wealthColumn) {
        if (!hasPremWeathProperties && !profilePremiumBitsPurchased)
          return true;

        return !hasPremWeathProperties;
      }

      if (isPremiumWealthTrigger(record.event_type)) {
        if (hasPremiumWealthTriggers) return false;

        return true;
      }

      return false;
    };

    rowKeyArray = [];
    interface Column {
      title?: JSX.Element | string;
      align?: string;
      width?: number;
      key?: string;
      sorter?: boolean;
      filters?: {
        text?: string;
        label?: string;
        value?: string;
        min?: number;
        max?: number;
      }[];
      sortDirections?: string[];
      defaultSortOrder?: string;
      render?: (val?, rec?, index?) => JSX.Element | string;
      onFilter?: (value) => boolean;
      className?: string;
      filterDropdown?: ({
        setSelectedKeys,
        selectedKeys,
        clearFilters,
      }: {
        setSelectedKeys?: () => [];
        selectedKeys?: [];
        clearFilters?: () => void;
      }) => JSX.Element;
      filterIcon?: (filtered) => JSX.Element;
      onFilterDropdownVisibleChange?: (visible) => void;
      filteredValue?: [];
      dataIndex?: string;
    }
    const columns: Column[] = [
      {
        title: (
          <div
            style={{
              paddingLeft: showRecentlyViewedIcon ? 30 : 10,
            }}
          >
            {' '}
            Name{' '}
          </div>
        ),
        width: 150,
        key: 'full_name',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (val, rec, index) => {
          rowKeyArrayAdd(rec);

          return (
            <div
              style={
                showRecentlyViewedIcon
                  ? {
                      paddingLeft: '5px',
                      display: 'grid',
                      alignItems: 'center',
                      gridTemplateColumns: '30px auto auto',
                    }
                  : { paddingLeft: '10px', display: 'flex' }
              }
            >
              {showRecentlyViewedIcon &&
                renderRecentlyViewed(rec.profile_history_updated)}
              <div
                style={{
                  // width: '85%',
                  wordBreak: 'break-word',
                  color: 'var(--color-black)',
                }}
              >
                {namecase(rec.full_name)}
              </div>

              <RowExtra
                rec={rec}
                selectedProfileIds={selectedProfileIds}
                handleExportCsvClick={handleExportCsvClick}
                handleExportFromRowClick={(n2Ids) => setExportFromRow(n2Ids)}
                updatePerson={(key, tags) => {
                  if (
                    props.dataStore.currentUser.userSelectedColumns.includes(
                      'tags',
                    )
                  ) {
                    const networkList = peopleStore.networkList;
                    networkList[index].tags = [...tags];
                    peopleStore.networkList = [...networkList];
                  }
                }}
                refreshData={fetchListData}
              />
            </div>
          );
        },
      },
      {
        title: 'Title',
        width: 180,
        key: 'title',
        sorter: true,
        className: 'overflow-check',
        sortDirections: ['ascend', 'descend'],
        ...getColumnSearchProps('Title', 'title'),
        render: (val) => {
          return (
            <div
              style={{
                maxHeight: '40px',
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                textTransform: 'capitalize',
              }}
            >
              {titleCaps(val.title) || ''}
            </div>
          );
        },
      },
      {
        title: 'Company',
        key: 'org_name',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 180,
        ...getColumnSearchProps('Company', 'org_name'),
        render: (val) => {
          return (
            <div
              className={
                val.ai_org_id ? 'company-link' : 'company-link-disabled'
              }
              style={{ display: 'inline' }}
              onClick={(e) => {
                if (!val || !val.ai_org_id) return;
                e.stopPropagation();
                props.showCompanyProfile(val.ai_org_id, val.org_name);
              }}
            >
              {val.org_name || ''}
            </div>
          );
        },
      },
      {
        title: 'Degree',
        width: 110,
        align: 'center',
        key: 'degree',
        render: (val, rec) => {
          if (rec.degree && rec.degree === 1) {
            return (
              <span>
                1<sup>st</sup>
              </span>
            );
          }
          if (rec.degree && rec.degree === 2) {
            return (
              <span>
                2<sup>nd</sup>
              </span>
            );
          }
          return '';
        },
      },
      {
        title: 'Introducer(s)',
        width: 120,
        align: 'center',
        key: 'mutual',
        className: 'mutual-rel-col',
        render: (rec) => {
          return (
            <>
              {rec.mutual_contacts_details &&
                rec.mutual_contacts_details.length > 0 && (
                  <div
                    className="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      showPersonProfile(
                        rec.mutual_contacts_details[0].b2b_id,
                        rec.mutual_contacts_details[0].b2c_id,
                      );
                    }}
                  >
                    {rec.mutual_contacts_details[0].name}
                  </div>
                )}

              {rec.mutual_contacts_details &&
              rec.mutual_contacts_details.length === 2 ? (
                <div
                  className="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    showPersonProfile(
                      rec.mutual_contacts_details[1].b2b_id,
                      rec.mutual_contacts_details[1].b2c_id,
                    );
                  }}
                >
                  {rec.mutual_contacts_details[1].name}
                </div>
              ) : rec.mutual_contacts_details &&
                rec.mutual_contacts_details.length > 2 ? (
                <Tooltip
                  color="var(--color-white)"
                  title={
                    <div>
                      {rec.mutual_contacts_details.map((obj) => {
                        if (obj.b2b_id || obj.b2c_id) {
                          return (
                            <div
                              className="link"
                              onClick={(e) => {
                                e.stopPropagation();
                                showPersonProfile(obj.b2b_id, obj.b2c_id);
                              }}
                            >
                              {obj.name}
                            </div>
                          );
                        } else {
                          return (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {obj.name}
                            </div>
                          );
                        }
                      })}
                    </div>
                  }
                >
                  <div>+({rec.mutual_contacts_details.length - 1})</div>
                  {/* <div className="popArrow-mutual" /> */}
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        title: 'Wealth Segment',
        width: 180,
        dataIndex: 'wealth_estimate',
        key: 'wealth_estimate',
        sorter: hasPremWeathProperties ? true : false,
        sortDirections: ['ascend', 'descend'],
        filters: hasPremWeathProperties ? [...wealthScoreRanges] : undefined,
        onFilter: (value) => {
          if (value) return true;
        },

        filteredValue:
          props.dataStore.table_search.wealth_estimate &&
          props.dataStore.table_search.wealth_estimate.length > 0
            ? props.dataStore.table_search.wealth_estimate.map(
                (item) => item.value,
              )
            : null,

        render: (val, rec) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              <BlurredContent
                blurred={isContentBlurred('wealth_estimate', rec)}
              >
                {val || ''}
              </BlurredContent>
            </div>
          );
        },
      },
      {
        title: 'Inferred Income',
        width: 180,
        sorter: hasPremWeathProperties ? true : false,
        sortDirections: ['ascend', 'descend'],
        key: 'inferred_salary',
        dataIndex: 'inferred_salary_range',
        filters: hasPremWeathProperties ? salaryFilter : undefined,
        onFilter: (value) => {
          if (value) return true;
        },

        filteredValue:
          props.dataStore.table_search.inferred_salary_range &&
          props.dataStore.table_search.inferred_salary_range.length > 0
            ? props.dataStore.table_search.inferred_salary_range.map(
                (item) => item.value,
              )
            : null,
        render: (val, rec) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-column)',
              }}
            >
              <BlurredContent
                blurred={isContentBlurred('inferred_salary', rec)}
              >
                {val ? val : ''}
              </BlurredContent>
            </div>
          );
        },
      },
      {
        title: 'Mobile Number',
        width: 180,
        align: 'center',
        key: 'mobile_phones',
        dataIndex: 'mobile_phones',
        render: (val) => {
          return (
            <>
              {val && val.length > 2 ? (
                <Tooltip
                  placement="topLeft"
                  title={val.map((phone) => (
                    <div>{formatPhoneNumber(phone)}</div>
                  ))}
                >
                  <div
                    style={{
                      width: '100%',
                      wordBreak: 'break-word',
                      display: 'block',
                      color: 'var(--color-column)',
                    }}
                  >
                    <div>{formatPhoneNumber(val[0])}</div>
                    <div>+({val.length - 1})</div>
                  </div>
                </Tooltip>
              ) : val && val.length <= 2 ? (
                <div
                  style={{
                    width: '100%',
                    wordBreak: 'break-word',
                    display: 'block',
                    color: 'var(--color-column)',
                  }}
                >
                  <div>{formatPhoneNumber(val[0])}</div>
                  {val[1] && <div>{formatPhoneNumber(val[1])}</div>}
                </div>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        title: 'Professional Email',
        width: 180,
        key: 'work_emails',
        dataIndex: 'work_emails',
        className: 'overflow-check email-cell',
        render: (val) => {
          return (
            <>
              {val && val.length > 2 ? (
                <Tooltip
                  placement="topLeft"
                  color="var(--color-white)"
                  title={val.map((email) => (
                    <div>{renderEmail(email)}</div>
                  ))}
                >
                  <div
                    style={{
                      width: '100px',
                      display: 'block',
                      color: 'var(--color-column)',
                    }}
                  >
                    {renderEmail(val[0])}
                    <div style={{ textAlign: 'center' }}>
                      +({val.length - 1})
                    </div>
                  </div>
                </Tooltip>
              ) : val && val.length <= 2 ? (
                <div
                  style={{
                    width: '100px',
                    display: 'block',
                    color: 'var(--color-column)',
                  }}
                >
                  {renderEmail(val[0])}
                  {val[1] && renderEmail(val[1])}
                </div>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        title: 'Personal Email',
        width: 180,
        key: 'personal_emails',
        dataIndex: 'personal_emails',
        className: 'overflow-check email-cell',
        render: (val) => {
          return (
            <>
              {val && val.length > 2 ? (
                <Tooltip
                  placement="topLeft"
                  color="var(--color-white)"
                  title={val.map((email) => (
                    <div>{renderEmail(email)}</div>
                  ))}
                >
                  <div
                    style={{
                      width: '100px',
                      display: 'block',
                      color: 'var(--color-column)',
                    }}
                  >
                    {renderEmail(val[0])}
                    <div style={{ textAlign: 'center' }}>
                      +({val.length - 1})
                    </div>
                  </div>
                </Tooltip>
              ) : val && val.length <= 2 ? (
                <div
                  style={{
                    width: '100px',
                    display: 'block',
                    color: 'var(--color-column)',
                  }}
                >
                  {renderEmail(val[0])}
                  {val[1] && renderEmail(val[1])}
                </div>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        title: (
          <div style={{ position: 'relative', display: 'flex' }}>
            <div className="help-icon-event" onClick={showHelpModal}>
              <HelpIcon height="14px" width="14px" />
            </div>
            <div
              style={{
                marginLeft: '5px',
                // color: 'var(--color-primary)',
                fontWeight: 'bold',
              }}
            >
              Trigger
            </div>
          </div>
        ),
        dataIndex: 'event_type',
        key: 'event_type',
        width: hasPremiumWealthTriggers ? 180 : 235,
        render: (val, rec) => {
          /* Setting id as unique key array */
          if (!val) return '';
          const blurred = isContentBlurred('event_type', rec);

          if (blurred) {
            return (
              <PremiumLabel
                labelText="PREMIUM TRIGGER TYPE"
                labelColor="black"
                bgColor="transparent"
                borderColor="light-gray"
                outline={true}
                iconColor="premium-green"
              />
            );
          } else {
            return (
              <div
                style={{
                  width: '100%',
                  wordBreak: 'break-word',
                  display: 'block',
                  textTransform: 'capitalize',
                }}
              >
                {formatEventName(val)}
              </div>
            );
          }
        },
        sorter: true,
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Trigger Date',
        width: 140,
        className: 'column-left-padding',
        dataIndex: 'event_date',
        key: 'event_date',
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-column)',
              }}
            >
              {val ? moment(val).format('MM/DD/YYYY') : ''}
            </div>
          );
        },
        sorter: true,
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Trigger Description',
        dataIndex: 'event_description',
        key: 'event_description',
        className: 'overflow-check',
        width: 200,
        render: (val, rec) => {
          const contentBlurred = isContentBlurred('event_description', rec);

          if (contentBlurred) {
            // no tooltip for when the content is blurred... doesn't make sense to have the tooltip when it's blurred.
            return (
              <div
                style={{
                  maxHeight: '40px',
                  display: 'block',
                }}
              >
                <BlurredContent blurred={contentBlurred}>
                  {eventDescription(rec)}
                </BlurredContent>
              </div>
            );
          } else {
            return (
              <Tooltip placement="topLeft" title={eventDescription(rec)}>
                <div
                  style={{
                    maxHeight: '40px',
                    display: 'block',
                  }}
                >
                  {eventDescription(rec)}
                </div>
              </Tooltip>
            );
          }
        },
      },
      {
        title: 'Trigger Value',
        width: 180,
        dataIndex: 'event_monetary_value',
        align: 'right',
        key: 'event_monetary_value',
        sorter: hasPremiumWealthTriggers ? true : false,
        sortDirections: ['ascend', 'descend'],
        render: (val, rec) => {
          const minValue = rec.event?.event_min_value || 0;
          const maxValue = rec.event?.event_max_value || 0;
          if (minValue >= 0 && maxValue) {
            return (
              <div
                style={{
                  width: '100%',
                  wordBreak: 'break-word',
                  display: 'block',
                }}
              >
                ${commarize(minValue, 0)} - ${commarize(maxValue, 0)}
              </div>
            );
          } else {
            return (
              <div
                style={{
                  width: '100%',
                  wordBreak: 'break-word',
                  display: 'block',
                }}
              >
                {val ? formatValue(val) : ''}
              </div>
            );
          }
        },
      },
      {
        title: 'City',
        key: 'location_city',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 150,
        ...getColumnSearchProps('City', 'location_city'),
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                textTransform: 'capitalize',
              }}
            >
              {val.location_city ? val.location_city.toLowerCase() : ''}
            </div>
          );
        },
      },
      {
        title: 'State',
        width: 100,
        key: 'location_state',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        filters: filterStates,
        onFilter: (value) => {
          if (value) return true;
        },

        filteredValue:
          props.dataStore.table_search.location_state &&
          props.dataStore.table_search.location_state.length > 0
            ? props.dataStore.table_search.location_state.map(
                (item) => item.value,
              )
            : null,
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val.location_state}
            </div>
          );
        },
      },
      {
        title: 'School',
        width: 180,
        key: 'schools',
        dataIndex: 'schools',
        className: 'overflow-check',
        render: (val) => {
          return (
            <>
              {val && val.length > 2 ? (
                <Tooltip
                  placement="topLeft"
                  title={val.map((school, index) => (
                    <div>
                      {school}
                      {index !== val.length - 1 && <>,</>}
                    </div>
                  ))}
                >
                  <div
                    style={{
                      // width: '100%',
                      // wordBreak: 'break-word',
                      width: '100px',
                      //overflowWrap: 'normal',
                      display: 'block',
                      color: 'var(--color-column)',
                      textTransform: 'capitalize',
                    }}
                  >
                    <div style={{ whiteSpace: 'nowrap' }}>{val[0]}</div>
                    <div style={{ textAlign: 'center' }}>
                      +({val.length - 1})
                    </div>
                  </div>
                </Tooltip>
              ) : val && val.length <= 2 ? (
                <div
                  style={{
                    // width: '100%',
                    // wordBreak: 'break-word',
                    width: '100px',
                    //overflowWrap: 'normal',
                    display: 'block',
                    color: 'var(--color-column)',
                    textTransform: 'capitalize',
                  }}
                >
                  <div style={{ whiteSpace: 'nowrap' }}>{val[0]}</div>
                  {val[1] && (
                    <div style={{ whiteSpace: 'nowrap' }}>{val[1]}</div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          );
        },
      },

      {
        title: 'Note',
        width: 100,
        key: 'note',
        dataIndex: 'note',
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val}
            </div>
          );
        },
      },
      {
        title: 'Tags',
        width: 100,
        key: 'tags',
        dataIndex: 'tags',
        render: (val, rec) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {flags && flags.tagFolders && val && val.length > 0 ? (
                <div className="table-tags">
                  <TagsNew
                    profileTagsDetails={val}
                    selectedProfileIds={[
                      {
                        b2b_id: rec.b2b_id,
                        b2c_id: rec.b2c_id || '',
                      },
                    ]}
                    from="row-profile-tag"
                    updatePerson={() => {
                      props.dataStore.networkFilters.fetchData = true;
                    }}
                  >
                    <>
                      <TagsList
                        tags={[{ id: val[0].tag, tag: val[0].tag_name || '' }]}
                        count={1}
                        checkedTags={val.map((item) => item.tag)}
                        handleTagsCheck={(checked) => {
                          /** No functionalities needed, just open the tags-profile modal */
                        }}
                      />
                      {val.length > 1 && (
                        <span className="more-count">
                          + {val.length - 1} more
                        </span>
                      )}
                    </>
                  </TagsNew>
                </div>
              ) : (
                <>
                  {val && val.length > 0
                    ? val.map((tag) => tag.tag_name || '').join(', ')
                    : ''}
                </>
              )}
            </div>
          );
        },
      },
      {
        title: 'Zip Code',
        width: 100,
        key: 'home_postal_code',
        dataIndex: 'home_postal_code',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val}
            </div>
          );
        },
      },
      {
        title: 'Home Address',
        width: 150,
        align: 'center',
        key: 'home_address',
        render: (rec) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-column)',
              }}
            >
              {rec.home_address_line1 || ''}
              {rec.home_address_line1 && rec.home_address_line2 && ', '}
              {rec.home_address_line2 || ''}
            </div>
          );
        },
      },
    ];

    const relWeightLink = [
      {
        title: 'Relationship Strength',
        key: 'relationship_status',
        dataIndex: 'relationship_status',
        width: 150,
        height: 0,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        filters: [
          {
            text: 'Low',
            value: 'low',
          },
          {
            text: 'Med',
            value: 'medium',
          },
          {
            text: 'High',
            value: 'high',
          },
        ],
        filteredValue: props.dataStore.networkFilters
          .mapped_relationships_filter?.mapped_relationship_weights?.length
          ? [
              ...props.dataStore.networkFilters.mapped_relationships_filter
                .mapped_relationship_weights,
            ]
          : [],

        render: (val) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {val && (
                <ConnectivityIcon
                  className={
                    val === 'high'
                      ? 'connectivity-icon-high'
                      : val === 'medium'
                        ? 'connectivity-icon-medium'
                        : 'connectivity-icon-low'
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        title: 'Relationship Path',
        key: 'relationship_type',
        dataIndex: 'relationship_type',
        width: 250,
        height: 0,
        defaultSortOrder: 'ascend',
        filters: relLinks,
        filteredValue: props.dataStore.networkFilters
          .mapped_relationships_filter?.mapped_relationship_types?.length
          ? [
              ...props.dataStore.networkFilters.mapped_relationships_filter
                .mapped_relationship_types,
            ]
          : [],
        render: (val) => {
          return (
            <div>
              {val &&
                val.length &&
                val.map((val) => {
                  return <div>{val ? val.toString().toTitleCase() : ''}</div>;
                })}
            </div>
          );
        },
      },
    ];

    if (!props.dataStore.currentUser.activePlan) {
      return <LoaderAnimation />;
    }

    if (!getSubscription(props.dataStore.currentUser)) {
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    }

    if (
      !Object.values(props.dataStore.table_search).length &&
      !props.dataStore.isNetworkFilterApplied &&
      !props.dataStore.isTriggerFilterApplied &&
      (!props.dataStore.selectedGroupByParam ||
        props.dataStore.selectedGroupByParam.length <= 0) &&
      !props.dataStore.mappedRelationshipsFilter.filterName &&
      (!props.dataStore.networkFilters.mapQuery ||
        Object.keys(props.dataStore.networkFilters.mapQuery).length <= 0)
    ) {
      return <DiscoverBackground />;
    }

    if (
      props.dataStore.selectedGroupByParam &&
      props.dataStore.selectedGroupByParam.length > 0 &&
      props.dataStore.isShowingInsightsView
    ) {
      return <InsightsView showCompanyProfile={props.showCompanyProfile} />;
    }
    const getColumns = () => {
      if (
        props.dataStore.networkFilters.mapped_relationships_filter
          .mapped_source_filter_name
      ) {
        return [...columns, ...relWeightLink];
      }

      return columns;
    };

    const setProgress = (value) => {
      setIsLoading(value);
    };

    return (
      <div
        style={{
          position: 'relative',
          top: '-12px',
        }}
      >
        {isLoading && <LoaderAnimation loaderId="people-view-loader" />}
        <div
          id="network-list-table"
          className={
            isLoading
              ? 'network-list-container aid-div-disable'
              : 'network-list-container aid-div-enable'
          }
        >
          <ExportTableNew
            changeSelectedProfileIds={(ids) => setSelectedProfileIds(ids)}
            dataCount={totalCount}
            columns={getColumns()}
            data={peopleStore.networkList}
            pagination={paginationProps}
            pageSize={listPagination.pageSize}
            onChange={handleTableChange}
            setProgress={setProgress}
            rowKey={(rec) => `${rec.b2b_id}${rec.b2c_id}`}
            expandable={{
              expandedRowRender: (rec) => (
                <p
                  style={{
                    margin: 0,
                    paddingLeft: 17,
                  }}
                >
                  {ManagementDescription(rec)}
                </p>
              ),
            }}
            editable
            deleteData={handleDeleteData}
            // Passing row keys of arrays
            rowKeyArray={rowKeyArray}
            crmRowCredentials={crmCredentials}
            clearCrmCredentials={() => {
              setCrmCredentials(null);
            }}
            refreshSelectedRows={refreshSelectedRows}
            refreshData={fetchListData}
            exportFromRow={exportFromRow}
            clearExport={() => {
              setExportFromRow(null);
            }}
            type={undefined}
            expandedRowShowing={undefined}
            exportAll={undefined}
            searchFilter={undefined}
            company={undefined}
            fromProfile={undefined}
          />
        </div>
      </div>
    );
  }),
);

export default PeopleView;

import './index.scss';
import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';

import { requestWithAuth } from '../../services/api';
import {
  dashboardBoxItems,
  DashboardViewNewDefaultItems,
  DashboardViewItems,
  DashboardGtmUngroupedLabels,
  DashboardViewBusinessInsightsLabels,
  DashboardGtmEducationInsightsLabels,
  DashboardViewWealthInsightsLabels,
  DashboardViewLocationInsightsLabels,
  FunctionFlags,
} from '../../utils/constants';

import { getDateRangeFromPeriod, getSubscription } from '../../utils/general';
import { showCompanyProfile } from '../../utils/modals';

import DashboardHeaderNew from '../../components/DashboardComponentsNew/DashboardHeaderNew';
import WeeklyEngagement from '../../components/DashboardComponentsNew/WeeklyEngagement';
import DashboardOpportunities from '../../components/DashboardComponentsNew/DashboardOpportunities';
import TriggersOverview from '../../components/DashboardComponentsNew/TriggersOverview';
import PeopleViewModal from '../../components/DashboardComponents/PeopleViewModal';
import DragDropListItem from '../../components/DashboardComponents/DragDropListItem';
import DragSortListBox from '../../components/DashboardComponents/DragSortListBox';
import DraggableGridBox from '../../components/DashboardComponents/DraggableGridBox';
import ChartBox from '../../components/DashboardComponents/ChartBox';
import TeamMembers from '../../components/DashboardComponents/TeamMembers';
import DataSources from '../../components/DashboardComponents/DataSources';
import NetworkDNANew from '../../components/DashboardComponentsNew/NetworkDNANew';
import { hasEntitlement } from '../../utils/entitlements';

let source;
let teamValue;

const { VITE_COREDATA_API_URL } = import.meta.env;
const boxItem = {};
const defaultItems = [...DashboardViewNewDefaultItems];
const sourceImportId = [];

const DashboardNew = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { currentUser } = props.dataStore;
    const hasPremiumWealthProperties = hasEntitlement(
      currentUser,
      FunctionFlags.wealth_properties,
    );

    // edit dashboard view trigger
    const [showEditView, setShowEditView] = useState(false);

    // general loading trigger. Individual components have their own
    // and should use "loadingObj.<module>". this should be reserved for
    // loading events that aren't tied to a module.
    const [isLoading, setIsLoading] = useState(false);

    // individual loading components
    const [loadingObj, setLoadingObj] = useState(
      Object.fromEntries(
        Object.keys({
          ...DashboardViewItems,
          WealthTriggerOpportunities: true,
        }).map((key) => [key, true]),
      ),
    );

    // handles displaying the PeopleViewModal when chart data is clicked
    const [modalVisibility, setModalVisibility] = useState(false);

    // all the wealth trigger event types.
    const [triggerTypes, setTriggerTypes] = useState([]);

    // user/team imported files
    const [sharedFileIds, setSharedFileIds] = useState([]);
    const [allTeamFilesImports, setAllTeamFilesImports] = useState([]);
    const [importSourceIds, setImportSourceIds] = useState([]);
    const [myFileImportsIds, setMyFileImportsIds] = useState([]);

    // basic user dashboard layout.
    // const [userDashboardData, setUserDashboardData] = useState({});

    // network dna
    const [schoolData, setSchoolData] = useState({});
    const [titleData, setTitleData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [stockPerformance, setStockPerformance] = useState({});
    const [industryData, setIndustryData] = useState({});
    const [cityData, setCityData] = useState({});
    const [stateData, setStateData] = useState({});
    const [zipData, setZipData] = useState({});
    const [salaryData, setSalaryData] = useState({});
    const [wealthSegmentData, setWealthSegmentData] = useState({});

    // weekly engagement object, should be a flat object containing counts
    const [weeklyEngagementData, setWeeklyEngagementData] = useState({});

    // the object for the dashboard header stats
    // TODO: needs to expand to include "available credits"
    //       and "new opportunities"
    const [triggerTotalCount, setTriggerTotalCount] = useState({});

    // eventData is not used because we use "fitleredEventData"
    // to filter out those events with no data
    const [eventData, setEventData] = useState([]);
    const [fitleredEventData, setFilteredEventData] = useState([]);

    // new wealth trigger opportunities
    const [wealthTriggerpportunities, setWealthTriggerOpportunities] = useState(
      {},
    );

    // controls/sets the dashboard items the user has selected for their layout
    const [selectedItemsList, setSelectedItemsList] = useState(defaultItems);
    const [itemList, setItemList] = useState({
      ...DashboardViewItems,
      WealthTriggerOpportunities: true,
    });

    // these are the avilable dashboard items/modules available to the user
    // through the edit view interactions
    const ungroupedLabels = [...DashboardGtmUngroupedLabels];
    const businessInsightsLabels = [...DashboardViewBusinessInsightsLabels];
    const wealthInsightsLabels = [...DashboardViewWealthInsightsLabels];
    const locationInsightsLabels = [...DashboardViewLocationInsightsLabels];
    const educationInsightsLabels = [...DashboardGtmEducationInsightsLabels];

    // external relationships
    const [importSourceLoading, setImportSourceLoading] = useState(false);
    const [dataSources, setDataSources] = useState({});
    const [csvBoxes, setCsvBoxes] = useState({});
    const [fileImportLoading, setFileImportLoading] = useState(false);

    useEffect(() => {
      teamValue = 'all';

      const { CancelToken } = axios;
      source = CancelToken.source();

      // we use this method to fetch the event types because the fetch that is done
      // via Header/index.jsx does not always complete in time for use to rely
      // on props.dataStore.lookUpLists.eventList. We can listen on updates to
      // "props.dataStore.lookUpLists" with useEffect, but for now I (Brett M.) feel
      // this is a better option.
      //
      // we have a useEffect that will kick off the next round of loading once
      // we receive the response from the event types query.
      fetchEventTypes();

      fetchFileLookups('file_imports');
      fetchFileLookups('all_count');

      window.addEventListener('beforeunload', onPageReload);

      return () => {
        /** unmount- save the selected filters*/
        // sourceImportId = [];
        saveSelectedFilters();
        if (source) source.cancel('Operation canceled by the user.');
        window.removeEventListener('beforeunload', onPageReload);
      };
    }, []);

    const fetchEventTypes = () => {
      setIsLoading(true);
      return requestWithAuth(
        'field-lookup',
        'GET',
        { key: 'event_types' },
        null,
        null,
      ).then((response) => {
        if (response) {
          setTriggerTypes(response.result);
        }
      });
    };

    useEffect(() => {
      if (triggerTypes.length > 0) fetchUserDashBoardData();
    }, [triggerTypes]);

    /** when a page reload happens - save the selected filters*/
    const onPageReload = () => {
      saveSelectedFilters();
    };

    /*
      keep selectedItems in a sesssion variable so that,
      on unmounting/refreshing component we can update the user selected items to the backend
    */
    useEffect(() => {
      window.sessionStorage.setItem(
        'ai-dashboard-selected',
        selectedItemsList.join(','),
      );
    }, [selectedItemsList]);

    // handle draggable modules (which is all piecies besides the stats)
    async function fetchSelectedApis(selectedViewItems) {
      // these two are tied together because we source "TiggerOverview" data from the total 'count' property of the
      // 'WealthTriggerOpportunities' query
      if (
        selectedViewItems.includes('TriggersOverview') &&
        !selectedViewItems.includes('WealthTriggerOpportunities')
      )
        selectedViewItems.push('WealthTriggerOpportunities');
      if (
        !selectedViewItems.includes('TriggersOverview') &&
        selectedViewItems.includes('WealthTriggerOpportunities')
      )
        selectedViewItems.push('TriggersOverview');

      // always update the loading state object so that we trigger the
      // loading spinners
      updateLoadingObject(selectedViewItems, true);

      const generalPromises = [];

      generalPromises.push(fetchAllCount('all_count'));
      generalPromises.push(fetchAllCount('file_imports'));

      Promise.all(generalPromises).then(() => setIsLoading(false));

      if (
        selectedViewItems.includes('TriggersOverview') ||
        selectedViewItems.includes('WealthTriggerOpportunities')
      ) {
        fetchWealthTriggersAndStatistics().then(() => {
          updateLoadingObject(
            ['TriggersOverview', 'WealthTriggerOpportunities'],
            false,
          );
        });
      }

      if (selectedViewItems.includes('WeeklyEngagement')) {
        fetchWeeklyEngagementData().then(() => {
          updateLoadingObject(['WeeklyEngagement'], false);
        });
      }

      if (selectedViewItems.includes('NetworkDNA')) {
        const networkPromises = [
          fetchSchoolData(),
          fetchCompanyData(),
          fetchCompanyViabilityData(),
          fetchIndustryData(),
          fetchTitleData(),
          fetchCityData(),
          fetchStateData(),
          fetchZipData(),
          fetchSalaryData(),
          fetchWealthSegmentData(),
        ];

        Promise.all(networkPromises).then(() => {
          updateLoadingObject(['NetworkDNA'], false);
        });
      } else {
        // we don't need to update the state loading object for individual components here
        // because the ChartBox component handles displaying the loading spinner differently
        // than via a passed in boolean value.

        if (selectedViewItems.includes('CollegesandUniversities'))
          fetchSchoolData();
        if (selectedViewItems.includes('Companies')) fetchCompanyData();
        if (selectedViewItems.includes('CompanyViabiliy'))
          fetchCompanyViabilityData();
        if (selectedViewItems.includes('Industries')) fetchIndustryData();
        if (selectedViewItems.includes('TitleRanks')) fetchTitleData();
        if (selectedViewItems.includes('Cities')) fetchCityData();
        if (selectedViewItems.includes('StatesandTerritories'))
          fetchStateData();
        if (selectedViewItems.includes('ZipCodes')) fetchZipData();
        if (selectedViewItems.includes('InferredSalaryRanges'))
          fetchSalaryData();
        if (selectedViewItems.includes('WealthSegment'))
          fetchWealthSegmentData();
      }
    }

    // base function for all the NetworkDNA state data fetching.
    const requestNetworkSummary = (body, key, reload) => {
      const url = 'network-summary-new';
      const params = {
        key,
        type: teamValue,
      };

      if (typeof teamValue === 'number') {
        params.type = 'team';
        params.team_id = teamValue;
      }

      if (props.dataStore.relationship_quick_search)
        params.quick_search = props.dataStore.relationship_quick_search;
      if (props.globalDataStore.multipleFilters.isFilterApplied) {
        params.is_user_filter = 1;
        params.filter_id = props.globalDataStore.multipleFilters.selectedId;
      } else params.is_user_filter = 0;

      if (sourceImportId && sourceImportId.length > 0)
        body.source_import_ids = sourceImportId.filter(Number);

      const absoluteUrl = `${VITE_COREDATA_API_URL}${url}`;
      return axios
        .request({
          url: absoluteUrl,
          method: 'POST',
          params,
          data: {
            ...body,
          },
          cancelToken: source.token,
        })
        .then((response) => {
          if (response && response.data) return response.data;
          else return {};
        })
        .catch((error) => {
          if (error.message === 'Operation canceled by the user.') {
            console.log('Request canceled', error.message);
          } else {
            console.log(error);
            if (document.getElementById(`${reload}`) !== null)
              document.getElementById(`${reload}`).style.display = 'block';
          }
          const obj = {};
          obj[key] = [];
          obj.total_count = 0;
          return obj;
        });
    };

    // handle draggable modules (which is all piecies besides the stats)
    const onListItemSelected = (e) => {
      const selected = e.target.id;
      fetchSelectedApis([selected]);

      if (!selectedItemsList.includes(selected)) {
        setSelectedItemsList([...selectedItemsList, selected]);
        setItemList((prev) => ({ ...prev, [selected]: false }));
      }
    };

    // handle draggable modules (which is all piecies besides the stats)
    const onCloseButtonClick = (item) => {
      const index = selectedItemsList.indexOf(item);
      if (index > -1) {
        const items = selectedItemsList;
        items.splice(index, 1);

        setSelectedItemsList(items);
      }

      setItemList((prev) => ({ ...prev, [item]: true }));
    };

    // handle draggable modules (which is all piecies besides the stats)
    const saveSelectedFilters = () => {
      if (!getSubscription(props.dataStore.currentUser)) return;

      /** To avoid infinite call to user-dashboard,
       * if the user is on dashboard page, and token expires
       * the user will be loggedout,
       * during this time dashboard is unmounting and calls user-dashboard
       * again its get 401 token expiry and tries to logout thus make in infinite call
       */
      if (
        localStorage.getItem('aidentified-authority') &&
        JSON.parse(localStorage.getItem('aidentified-authority'))[0] === 'guest'
      )
        return;

      const body = {
        items: window.sessionStorage.getItem('ai-dashboard-selected')
          ? window.sessionStorage.getItem('ai-dashboard-selected').split(',')
          : [],
        dashboard_dropdown_items: window.sessionStorage.getItem(
          'dashboard_dropdown_items',
        )
          ? JSON.parse(
              window.sessionStorage.getItem('dashboard_dropdown_items'),
            )
          : {},
      };
      requestWithAuth('user-dashboard', 'POST', null, body, null);
    };

    // handle draggable modules (which is all piecies besides the stats)
    const swap = (fromIndex, toIndex, dragData) => {
      const newItem = dragData.label;
      const newItems = [...selectedItemsList];
      newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, newItem);
      setSelectedItemsList([...newItems]);
    };

    // handle draggable modules (which is all piecies besides the stats)
    const updateGridOnDrop = (item) => {
      fetchSelectedApis([item]);

      if (!selectedItemsList.includes(item))
        setSelectedItems([...selectedItemsList, item]);

      setItemList((prev) => ({ ...prev, [item]: false }));
    };

    // handle draggable modules (which is all piecies besides the stats)
    const toggleShowEditView = () => {
      setShowEditView(!showEditView);

      if (showEditView) {
        saveSelectedFilters();
      }
    };

    // helper function to update our loading state object
    const updateLoadingObject = (keys, value) => {
      setLoadingObj((prev) => ({
        ...prev,
        ...Object.fromEntries(keys.map((k) => [k, value])),
      }));
    };

    // fetch user widgits
    const setDefaultDashboardView = () => {
      setSelectedItemsList(defaultItems);
      setItemList((prev) =>
        Object.assign(
          {},
          {
            ...prev,
            ...Object.fromEntries(
              Object.entries(prev)
                .filter(([_key]) => defaultItems.includes(_key))
                .map(([_key]) => [_key, false]),
            ),
          },
        ),
      );
      fetchSelectedApis(defaultItems);
    };

    // this handles the "Saved Search" filter chnage on the wealth trigger opportunity layout (cards/table)
    const onFilterChange = () => {
      const { CancelToken } = axios;
      source = CancelToken.source();

      fetchSelectedApis(selectedItemsList);
    };

    // fetch user dashboard layout design, filters, etc.
    const fetchUserDashBoardData = () => {
      /** if no selected items set by the user, then show defaultItems */
      return requestWithAuth('user-dashboard', 'GET', null, null, null).then(
        (response) => {
          if (response) {
            // setUserDashboardData(response);

            // ============================
            // TODO: used in new dashboard?
            // ============================
            // if (
            //   response.dashboard_dropdown_items &&
            //   Object.keys(response.dashboard_dropdown_items).length > 0
            // ) {
            //   setDashboardDropdownItems({
            //     ...response.dashboard_dropdown_items,
            //   });
            //   if (response.dashboard_dropdown_items.relationship_path) {
            //     const dropdownItem =
            //       response.dashboard_dropdown_items.relationship_path;
            //     if (Array.isArray(dropdownItem.id)) {
            //       /** Within RelationshipPaths Dropdown Importsource (linkedin/ Icloud/ google/ outlook) selected*/
            //       boxItem = { import_source: dropdownItem['name'] };
            //       sourceImportId = Array.isArray(dropdownItem.id)
            //         ? dropdownItem.id
            //         : [dropdownItem.id];

            //       teamValue = 'first';
            //     } else {
            //       /** Within RelationshipPaths Dropdown (All/ 1st/ 2nd/ 1st & 2nd / Any teams) selected*/
            //       teamValue = dropdownItem.id;
            //       boxItem = {};
            //       sourceImportId = [];
            //     }
            //   }

            //   if (response.dashboard_dropdown_items.filter) {
            //     props.globalDataStore.multipleFilters.selectedId =
            //       response.dashboard_dropdown_items.filter;
            //     props.globalDataStore.multipleFilters.isFilterApplied =
            //       response.dashboard_dropdown_items.filter !== -1;
            //   }
            //   if (response.dashboard_dropdown_items.sources) {
            //     boxItem = response.dashboard_dropdown_items.sources;
            //     const importIds =
            //       response.dashboard_dropdown_items.sources.file_imports || [];
            //     sourceImportId = [...sourceImportId, ...importIds];
            //   }
            // }
            // ============================

            if (
              response.dashboard_items &&
              response.dashboard_items.length > 0
            ) {
              const filteredItems = response.dashboard_items.filter(
                (x) => x !== '',
              );

              if (filteredItems.length > 0) {
                const selected = [];
                filteredItems.map((item) => {
                  if (Object.keys(dashboardBoxItems).includes(item)) {
                    selected.push(item);
                  }
                });

                // for GTM test we need to add this check for people switching back
                // and forth.
                const diff = DashboardViewNewDefaultItems.filter(
                  (x) => !selected.includes(x),
                );
                diff.forEach((x) => selected.push(x));

                if (selected && selected.length > 0) {
                  setItemList((prev) => ({
                    ...prev,
                    ...Object.fromEntries(selected.map((k) => [k, false])),
                  }));

                  setSelectedItemsList(selected);

                  fetchSelectedApis(selected);
                } else {
                  setDefaultDashboardView();
                }
              }
            } else {
              setDefaultDashboardView();
            }
          }
        },
      );
    };

    // fetch dashboard stats (trigger_total_count) and wealth trigger events (event_data)
    // should only be called directly from "fetchWealthTriggersAndStatistics"
    const _fetchEventsCount = () => {
      const params = {};

      if (teamValue) params.type = teamValue;
      if (typeof teamValue === 'number') {
        params.type = 'team';
        params.team_id = teamValue;
      }

      if (props.dataStore.relationship_quick_search)
        params.quick_search = props.dataStore.relationship_quick_search;

      if (props.globalDataStore.multipleFilters.isFilterApplied) {
        params.is_user_filter = 1;
        params.filter_id = props.globalDataStore.multipleFilters.selectedId;
      } else {
        params.is_user_filter = 0;
      }

      params.date_filter = 'month';
      const body = {
        source_import_ids: [],
      };

      return requestWithAuth(
        'event-summary-filters',
        'POST',
        params,
        body,
        source.token,
      ).then((response) => {
        if (!response || !response.total_counts) {
          setTriggerTotalCount((prev) => ({
            ...prev,
            ...{
              total_monetary_value: 0,
              total_org_count: 0,
              total_event_count: 0,
            },
          }));
          return;
        }

        if (response.total_counts) {
          setTriggerTotalCount((prev) => ({
            ...prev,
            ...{
              total_monetary_value: response.total_counts.total_monetary_value,
              total_org_count: response.total_counts.total_org_count,
              total_event_count: response.total_counts.total_event_count,
            },
          }));
        }
      });
    };

    // handles any of the graph clicks for charts rendered on the page
    const onGraphClick = (id, item, from_wealth_triggers = false) => {
      if (!item) return;

      props.dataStore.summaryFilter = {};
      props.dataStore.summaryFilter = {
        id,
        item,
        ownerTypeId: from_wealth_triggers ? null : teamValue,
        quickSearchRI: props.dataStore.relationship_quick_search,
        selectedId: props.globalDataStore.multipleFilters.selectedId,
        allTeamFiles: sharedFileIds || [],
      };

      if (boxItem && Object.keys(boxItem).length > 0) {
        props.dataStore.summaryFilter.import_source =
          boxItem.import_source || [];
        props.dataStore.summaryFilter.file_imports = boxItem.file_imports || [];
        props.dataStore.summaryFilter.team_file_imports =
          boxItem.team_file_imports || [];
        props.dataStore.summaryFilter.isBoxClicked = true;
      } else if (props.globalDataStore.multipleFilters.selectedId !== -1) {
        const selectedFilter =
          props.globalDataStore.multipleFilters.filterList.filter((item) => {
            return (
              item.value === props.globalDataStore.multipleFilters.selectedId
            );
          });

        const { import_source, file_imports, team_file_imports } =
          selectedFilter[0].filters;

        if (import_source && import_source.length) {
          props.dataStore.summaryFilter.import_source = import_source;
        } else {
          props.dataStore.summaryFilter.import_source = importSourceIds || [];
        }

        if (file_imports && file_imports.length) {
          props.dataStore.summaryFilter.file_imports = file_imports;
        } else {
          props.dataStore.summaryFilter.file_imports = myFileImportsIds || [];
        }

        if (selectedFilter.filter_type !== 'first_degree') {
          if (team_file_imports && team_file_imports.length) {
            props.dataStore.summaryFilter.team_file_imports = team_file_imports;
          } else {
            props.dataStore.summaryFilter.team_file_imports = sharedFileIds.map(
              (file) => {
                return file.id[0];
              },
            );
          }
        }

        props.dataStore.summaryFilter.isBoxClicked = false;
      } else {
        if (id === 'client_inv_org_id' || id === 'client_inv_trigger') {
          props.dataStore.summaryFilter.file_imports =
            props.dataStore.lookUpLists.fileImports.map((file_import) => {
              return file_import.value;
            });
        } else if (id === 'client_inv_files') {
          props.dataStore.summaryFilter.file_imports =
            props.dataStore.lookUpLists.fileImports
              .map((file_import) => {
                return file_import.label === item ? file_import.value : '';
              })
              .filter((id) => id != '');
        } else {
          if (teamValue !== 'all') {
            props.dataStore.summaryFilter.file_imports = myFileImportsIds || [];

            props.dataStore.summaryFilter.import_source = importSourceIds || [];

            props.dataStore.summaryFilter.team_file_imports = sharedFileIds.map(
              (file) => {
                return file.id[0];
              },
            );
          }
        }

        props.dataStore.summaryFilter.isBoxClicked = false;
      }

      /* Click from triggers overview pie chart in Dashboard */
      if (from_wealth_triggers) {
        props.dataStore.summaryFilter.m2_date = getDateRangeFromPeriod('month');

        props.dataStore.summaryFilter.triggers_view = true;
      }

      if (id === 'total_org_count') return true;

      setModalVisibility(true);
    };

    // fetch school data
    const fetchSchoolData = () => {
      setSchoolData(null);
      const body = {};
      return requestNetworkSummary(body, 'top_colleges', 'reload-schools').then(
        (response) => {
          if (response) {
            setSchoolData(response);
          }
        },
      );
    };

    const fetchTitleData = () => {
      setTitleData(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_titles',
        'reload-title_rank',
      ).then((response) => {
        if (response) {
          setTitleData(response);
        }
      });
    };

    const fetchCompanyData = () => {
      setCompanyData(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_ai_org_ids',
        'reload-org_name',
      ).then((response) => {
        if (response) {
          setCompanyData(response);
        }
      });
    };

    const fetchCompanyViabilityData = () => {
      setStockPerformance(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_stock_performance',
        'reload-stock_performance',
      ).then((response) => {
        if (response) {
          setStockPerformance(response);
        }
      });
    };

    const fetchIndustryData = () => {
      setIndustryData(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_industries',
        'reload-industry',
      ).then((response) => {
        if (response) {
          setIndustryData(response);
        }
      });
    };

    const fetchCityData = () => {
      setCityData(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_cities',
        'reload-location_city',
      ).then((response) => {
        if (response) {
          setCityData(response);
        }
      });
    };

    const fetchStateData = () => {
      setStateData(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_states',
        'reload-location_state',
      ).then((response) => {
        if (response) {
          setStateData(response);
        }
      });
    };

    const fetchZipData = () => {
      setZipData(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_zip_codes',
        'reload-home_postal_code',
      ).then((response) => {
        if (response) {
          setZipData(response);
        }
      });
    };

    const fetchSalaryData = () => {
      setSalaryData(null);

      const body = {};
      return requestNetworkSummary(
        body,
        'top_salary_ranges',
        'reload-inferred_salary_range',
      ).then((response) => {
        if (response) {
          setSalaryData(response);
        }
      });
    };

    const fetchWealthSegmentData = () => {
      setWealthSegmentData(null);
      const body = {};
      return requestNetworkSummary(
        body,
        'top_wealth_estimate',
        'reload-top_wealth_estimate',
      ).then((response) => {
        if (response) {
          setWealthSegmentData(response);
        }
      });
    };

    // combine the event counts, trigger statistics and the wealth trigger opporunity fetching
    // into one function. the trigger counts and event data are sourced from two different
    // end-points currently. "opportunities-ds2" and "event-list-summary"
    function fetchWealthTriggersAndStatistics() {
      setEventData([]);
      setFilteredEventData([]);
      setTriggerTotalCount({});
      setWealthTriggerOpportunities([]);

      const p = [_fetchEventsCount(), _fetchWealthTriggerData()];

      return Promise.all(p);
    }

    useEffect(() => {
      const arry = Object.values(wealthTriggerpportunities)
        .flat()
        .map((e) => e.events);

      if (arry.length > 0) {
        const evd = arry.reduce((acc, event) => {
          const ele = acc.find((x) => x.event_type == event.event_type);
          if (!ele) {
            acc.push({
              event_type: event.event_type,
              event_count: 1,
            });
          } else {
            ele.event_count++;
          }
          return acc;
        }, []);

        setEventData((prev) => [...prev, ...evd]);
        setFilteredEventData((prev) => [
          ...prev,
          ...evd.filter((e) => e.event_count > 0),
        ]);
      }
    }, [wealthTriggerpportunities]);

    // parent function for all the wealth triggers.
    // loops over the "triggerTypes" and starts new
    // Promises for each trigger
    // should only be called directly from "fetchWealthTriggersAndStatistics"
    async function _fetchWealthTriggerData() {
      const fetchPromises = triggerTypes.map(async (type) => {
        return _fetchWealthTriggerOpportunities(type);
      });

      await Promise.all(fetchPromises);
    }

    // should only be called directly from "_fetchWealthTriggerData"
    async function _fetchWealthTriggerOpportunities(event) {
      return new Promise((resolve, reject) => {
        // reset the given key since we're re-fetching
        setWealthTriggerOpportunities((prev) => ({ ...prev, [event]: [] }));

        /** returning promise to check for resolve condition */
        let ids = [];
        if (
          props.globalDataStore.multipleFilters.selectedIdList &&
          props.globalDataStore.multipleFilters.selectedIdList.length > 0
        ) {
          ids = props.globalDataStore.multipleFilters.selectedIdList
            .filter((obj) => obj.value && obj.value > 0)
            .map((obj) => obj.value);

          if (!ids || ids.length <= 0) {
            // setLoading((prev) => ({ ...prev, [event]: false }));
            return;
          }
        }
        // setLoading((prev) => ({ ...prev, [event]: true }));

        // api defaults a page_size to 10 if not provided
        const params = {
          page_size: 30,
        };

        // if (
        //   query &&
        //   !(Object.keys(query).length === 0 && query.constructor === Object) &&
        //   (query.daily_opportunities_id || query.daily_opportunities_date)
        // ) {
        //   /**Redirection from email */
        //   if (query.daily_opportunities_id)
        //     params.daily_opportunities_id = query.daily_opportunities_id;

        //   if (query.daily_opportunities_date) {
        //     params.daily_opportunities_date = query.daily_opportunities_date;
        //     setDailyOpportunitiesDate(query.daily_opportunities_date);
        //   }
        // }

        // if (fromPage && fromPage > 0) {
        //   params.from = fromPage;
        //   list = opportunitiesData[event];
        // }
        // if (opportunitiesEstimate) {
        //   params[opportunitiesEstimate] = 1;
        // }

        if (event && event !== 'date') {
          params.event_type = event;
        }

        const body = {
          filter_ids: [],
          collapse_field: 'b2b_id',
        };

        body.filter_ids = ids;
        return requestWithAuth(
          'opportunities-ds2',
          'POST',
          params,
          body,
          source.token,
          'progress_icon',
        ).then((response) => {
          if (response === null) {
            /**resolve  when nodata in typeTrigger */
            if (event !== 'date') resolve();
            return;
          }

          if (!response) {
            resolve();
            return;
          }
          if (response?.count == 0 && event !== 'date') resolve();

          if (response && response.results && response.count) {
            setWealthTriggerOpportunities((prev) => ({
              ...prev,
              [event]: [...response.results],
            }));

            // sets the total unique profiles across all event types
            setTriggerTotalCount((prev) => ({
              ...prev,
              ...{
                total_executives: (prev.total_executives || 0) + response.count,
                total_opportunities:
                  (prev.total_opportunities || 0) + response.results.length,
              },
            }));

            return;
          }

          if (response && response.message) {
            message.error(response.message, 10);
          } else if (response && response.detail) {
            message.error(response.detail, 10);
          }

          setWealthTriggerOpportunities((prev) => ({ ...prev, [event]: [] }));
        });
      });
    }

    // fetch weekly engagement
    const fetchWeeklyEngagementData = () => {
      setWeeklyEngagementData({});
      return requestWithAuth(
        'weekly-engagement-log',
        'GET',
        null,
        null,
        source.token,
      ).then((response) => {
        if (!response) return;
        setWeeklyEngagementData(response);
      });
    };

    /*
      when rendering the design view for the dashboard... consider this:
    */
    useEffect(() => {
      const gridContainers = document.querySelectorAll('.opportunities-grid');
      if (showEditView) {
        // Dynamically adjust grid-template-columns for all grid containers
        gridContainers.forEach((container) => {
          const currentColumns =
            getComputedStyle(container).gridTemplateColumns.split(' ').length;

          // Reduce columns by one
          container.style.gridTemplateColumns = `repeat(${currentColumns - 1}, minmax(0, 1fr))`;
        });
      } else {
        gridContainers.forEach((container) => {
          container.style.removeProperty('grid-template-columns');
        });
      }
    }, [showEditView]);

    // renders the edit view sidebar with the draggable and selectable modules.
    const renderEditDashboardView = () => {
      if (!showEditView) return <></>;

      return (
        <div className="edit-menu-container">
          <div className="editmenu">
            <div className="edit-menu-header">
              Edit View
              <span className="closebutton" onClick={toggleShowEditView}>
                <CloseOutlined />
              </span>
            </div>
            <div className="edit-menu-content">
              <div className="text">Drag up or down to reorder</div>

              <DragSortListBox
                itemList={selectedItemsList}
                swap={swap}
                onCloseButtonClick={onCloseButtonClick}
              />

              {ungroupedLabels.map((item) => {
                if (itemList[item])
                  return (
                    <DragDropListItem
                      id={item}
                      onClick={onListItemSelected}
                      targetKey="DragDropListItem"
                    />
                  );
              })}

              {/* hide groupname if all grouped items are selected to open */}
              {!businessInsightsLabels.every((i) =>
                selectedItemsList.includes(i),
              ) && <div className="group-header">BUSINESS INSIGHTS:</div>}

              {businessInsightsLabels.map((item) => {
                if (itemList[item])
                  return (
                    <DragDropListItem
                      id={item}
                      onClick={onListItemSelected}
                      targetKey="DragDropListItem"
                    />
                  );
              })}
              {!wealthInsightsLabels.every((i) =>
                selectedItemsList.includes(i),
              ) && <div className="group-header">WEALTH INSIGHTS:</div>}

              {wealthInsightsLabels.map((item) => {
                if (itemList[item])
                  return (
                    <DragDropListItem
                      id={item}
                      noDragging={!hasPremiumWealthProperties}
                      onClick={(e) => {
                        if (!hasPremiumWealthProperties) return false;
                        onListItemSelected(e);
                      }}
                      draggableClassName={
                        !hasPremiumWealthProperties
                          ? 'drag-drop-list-item-disabled'
                          : ''
                      }
                      targetKey="DragDropListItem"
                    />
                  );
              })}
              {!locationInsightsLabels.every((i) =>
                selectedItemsList.includes(i),
              ) && <div className="group-header">LOCATION INSIGHTS:</div>}

              {locationInsightsLabels.map((item) => {
                if (itemList[item])
                  return (
                    <DragDropListItem
                      id={item}
                      onClick={onListItemSelected}
                      targetKey="DragDropListItem"
                    />
                  );
              })}
              {!educationInsightsLabels.every((i) =>
                selectedItemsList.includes(i),
              ) && (
                <div className="group-header">
                  EDUCATION & INTERESTS INSIGHTS:
                </div>
              )}

              {educationInsightsLabels.map((item) => {
                if (itemList[item])
                  return (
                    <DragDropListItem
                      id={item}
                      onClick={onListItemSelected}
                      targetKey="DragDropListItem"
                    />
                  );
              })}
            </div>
          </div>
        </div>
      );
    };

    const renderChartBox = (
      id,
      closeKey,
      type,
      chartData,
      totalCount,
      boxName,
      reloadFunc,
    ) => {
      return (
        <ChartBox
          id={id}
          type={type}
          chartData={chartData}
          totalCount={totalCount}
          onClick={onGraphClick}
          showCompanyProfile={showCompanyProfile}
          onReload={reloadFunc}
          box={boxName}
          onCloseButtonClick={() => onCloseButtonClick(closeKey)}
        />
      );
    };

    // function that is passed to the dragable components to render each the individual
    // items
    const renderComponents = (id) => {
      switch (id) {
        case 'TriggersOverview':
          return (
            <TriggersOverview
              eventData={fitleredEventData}
              isLoading={loadingObj['TriggersOverview']}
              onClick={onGraphClick}
            />
          );
        case 'WeeklyEngagement':
          return (
            <WeeklyEngagement
              data={weeklyEngagementData}
              isLoading={loadingObj['WeeklyEngagement']}
            />
          );
        case 'WealthTriggerOpportunities':
          return (
            <DashboardOpportunities
              isLoading={loadingObj['WealthTriggerOpportunities']}
              data={wealthTriggerpportunities}
            />
          );
        case 'TeamMembers':
          return (
            <TeamMembers
              onCloseButtonClick={() => onCloseButtonClick('TeamMembers')}
              teamId={teamValue}
            />
          );
        case 'ExternalRelationshipPaths':
          return (
            <DataSources
              data={dataSources}
              csvBoxes={csvBoxes}
              // onClick={onHandleBoxClickListener}
              showDataSourceProgress={importSourceLoading}
              onCloseButtonClick={() =>
                onCloseButtonClick('ExternalRelationshipPaths')
              }
              showCompanyProfile={showCompanyProfile}
              team={teamValue}
            />
          );
        case 'NetworkDNA':
          return (
            <NetworkDNANew
              industry={industryData}
              stockPerformance={stockPerformance}
              company={companyData}
              title={titleData}
              wealthSegment={wealthSegmentData}
              salary={salaryData}
              zipCodes={zipData}
              city={cityData}
              states={stateData}
              colleges={schoolData}
              isLoading={loadingObj['NetworkDNA']}
            />
          );
        case 'Companies':
          return renderChartBox(
            'org_name',
            'Companies',
            'list',
            companyData ? companyData.top_ai_org_ids : null,
            companyData ? companyData.total_count : 0,
            'company',
            fetchCompanyData,
          );
        case 'CompanyViabiliy':
          return renderChartBox(
            'stock_performance',
            'CompanyViabiliy',
            'vertical',
            stockPerformance ? stockPerformance.top_stock_performance : null,
            stockPerformance ? stockPerformance.total_count : 0,
            undefined,
            fetchCompanyViabilityData,
          );
        case 'Industries':
          return renderChartBox(
            'industry',
            'Industries',
            'horizontal',
            industryData ? industryData.top_industries : null,
            industryData ? industryData.total_count : 0,
            undefined,
            fetchIndustryData,
          );
        case 'TitleRanks':
          return renderChartBox(
            'title_rank',
            'TitleRanks',
            'list',
            titleData ? titleData.top_titles : null,
            titleData ? titleData.total_count : 0,
            'title',
            fetchTitleData,
          );
        case 'InferredSalaryRanges':
          return renderChartBox(
            'inferred_salary_range',
            'InferredSalaryRanges',
            'vertical',
            salaryData ? salaryData.top_salary_ranges : null,
            salaryData ? salaryData.total_count : 0,
            undefined,
            fetchSalaryData,
          );
        case 'WealthSegment':
          return renderChartBox(
            'top_wealth_estimate',
            'WealthSegment',
            'horizontal',
            wealthSegmentData ? wealthSegmentData.top_wealth_estimate : null,
            wealthSegmentData ? wealthSegmentData.total_count : 0,
            undefined,
            fetchWealthSegmentData,
          );
        case 'Cities':
          return renderChartBox(
            'location_city',
            'Cities',
            'list',
            cityData ? cityData.top_cities : null,
            cityData ? cityData.total_count : 0,
            'city',
            fetchCityData,
          );
        case 'StatesandTerritories':
          return renderChartBox(
            'location_state',
            'StatesandTerritories',
            'horizontal',
            stateData ? stateData.top_states : null,
            stateData ? stateData.total_count : 0,
            undefined,
            fetchStateData,
          );
        case 'ZipCodes':
          return renderChartBox(
            'home_postal_code',
            'ZipCodes',
            'horizontal',
            zipData ? zipData.top_zip_codes : null,
            zipData ? zipData.total_count : 0,
            undefined,
            fetchZipData,
          );
        case 'CollegesandUniversities':
          return renderChartBox(
            'schools',
            'CollegesandUniversities',
            'horizontal',
            schoolData ? schoolData.top_colleges : null,
            schoolData ? schoolData.total_count : 0,
            undefined,
            fetchSchoolData,
          );
        default:
          return <></>;
      }
    };

    const fetchAllCount = (key) => {
      /** Filters will be applied so that we can show the data in External Relationship Paths and CSVImport files */
      if (key === 'all_count') {
        setImportSourceIds([]);
        setDataSources({});
        setImportSourceLoading(true);
      } else {
        setCsvBoxes([]);
        setFileImportLoading(true);
      }
      const allCountParams = {
        key,
        type: teamValue,
      };

      if (typeof teamValue === 'number') {
        allCountParams.type = 'team';
        allCountParams.team_id = teamValue;
      } else if (key === 'all_count') {
        allCountParams.type = 'first';
      }

      if (props.dataStore.relationship_quick_search)
        allCountParams.quick_search = props.dataStore.relationship_quick_search;
      if (props.globalDataStore.multipleFilters.isFilterApplied) {
        allCountParams.is_user_filter = 1;
        allCountParams.filter_id =
          props.globalDataStore.multipleFilters.selectedId;
      } else allCountParams.is_user_filter = 0;

      return requestWithAuth(
        'network-summary-new',
        'POST',
        allCountParams,
        null,
        source.token,
        null,
      ).then((response) => {
        if (response) {
          if (key === 'all_count') {
            if (
              response.linkedin !== '' &&
              response.icloud !== '' &&
              response.outlook !== '' &&
              response.google !== ''
            ) {
              setDataSources(response);
              const importSources = [];
              Object.entries(response).map(([key, val]) => {
                if (val.enriched_contacts) {
                  importSources.push(key);
                }
              });
              setImportSourceIds([...importSources]);
            }
          } else {
            setCsvBoxes(response);
            setSharedFileIds([]);
            setMyFileImportsIds([]);
            if (response) {
              const files = [],
                sharedFiles = [];
              Object.entries(response).map(([key, val]) => {
                if (!val.shared && val.id && val.id.length) {
                  files.push(val.id[0]);
                } else sharedFiles.push(val);
              });
              setMyFileImportsIds([...files]);
              setSharedFileIds([...sharedFiles]);
            }
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        key === 'all_count'
          ? setImportSourceLoading(false)
          : setFileImportLoading(false);
      });
    };

    const fetchFileLookups = (key) => {
      const params = { key };

      if (key === 'file_imports') params.type = 'team';
      else params.type = 'first';

      return requestWithAuth(
        'network-summary-new',
        'POST',
        params,
        null,
        source.token,
        null,
      ).then((response) => {
        if (response) {
          if (response) {
            if (key === 'file_imports') {
              const files = [];
              Object.entries(response).map(([key, val]) => {
                if (val.shared && val.id && val.id.length) {
                  files.push(val.id[0]);
                }
              });
              setAllTeamFilesImports([...files]);
            } else {
              if (
                response.linkedin !== '' &&
                response.icloud !== '' &&
                response.outlook !== '' &&
                response.google !== ''
              ) {
                setDataSources(response);
              }
            }
          }
        }
      });
    };

    return (
      <div className="dashboard-new flex flex-row" style={{ gap: '15px' }}>
        {renderEditDashboardView()}

        <div
          className="dashboard-new flex flex-col"
          style={{ gap: 32, paddingTop: 20, paddingBottom: 32 }}
        >
          <DashboardHeaderNew
            isLoading={isLoading}
            data={triggerTotalCount}
            onEditDashboardView={toggleShowEditView}
            onSavedSearchChange={onFilterChange}
          />

          <div className="grow">
            {selectedItemsList.length > 0 && (
              <DraggableGridBox
                render={renderComponents}
                selectedItems={selectedItemsList}
                targetKey="DragDropListItem"
                handleDrop={updateGridOnDrop}
                swap={swap}
                emptyWarning={false}
              />
            )}
          </div>

          {modalVisibility && (
            <PeopleViewModal
              onCancel={() => setModalVisibility(false)}
              showCompanyProfile={showCompanyProfile}
            />
          )}
        </div>
      </div>
    );
  }),
);

export default DashboardNew;

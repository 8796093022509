import './index.scss';
import { inject, observer } from 'mobx-react';

import BellIcon from '../../assets/icons/icn-dash-reminder.svg?react';
import CheckIcon from '../../assets/icons/ai_ic_check.svg?react';
import CloseIcon from '../../assets/icons/ai_ic_close.svg?react';

import { ModalDataStore } from '../../types/GlobalTypes';

import { AiButton } from '../AiButton';

interface PaywallSpeedBumpProps {
  content: 'none' | 'children' | 'profile' | 'prem_wealth_triggers';
  children?: React.ReactNode;
  modalDataStore?: ModalDataStore;
  handleOnClose: () => void;
  handleOnUpgradePremium: () => void;
  handleProfileUnlock: () => void;
}

const PaywallSpeedBump = inject('modalDataStore')(
  observer((props: PaywallSpeedBumpProps) => {
    const renderProfilePaywallContent = () => {
      return (
        <>
          <div
            className="w-full flex flex-col items-center"
            style={{
              fontSize: '24px',
              lineHeight: '34px',
              fontWeight: 500,
              color: 'var(--color-gray-900)',
              paddingBottom: '20px',
            }}
          >
            <span>Upgrade to unlock Premium Attributes</span>
          </div>

          <div
            className="grid grid-cols-2-condense gap-8 features items-start"
            style={{ paddingBottom: '28px' }}
          >
            <CheckIcon width={16} height={16} style={{ marginTop: '4px' }} />
            <span className="feature-bullet">
              <strong>Locations & Property Information</strong> - including
              verified home ownership status and property listings for sales and
              purchases
            </span>

            <CheckIcon width={16} height={16} style={{ marginTop: '4px' }} />
            <span className="feature-bullet">
              <strong>Wealth & Income Information</strong> - including wealth
              segment, income, share balances
            </span>

            <CheckIcon width={16} height={16} style={{ marginTop: '4px' }} />
            <span className="feature-bullet">
              <strong>Plus ALL 14 Wealth Triggers</strong>
            </span>
          </div>
        </>
      );
    };

    const renderPremiumWealthTriggerContent = () => {
      return (
        <>
          <div
            className="w-full flex flex-col items-center"
            style={{
              fontSize: '24px',
              lineHeight: '34px',
              fontWeight: 500,
              color: 'var(--color-gray-900)',
            }}
          >
            <span>Upgrade to unlock</span>
            <div className="flex flex-row gap-4 items-center">
              <span style={{ fontSize: '18px' }}>Premium Wealth Event</span>
              <BellIcon width={24} height={24} />
            </div>
          </div>

          <div className="grid grid-cols-2-condense gap-8 features items-center">
            <CheckIcon width={16} height={16} />
            <span className="feature-bullet">
              Get all 14 Wealth Trigger event types{' '}
            </span>

            <CheckIcon width={16} height={16} />
            <span className="feature-bullet">
              Includes all Wealth Trigger values and details in email alerts
            </span>

            {/* empty span to keep grid structure */}
            <span></span>
            {renderSeeAllWealthTriggers()}
          </div>
        </>
      );
    };

    const renderCloseButton = () => {
      return (
        <AiButton
          buttonclassName="absolute"
          buttonStyle={{
            padding: '4px',
            right: '20px',
            backgroundColor: 'transparent',
          }}
          onClick={props.handleOnClose}
        >
          <CloseIcon width={16} height={16} />
        </AiButton>
      );
    };

    const renderProfileUnlock = () => {
      return (
        <AiButton
          buttonclassName="w-full"
          variant="outline"
          buttonStyle={{
            backgroundColor: 'var(--color-white)',
            color: 'var(--color-gray-300)',
            fontSize: '16px',
            lineHeight: '20px',
            borderRadius: '4px',
            fontWeight: 400,
            borderColor: 'var(--color-gray-50)',
            borderWidth: '2px',
            borderStyle: 'solid',
          }}
          onClick={props.handleProfileUnlock}
        >
          Use 1 credit to unlock Premium attributes for this profile only
        </AiButton>
      );
    };

    const renderUpgradeToPremium = () => {
      return (
        <AiButton
          buttonclassName="w-full"
          buttonStyle={{
            backgroundColor: 'var(--color-premium-green)',
            color: 'var(--color-white)',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 500,
          }}
          onClick={props.handleOnUpgradePremium}
        >
          Upgrade to Premium
        </AiButton>
      );
    };

    const renderNoThanks = () => {
      return (
        <AiButton
          variant="link"
          buttonStyle={{
            backgroundColor: 'var(--color-white)',
            color: 'var(--color-gray-300)',
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: 400,
          }}
          onClick={props.handleOnClose}
        >
          No thanks
        </AiButton>
      );
    };

    const renderSeeAllWealthTriggers = () => {
      return (
        <AiButton
          buttonStyle={{ padding: '4px', placeSelf: 'flex-start' }}
          variant="link"
          onClick={() => {
            props.modalDataStore.paywallSpeedBump.visible = false;
            props.modalDataStore.eventHelpModalVisible = true;
          }}
        >
          See a list of all Wealth Triggers
        </AiButton>
      );
    };

    return (
      <>
        {/* is an absolute button/div so the relative parent must be present */}
        {renderCloseButton()}

        <div
          className="w-full h-full flex flex-col place-items-center"
          style={{
            gap: '12px',
            paddingTop: props.content == 'profile' ? '20px' : '0px',
          }}
        >
          {props.content == 'profile' && renderProfilePaywallContent()}
          {props.content == 'prem_wealth_triggers' &&
            renderPremiumWealthTriggerContent()}
          {props.content == 'children' && props.children}
          {props.content == 'none' && <></>}
        </div>
        <div
          className="w-full flex flex-col place-items-center margin-t-auto"
          style={{ gap: '12px', paddingTop: '12px' }}
        >
          {renderUpgradeToPremium()}

          {renderProfileUnlock()}

          {renderNoThanks()}
        </div>
      </>
    );
  }),
);

export default PaywallSpeedBump;

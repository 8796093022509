import '@gouch/to-title-case';
import './index.scss';

import { inject, observer } from 'mobx-react';
import { Tooltip, Collapse } from 'antd';
import moment from 'moment';
import hash from 'object-hash';

import BlurredContent from '../BlurredContent';

// import DiamondIcon from '../../assets/icons/ai_diamond.svg?react';
import WealthIcon from '../../assets/icons/ai_ic_wealth.svg?react';
import InfoIconNew from '../../assets/icons/ai_info.svg?react';
import IconCheck from '../../assets/icons/ai_ic_check.svg?react';
import IconClose from '../../assets/icons/ai_ic_close.svg?react';
import DownArrow from '../../assets/icons/ai_ic_down_arrow.svg?react';
import DiamondIcon from '../../assets/icons/ai_diamond.svg?react';
import { getWealthScoreLabel, formatValue } from '../../utils/general';
import { FunctionFlags } from '../../utils/constants';
import { isEntitledToPremiumContent } from '../../utils/entitlements';
import { PremiumLabel } from '../AiFunctionalComponents';
import { AiButton } from '../AiButton';
import WorkSvg from './WorkSvg';

const { Panel } = Collapse;

const Professional = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { person, showCompanyProfile } = props;

    const { currentUser } = props.dataStore;
    const prem_wealth = isEntitledToPremiumContent(
      currentUser,
      person,
      FunctionFlags.wealth_properties,
    );

    const getWorkAddress = (data, hashValue) => {
      const address1 = `${
        data?.work_address_line1
          ? data?.work_address_line1.toString().toLowerCase().toTitleCase()
          : ''
      } ${
        data?.work_address_line2
          ? data?.work_address_line2.toString().toLowerCase().toTitleCase()
          : ''
      }`.trim();
      const address2 = `${
        data?.work_city
          ? data?.work_city.toString().toLowerCase().toTitleCase()
          : ''
      } ${data?.work_state ? data?.work_state : ''} ${
        data?.work_postal_code ? data?.work_postal_code : ''
      }`.trim();
      return (
        <>
          {address1 && <div>{address1}</div>}
          {address2 && <div>{address2}</div>}
        </>
      );
    };

    const showPaywallSpeedBump = (e) => {
      e.stopPropagation();
      props.modalDataStore.paywallSpeedBump.content = 'profile';
      props.modalDataStore.paywallSpeedBump.visible = true;
    };

    const showWealthModalVisible = (e) => {
      e.stopPropagation();
      props.modalDataStore.wealthModalVisible = true;
    };

    function renderLinkedinView() {
      return (
        <div className="profile-boxes-right" style={{ gap: '4px' }}>
          {person.norm_linkedin_urls !== undefined &&
            person.norm_linkedin_urls.length > 0 && (
              <>
                View profile on
                <Tooltip
                  placement="bottomRight"
                  color="var(--color-primary)"
                  title={`//${person.norm_linkedin_urls[0]}`}
                >
                  <a
                    href={`//${person.norm_linkedin_urls[0]}`}
                    style={{ color: 'var(--color-link)' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </Tooltip>
              </>
            )}
        </div>
      );
    }

    function renderWealthUnderstated() {
      return (
        <div className="diamond-text">
          <DiamondIcon style={{ height: 31.5 }} />
          <div className="diamond-text-inner">
            <span>
              The estimated wealth and income of this profile may be understated
            </span>
            <InfoIconNew
              style={{ height: 18.75 }}
              className="info-icon"
              onClick={showWealthModalVisible}
            />
          </div>
        </div>
      );
    }

    function renderCompanyTemplate(exp, verifiedDate) {
      let startDate, endDate;
      let carrierDate;
      if (exp.start_date && exp.end_date) {
        startDate = moment(exp.start_date).format('MMM YYYY');
        endDate = moment(exp.end_date).format('MMM YYYY');
        carrierDate = `${startDate} - ${endDate}`;
      } else if (exp.start_date) {
        startDate = moment(exp.start_date).format('MMM YYYY');
      }

      const hashValue = hash([exp.org_name, exp.title]);
      return (
        <div className="company-experience">
          {exp.org_details && exp.org_details.logo_url ? (
            <div className="org-logo">
              <a href={exp.org_details.url} target="_blank" rel="noreferrer">
                <img
                  src={exp.org_details.logo_url}
                  alt={exp.org_name}
                  className="company-logo"
                />
              </a>
            </div>
          ) : (
            <div className="org-logo">
              <WorkSvg svgId="ProfessionalWorkSvg" />
            </div>
          )}
          {(exp.org_name || getWorkAddress(exp)) && (
            <div>
              <div className="profile-labels">Company</div>
              {exp.org_name && (
                <div
                  className={
                    exp.ai_org_id
                      ? 'profile-value link'
                      : 'profile-value link-disabled'
                  }
                  onClick={() => {
                    if (!exp.ai_org_id) return;
                    showCompanyProfile(exp.ai_org_id, exp.org_name);
                  }}
                >
                  {exp.org_name.toTitleCase()}
                </div>
              )}
              <div className="value">{getWorkAddress(exp, hashValue)}</div>
            </div>
          )}
          {exp.title && (
            <div>
              <div className="profile-labels flex">
                Title
                {/* {verifiedDate && (
                  <Tooltip
                    color={'var(--color-white)'}
                    title={() => {
                      return (
                        <div className="tooltip-flex">
                          <VerifyIcon />
                          AI Inspected against two data sources on{' '}
                          {getDate(verifiedDate)}
                        </div>
                      );
                    }}
                  >
                    <VerifyIcon />
                  </Tooltip>
                )} */}
              </div>
              <div className="value" style={{ fontSize: 14 }}>
                {exp.title}
              </div>
            </div>
          )}
          {exp.start_date && (
            <div>
              <div className="profile-labels">
                {carrierDate ? 'Period' : 'Start Date'}
              </div>
              <div className="value" style={{ fontSize: 14 }}>
                {carrierDate ? carrierDate : startDate}
              </div>
            </div>
          )}
        </div>
      );
    }

    function renderWealthComponents() {
      if (
        !person.wealth_components ||
        Object.keys(person.wealth_components).length <= 0
      )
        return <></>;

      const included = [];
      const excluded = [];
      const wealthComponentLabels = {
        age: 'Age',
        career_info: 'Career Info',
        property_ownership: 'Property Ownership',
        property_value: 'Property Value',
        inferred_income: 'Inferred Income',
        zip4_tax_info: 'Zip-Based Info',
        remaining_share_balance: 'Equity Info',
        wealth_triggers: 'Wealth Triggers',
      };
      Object.entries(person.wealth_components).map(([key, value]) => {
        if (value) included.push(wealthComponentLabels[key]);
        else excluded.push(wealthComponentLabels[key]);
      });

      return (
        <>
          {(included.length > 0 || excluded.length > 0) && (
            <>
              <div className="border-class"></div>
              <div></div>

              <div className="wealth-components-header">
                Wealth & Income Components
                <InfoIconNew
                  style={{ height: 16 }}
                  className="info-icon"
                  onClick={showWealthModalVisible}
                />
              </div>
              {included.length > 0 && (
                <div className="profile-labels" style={{ fontSize: 11 }}>
                  <IconCheck
                    style={{ marginBottom: '-2px', marginRight: '4px' }}
                  />{' '}
                  Included
                </div>
              )}
              <div className="included-components profile-labels">
                {included.map((component) => {
                  return <div>{component}</div>;
                })}
              </div>
              {excluded.length > 0 && (
                <div className="profile-labels" style={{ fontSize: 11 }}>
                  <IconClose
                    style={{
                      marginBottom: '-4px',
                      marginRight: '4px',
                      color: '#53d3a7',
                    }}
                  />{' '}
                  Excluded
                </div>
              )}
              <div className="excluded-components profile-labels">
                {excluded.map((component) => {
                  return <div>{component}</div>;
                })}
              </div>
            </>
          )}
        </>
      );
    }
    return (
      <>
        <div style={{ width: '100%' }}>
          <div className="profile-boxes professional-box break-inside-avoid">
            <div className="profile-box-header">
              <div className="profile-titles">Professional</div>
              {renderLinkedinView()}
            </div>
            {/* <div className="border" /> */}
            <div className="profile-box-body" style={{ padding: 0 }}>
              {person.current_experience ? (
                <div className="experience-list">
                  {person.current_experience.map((exp) => {
                    return renderCompanyTemplate(
                      exp,
                      person.current_position_verified_on,
                    );
                  })}
                </div>
              ) : (
                <div className="no-data">No Professional info available </div>
              )}

              {((person.wealth_estimate !== undefined &&
                person.wealth_estimate !== null &&
                person.wealth_estimate >= 0) ||
                person.inferred_salary_range ||
                person.remaining_share_balance) && (
                <div className="wealth-section-parent">
                  <div
                    className="profile-labels flex"
                    style={{
                      gap: '6px',
                      fontFamily: 'Poppins-Medium',
                      paddingBottom: 14,
                    }}
                  >
                    <span>Wealth & Income</span>
                    {!prem_wealth && (
                      <AiButton
                        buttonStyle={{ padding: '0px' }}
                        onClick={showPaywallSpeedBump}
                      >
                        <PremiumLabel includeIcon={true} fill={true} />
                      </AiButton>
                    )}
                  </div>
                  <div className="wealth-section-area">
                    <div className="wealth-icon">
                      <WealthIcon />
                    </div>
                    {person.wealth_estimate && (
                      <div>
                        <div
                          className="profile-labels "
                          style={{ marginBottom: 10 }}
                        >
                          Wealth Segment
                        </div>
                        <div className="value flex">
                          <BlurredContent blurred={!prem_wealth}>
                            {getWealthScoreLabel(person.wealth_estimate)}
                          </BlurredContent>
                        </div>
                      </div>
                    )}

                    {person.inferred_salary_range && (
                      <div>
                        <div
                          className="profile-labels "
                          style={{ marginBottom: 10 }}
                        >
                          Inferred Income
                        </div>
                        <div
                          className="value"
                          style={{ textTransform: 'uppercase' }}
                        >
                          <BlurredContent blurred={!prem_wealth}>
                            {person.inferred_salary_range}
                          </BlurredContent>
                        </div>
                      </div>
                    )}

                    {person.remaining_share_balance &&
                      person.remaining_share_balance.length > 0 &&
                      person.remaining_share_balance.filter((item) => {
                        return item.remaining_share_balance !== 0;
                      }).length > 0 && (
                        <div>
                          <div
                            className="profile-labels"
                            style={{ marginBottom: 10 }}
                          >
                            Share Balance(s)
                          </div>
                          <div className="remaining-share-value-section">
                            {person.remaining_share_balance.map((value) => {
                              if (!value || !value.remaining_share_balance)
                                return <> </>;
                              return (
                                <div className="value remaining-share-value">
                                  <BlurredContent blurred={!prem_wealth}>
                                    {`${
                                      value.remaining_share_balance
                                        ? formatValue(
                                            value.remaining_share_balance,
                                          )
                                        : '$0'
                                    } ${
                                      value.stock_ticker
                                        ? 'of ' + value.stock_ticker
                                        : ''
                                    }`}
                                  </BlurredContent>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                    {renderWealthComponents()}
                    {person.wealth_income_understated &&
                      renderWealthUnderstated()}
                  </div>
                </div>
              )}
              {/* Career History */}
              {person.previous_experience &&
                person.previous_experience.length > 0 && (
                  <div className="career-history profile-boxes">
                    <Collapse
                      className="career-history-collapse"
                      expandIconPosition="left"
                      expandIcon={({ isActive }) => (
                        <DownArrow
                          style={{
                            rotate: isActive ? '180deg' : '0deg',
                          }}
                        />
                      )}
                    >
                      <Panel
                        header={
                          <div className="profile-box-header">
                            <div className="profile-titles">Career History</div>
                            {/* <span>{renderLinkedinView()}</span> */}
                          </div>
                        }
                      >
                        <div
                          className="profile-box-body"
                          style={{
                            padding: 0,
                            backgroundColor: 'var(--color-history)',
                            borderRadius: '0 0 6px 6px',
                          }}
                        >
                          {person.previous_experience.map((exp) => {
                            return renderCompanyTemplate(
                              exp,
                              person.career_history_verified_on,
                            );
                          })}
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }),
);

export default Professional;

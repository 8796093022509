import './index.scss';

import { useEffect, useState } from 'react';
import { Radio, message, Select, Popover } from 'antd';
import { MenuOutlined, PlayCircleFilled } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cloneDeep from 'lodash/cloneDeep';

import LoaderAnimation from '../../components/AidLoader';
import CardsIcon from '../../assets/icons/ai_cards.svg?react';
import FilterIcon from '../../assets/icons/ai_filter_info.svg?react';
import IntegrateCrm from '../../assets/icons/ai_integrate.svg?react';

import WarningMessage from '../../components/WarningMessage';
import { copyUserFilter } from '../../utils/copyUserFilter';
import { requestWithAuth, requestLookupData } from '../../services/api';
import { hasEntitlement } from '../../utils/entitlements';
import { FunctionFlags, IrsItems } from '../../utils/constants';
import FilterListing from './FilterListing';

let source;
const { Option } = Select;

const IrsItemsKeys = IrsItems.map((obj) => {
  return obj.value;
});

const Filters = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [layout, setLayout] = useState('Cards');
    const [loading, setLoading] = useState(false);

    const flags = useFlags();
    const newProfileActive = flags && flags.dntfd8933NewProfileView;

    const { currentUser } = props.dataStore;
    const hasSavedSearches =
      !newProfileActive ||
      (newProfileActive &&
        hasEntitlement(currentUser, FunctionFlags.saved_searches));

    const NAICS_LOOKUP_URL = 'https://s.aidimg.com/cda/naics/naics-v2.json';

    useEffect(() => {
      if (
        props.dataStore.currentUser.type !== 'lite' &&
        props.dataStore.currentUser.type !== 'inactive'
      ) {
        fetchNAICSData();
      }
    }, []);

    const fetchNAICSData = () => {
      requestLookupData(NAICS_LOOKUP_URL)
        .then((response) => {
          if (response && response.length > 0) {
            props.dataStore.lookUpLists.naicsList = response;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const onLayoutChange = (e) => {
      setLayout(e.target.value);
    };

    const getUserFilters = () => {
      setLoading(true);
      const params = {};
      if (props.globalDataStore.orderingParam) {
        params.ordering = props.globalDataStore.orderingParam;
      }
      requestWithAuth('multiple-filters', 'GET', params, null, null)
        .then((response) => {
          setLoading(false);
          const filters = [];
          const allFilterIds = [];
          let selectedIdList = [];
          if (response && response.length > 0) {
            response.map((filter) => {
              /** removing user-created filters those includes
               * 'US Tax Insights Filtervalues' for factset users
               * (as this section is no longer supported for factset users)*/
              if (
                props.dataStore?.currentUser?.slug === 'factset' &&
                filter.filters &&
                IrsItemsKeys.some((key) =>
                  Object.keys(filter.filters).includes(key),
                )
              )
                return;
              else if (filter.name) {
                if (filter.is_active) {
                  props.dataStore.networkFilters.selectedIdValue = filter.id;
                  props.globalDataStore.multipleFilters.isFilterApplied = true;
                  props.globalDataStore.multipleFilters.selectedId = filter.id;
                  selectedIdList = [{ value: filter.id, label: filter.name }];
                  props.dataStore.appliedMasterFilters.filter_type =
                    filter.filter_type;
                  props.globalDataStore.masterFilters.filter_type =
                    filter.filter_type;
                }

                saveUserFilter(
                  filter.filters,
                  filter.id,
                  filter.name,
                  filter.is_active,
                  filter.email_alert,
                  filter.separate_email_alert,
                  filter.filter_type,
                );

                allFilterIds.push({
                  value: filter.id,
                  label: filter.name,
                });

                filters.push({
                  value: filter.id,
                  label: filter.name,
                  is_active: filter.is_active,
                  email_alert: filter.email_alert,
                  separate_email_alert: filter.separate_email_alert,
                  filters: filter.filters,
                  filter_type: filter.filter_type,
                  created_at: filter.created_at,
                  profile_count: filter.profile_count,
                });
              }
            });
          }
          props.globalDataStore.multipleFilters.filterList = filters;
          props.globalDataStore.multipleFilters.allFilterIds = allFilterIds;
          /** if no default filter then add all filters, for opportunities */
          if (selectedIdList.length === 0) {
            props.globalDataStore.multipleFilters.selectedIdList = [
              { value: -1, label: 'All Prospect Filters' },
              ...props.globalDataStore.multipleFilters.allFilterIds,
            ];
          } else {
            props.globalDataStore.multipleFilters.selectedIdList =
              selectedIdList;
          }
          props.globalDataStore.masterFiltersOnSelect.isSaveClicked = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const onEditClick = (
      filters,
      id,
      name,
      is_active,
      email_alert,
      separate_email_alert,
      filter_type,
    ) => {
      props.modalDataStore.filterModal.isVisible = true;
      saveUserFilter(
        filters,
        id,
        name,
        is_active,
        email_alert,
        separate_email_alert,
        filter_type,
      );
    };

    const onDeleteClick = (id) => {
      setLoading(true);
      requestWithAuth(`multiple-filters/${id}`, 'DELETE', null, null, null)
        .then((response) => {
          if (response && response.code === 200) {
            getUserFilters();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error('Please try again', 5);
          console.log(err);
        });
    };

    const saveUserFilter = (
      filters,
      id,
      name,
      is_active,
      email_alert,
      separate_email_alert,
      filter_type,
    ) => {
      props.globalDataStore.reset();
      props.globalDataStore.multipleFilters.isFilterApplied = true;

      if (id) props.globalDataStore.masterFiltersOnSelect.profile_id = id;
      props.globalDataStore.masterFiltersOnSelect.attributes_checked = [];

      const dataStoreFilters = copyUserFilter(
        filters,
        props.globalDataStore.masterFiltersOnSelect,
        props.dataStore.lookUpLists,
        name,
        is_active,
        email_alert,
        separate_email_alert,
      );

      props.globalDataStore.masterFiltersOnSelect = cloneDeep(dataStoreFilters);

      if (
        props.globalDataStore.masterFiltersOnSelect.relmap_org &&
        props.globalDataStore.masterFiltersOnSelect.relmap_org.length > 0
      ) {
        props.globalDataStore.masterFiltersOnSelect.filter_type = 'all';
      } else
        props.globalDataStore.masterFiltersOnSelect.filter_type = filter_type;

      props.globalDataStore.masterFilters = cloneDeep(
        props.globalDataStore.masterFiltersOnSelect,
      );

      if (is_active) {
        props.dataStore.networkFilters = cloneDeep(
          props.globalDataStore.masterFiltersOnSelect,
        );
        props.dataStore.networkFilters.prospect_profile_name = name;
        props.dataStore.networkFilters.default_view = is_active;
        props.dataStore.networkFilters.selectedIdValue = id;
      }
    };

    const makeDefaultClick = (id, name) => {
      setLoading(true);
      const params = {};
      params.name = name;
      params.is_active = 1;
      requestWithAuth(
        `multiple-filters/${id}`,
        'PUT',
        params,
        null,
        source.token,
      )
        .then((response) => {
          setLoading(false);
          if (response) {
            props.dataStore.networkFilters.prospect_profile_name = name;
            props.dataStore.networkFilters.default_view = true;
            getUserFilters();
          } else {
            message.error('Please try again', 5);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const clearDefault = () => {
      const params = {};
      setLoading(true);
      props.globalDataStore.multipleFilters.filterList.map((values) => {
        if (values.is_active) {
          if (values.label) params.name = values.label;
          params.is_active = 0;
          requestWithAuth(
            `multiple-filters/${values.value}`,
            'PUT',
            params,
            null,
            source.token,
          ).then((response) => {
            if (!response) message.error('Please try again', 5);
            setLoading(true);
            getUserFilters();
          });
        }
      });
    };

    const sortData = (value) => {
      props.globalDataStore.orderingParam = value;
      getUserFilters();
    };

    const addNewFilter = () => {
      props.globalDataStore.reset();
      props.globalDataStore.resetMasterFilter();
      props.modalDataStore.filterModal.isVisible = true;
    };

    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();
      if (
        props.dataStore.currentUser.type !== 'lite' &&
        props.dataStore.currentUser.type !== 'inactive'
      ) {
        getUserFilters();
      }
      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    if (
      props.dataStore.currentUser.type === 'lite' ||
      props.dataStore.currentUser.type === 'inactive'
    ) {
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    }

    return (
      <div className="loader-wrapper">
        {loading && <LoaderAnimation loaderId="filters-loader" />}
        <div className={loading ? 'aid-div-disable' : 'aid-div-enable'}>
          <>
            <div className="filter-info">
              <FilterIcon
                style={{
                  width: 38,
                  height: 36,
                  fill: 'var(--color-primary-light)',
                }}
              />
              <div>
                <div
                  style={{
                    color: 'var(--color-primary)',
                    fontSize: '18px',
                  }}
                >
                  {' '}
                  Create and manage saved searches here to track valuable
                  opportunities.
                </div>
                <div
                  style={{
                    color: 'var(--color-primary)',
                    fontSize: '11px',
                    opacity: '0.5',
                  }}
                >
                  Access your Saved Searches throughout the application and be
                  alerted through email when there is an update.
                </div>
              </div>
            </div>
            <div className="filter-layout-header">
              <div style={{ display: 'flex', gap: '30px', fontSize: '13px' }}>
                <button
                  className="add-new-filter"
                  disabled={!hasSavedSearches}
                  style={{ border: 'none' }}
                  onClick={addNewFilter}
                >
                  <IntegrateCrm />
                  <span>Add New Saved Search</span>
                </button>
                <Popover
                  content={
                    <div style={{ width: '100%', height: '100%' }}>
                      <iframe
                        src="https://aidentified.portal.trainn.co/share/Vt9C0J7IfQe99TrkPf4lVg/embed?autoplay=false"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        allow="autoplay; fullscreen"
                        title="Add New Saved Search"
                        /* 4:3 aspect ratio on the video */
                        style={{ width: '75vw', height: '56.25vh' }}
                      ></iframe>
                    </div>
                  }
                  title={null}
                  placement="bottom"
                  trigger="click"
                >
                  <div className="search-filters" id="search-filters-hint">
                    <span>How To Create Saved Searches</span>
                    <PlayCircleFilled />
                    <span className="link">Watch Now</span>
                  </div>
                </Popover>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '30px',
                }}
              >
                <div className="layout-buttons">
                  <Radio.Group onChange={onLayoutChange} value={layout}>
                    <Radio.Button value="Cards">
                      <CardsIcon /> Cards
                    </Radio.Button>
                    <Radio.Button value="List">
                      <MenuOutlined className="toggle-icon" /> List
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div className="sort-section">
                  <div>
                    Sort By &nbsp;
                    <span>
                      <Select
                        defaultValue="-created_at"
                        onChange={sortData}
                        value={props.globalDataStore.orderingParam}
                        style={{
                          width: 105,
                          color: 'var(--color-primary)',
                        }}
                      >
                        <Option value="name">Name (A-Z)</Option>
                        <Option value="-name">Name (Z-A)</Option>
                        <Option value="-created_at">Newest First</Option>
                        <Option value="created_at">Oldest First</Option>
                      </Select>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
          <FilterListing
            data={props.globalDataStore.multipleFilters.filterList}
            makeDefaultApi={makeDefaultClick}
            onEdit={onEditClick}
            onDelete={onDeleteClick}
            clearDefault={clearDefault}
            addNew={addNewFilter}
            from={layout}
          />
        </div>
      </div>
    );
  }),
);

export default Filters;

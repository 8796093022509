import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Modal, Dropdown, Checkbox, Input, message } from 'antd';
import type { DropdownProps, MenuProps, CheckboxProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';

import { useNavigate } from 'react-router';
import {
  formatEventDescription,
  formatEventName,
  formatValue,
} from '../../utils/general';
import { AiButton } from '../AiButton';
import { showConfirm } from '../../utils/popup';
import { requestWithAuth } from '../../services/api';

import { DataStore, EventTriggerInterface, Tag } from '../../types/GlobalTypes';

import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
  handleCrmSync,
} from '../../utils/crmMethods';

import BellIcon from '../../assets/icons/icn-dash-reminder.svg?react';
import TagsIcon from '../../assets/icons/icn-dash-tag.svg?react';
import SelectClubs from '../SelectClubs';
import BlurredContent, { BlurredContentClass } from '../BlurredContent';
import { ConnectionStrength, PremiumLabel } from '../AiFunctionalComponents';
import PaywallSpeedBump from '../Paywall/PaywallSpeedBump';

interface OpportunityCardNewProps {
  dataStore?: DataStore;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: any;
  user: { name: string; crm: string; org_name: string };
  entitledToPremiumContent: boolean;
  showTriggerType: boolean;
  showProfile: (b2bId: number, b2cId: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showCompanyProfile: (id: any, orgName: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addReminder: (isReminder: any, value: any, b2bId: any, b2cId: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleProgress: (isLoading: any) => void;
  createTag: (
    tag: string,
  ) => Promise<Tag> /* This function should just create the Tag */;
}

interface LeadProp {
  b2b_id: number;
  b2c_id: number;
}

const OpportunityCardNew = inject('dataStore')(
  observer((props: OpportunityCardNewProps) => {
    const {
      dataStore,
      data,
      user,
      entitledToPremiumContent,
      tags,
      showTriggerType,
      showProfile,
      showCompanyProfile,
      addReminder,
      toggleProgress,
      createTag,
    } = props;

    const [leadIds, setLeadIds] = useState<LeadProp>({
      b2b_id: null,
      b2c_id: null,
    });
    const [crmOk, setCrmOk] = useState(false);
    const [clubCode, setClubCode] = useState();
    const [showSpeedBump, setShowSpeedBump] = useState(false);

    const { currentUser } = dataStore;
    const { confirm } = Modal;
    const navigate = useNavigate();
    const modalRef = useRef(null);

    const [newTag, setNewTag] = useState<string>('');
    const [tagsOpen, setTagsOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagOptions, setTagOptions] = useState<ItemType[]>();

    const blurContent = !entitledToPremiumContent;

    const selectedProfile = useMemo(
      () => ({
        b2b_id: data.b2b_id,
        b2c_id: data.b2c_id,
      }),
      [data.b2b_id, data.b2c_id],
    );

    useEffect(() => {
      if (crmOk) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        currentUser.creditCount >= 1 || currentUser.isAdmin
          ? handleCrmPopupOk(clubCode)
          : handleSubscribeRedirect();
      }
    }, [crmOk]);

    useEffect(() => {
      if (!tagsOpen) setNewTag('');
    }, [tagsOpen]);

    // Method to handle show popup
    const onCrmClick = (b2b_id, b2c_id) => {
      if (!user.crm) {
        navigate('/connect/export');
        return;
      }
      setLeadIds({ b2b_id, b2c_id });

      if (user.crm === 'sf' && currentUser.org_name === 'INV') clubModel();
      else exportPopUp();
    };

    const clubModel = () => {
      modalRef.current = confirm({
        icon: null,
        okButtonProps: {
          disabled: true,
        },
        onOk() {
          exportPopUp();
          modalRef.current.destroy();
        },
        onCancel() {
          setClubCode(null);
          if (modalRef && modalRef.current) modalRef.current.destroy();
        },
        className: 'club-popUp',
        title: 'Select Club/Location',
        content: (
          <SelectClubs
            setClubVal={(val) => setClubCode(val)}
            modalRef={modalRef}
          />
        ),
      });
    };

    const handleSubscribeRedirect = () => {
      navigate('/account/credits');
    };

    // Method to handle yes click of export csv
    const handleCrmPopupOk = (clubCode) => {
      toggleProgress(true);
      const body = { n2_person: null, club_code: null, org_name: null };
      const person = [];
      if (leadIds && leadIds.b2b_id) person.push(leadIds.b2b_id);
      if (leadIds && leadIds.b2c_id) person.push(leadIds.b2c_id);
      body.n2_person = person;

      if (props.dataStore.currentUser.org_name)
        body.org_name = currentUser.org_name;

      if (clubCode) body.club_code = clubCode.value;

      handleCrmSync(
        currentUser.ownerId,
        user.crm,
        body,
        onCrmTokenExpiry,
        'progress_opportunities',
        props.dataStore.networkFilters,
      ).then(() => {
        setLeadIds({} as LeadProp);
        toggleProgress(false);
      });
    };

    const onCrmTokenExpiry = (url) => {
      navigate('/connect/export', {
        state: {
          crmSource: url === 'redtail-auth/0' ? 'rt' : 'cm',
          response: url === 'redtail-auth/0' ? 'rt' : 'cm',
        },
      });
    };

    const exportPopUp = () => {
      return showConfirm({
        className: 'small-popup',
        closable: true,
        onOk: () => {
          setCrmOk(true);
        },
        title: 'Confirm Export',
        content: (
          <div style={{ textAlign: 'center' }}>
            You selected 1 profile to export
            <br />
            {props.dataStore.currentUser.creditCount >= 1 ||
            props.dataStore.currentUser.isAdmin
              ? 'Please confirm'
              : 'You require an additional 1 credit to proceed'}
          </div>
        ),
        okText:
          props.dataStore.currentUser.creditCount >= 1 ||
          props.dataStore.currentUser.isAdmin
            ? 'Yes'
            : 'Buy More',
      });
    };

    const getGender = (gender: string) => {
      gender = gender?.toUpperCase() || '';

      if (gender === 'M') return 'Male';
      if (gender === 'F') return 'Female';

      return gender;
    };

    const getContactLocation = (city: string, state: string) => {
      if (city && state) return `${city}, ${state}`;
      if (city) return city;
      if (state) return state;

      return '';
    };

    const getDegree = (degree: number) => {
      switch (degree) {
        case 1:
          return '1st Degree';
        case 2:
          return '2nd Degree';
        default:
          return '';
      }
    };

    const getIntroducers = (mutalCount: number) => {
      return `${mutalCount} introducers`;
    };

    const defaultItemStyles: React.CSSProperties = useMemo(
      () => ({
        borderRadius: 0,
        padding: '12px 16px',
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
        borderColor: '#DCE5EB',
      }),
      [],
    );

    const lastItemStyles: React.CSSProperties = useMemo(
      () =>
        Object.assign({}, defaultItemStyles, {
          borderRadius: '0px 0px 4px 4px',
        }),
      [defaultItemStyles],
    );

    const baseMenuStyle: React.CSSProperties = useMemo(
      () =>
        Object.assign({}, defaultItemStyles, {
          color: '#FFFFFF',
          backgroundColor: '#003153',
          cursor: 'default',
          opacity: 1,
          fontWeight: 500,
          minWidth: 200,
          fontSize: 16,
          lineHeight: '22px',
          border: 'none',
          borderRadius: '4px 4px 0px 0px',
        }),
      [defaultItemStyles],
    );

    const updateOpportunityTags = useCallback(
      (list_item: { tag_id: number; tag: string; checked: boolean }) => {
        console.log('list_item', list_item);

        const body = {
          b2b_b2c_list: [selectedProfile],
          tag_ids: [list_item.tag_id],
        };

        const params = {};

        const added = list_item.checked;
        const endpoint = added
          ? 'profile-tags'
          : 'profile-tags/delete-multiple';

        requestWithAuth(endpoint, 'POST', params, body, null)
          .then((response) => {
            if (response && response.code === 200 && response.message == 'ok') {
              message.success(
                `Tags ${added ? 'added' : 'removed'} successfully`,
              );

              const t = added
                ? [
                    ...selectedTags,
                    { id: list_item.tag_id, tag: list_item.tag },
                  ]
                : [
                    ...selectedTags.filter(
                      (tags) => tags.id != list_item.tag_id,
                    ),
                  ];

              setSelectedTags(t);
            }
          })
          .catch((err) => {
            message.error('Please try again', 5);
            console.log(err);
          });
      },
      [selectedProfile, selectedTags],
    );

    const handleTagCreate = useCallback(
      async (event: React.MouseEvent<HTMLButtonElement>) => {
        // console.log('handleTagCreate', event, '\r\ntag?', newTag);
        const tag = await createTag(newTag);

        if (tag.tag === 'Tag name already exists') {
          message.error(`Tag of ${newTag} already exists!`);
          return;
        }

        updateOpportunityTags({ tag_id: tag.id, tag: tag.tag, checked: true });
        setNewTag('');
      },
      [newTag, createTag, updateOpportunityTags],
    );

    const tagSelectables = useCallback(() => {
      const handleCheckChange: CheckboxProps['onChange'] = (e) => {
        // console.log("tag selected", e);
        updateOpportunityTags({
          tag_id: e.target['data-value'] as number,
          tag: e.target['data-tag'] as string,
          checked: e.target.checked,
        });
      };

      const renderSelctableItem = (tag) => {
        return (
          <Checkbox
            id={`${tag.id}-tag`}
            onChange={handleCheckChange}
            data-value={tag.id}
            data-tag={tag.tag}
            checked={selectedTags.some((t) => t.id == tag.id)}
            type="checkbox"
          >
            {tag.tag}
          </Checkbox>
        );
      };

      const renderAddTagOption = () => {
        return (
          <div className="flex flex-row gap-8">
            <Input
              variant="underlined"
              className="grow"
              placeholder="Custom"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
            />
            <AiButton
              buttonStyle={{ padding: '6px 12px' }}
              variant="outline"
              buttonType="primary"
              type="button"
              onClick={handleTagCreate}
            >
              Add
            </AiButton>
          </div>
        );
      };

      const s = defaultItemStyles;
      s['padding'] = '4px 12px';
      s['border'] = 'none';

      const selectables = tags
        .sort((a, b) => b.id - a.id)
        .map((item, index) => ({
          label: renderSelctableItem(item),
          key: item.id,
          style: index == tags.length - 1 ? lastItemStyles : s,
        }));

      selectables.unshift({
        label: 'Add Tags',
        icon: (
          <div className="flex items-center" style={{ color: '#FFFFFF' }}>
            <TagsIcon />
          </div>
        ),
        disabled: true,
        key: -1,
        className: 'ai-opportunity-card-tag-header-item',
        style: baseMenuStyle,
      });

      const lis = lastItemStyles;
      lis['border'] = 'none';
      lis['padding'] = '8px 12px';

      selectables.push({
        label: renderAddTagOption(),
        key: -2,
        style: lis,
      });

      return selectables;
    }, [
      tags,
      baseMenuStyle,
      defaultItemStyles,
      handleTagCreate,
      lastItemStyles,
      newTag,
      updateOpportunityTags,
      selectedTags,
    ]);

    useEffect(() => {
      setTagOptions(tagSelectables());
    }, [tags, tagSelectables]);

    // ========================
    // begin render functions
    // ========================

    const renderPremiumContentLocked = () => {
      return (
        <div
          className="absolute left-0 top-0 right-0 bottom-0 flex flex-col justify-center"
          style={{ gap: 8, alignItems: 'center' }}
        >
          <AiButton
            buttonStyle={{ padding: '0px' }}
            onClick={() => setShowSpeedBump(true)}
          >
            <PremiumLabel fill={true} />
          </AiButton>
          <>
            <span className="ai-opportunity-card-label">
              prospect details locked
            </span>
          </>
        </div>
      );
    };

    const renderContactImage = () => {
      return (
        <BlurredContent blurred={blurContent} blurNode={true}>
          <div className="flex flex-wrap content-center">
            {data.logo_url ? (
              <img src={data.logo_url} alt="logo" height={60} width={60} />
            ) : (
              <img height={60} width={60} />
            )}
          </div>
        </BlurredContent>
      );
    };

    const renderContactInfo = () => {
      return (
        <BlurredContent blurred={blurContent}>
          <div className="flex flex-col gap-0 items-start">
            <a
              className="ai-opportunity-header-contact-name"
              style={blurContent ? { cursor: 'default' } : {}}
              onClick={() => {
                if (blurContent) return false;

                showProfile(data.b2b_id, data.b2c_id);
              }}
            >
              {data.full_name}
            </a>
            <a
              className="ai-opportunity-header-company-name"
              style={blurContent ? { cursor: 'default' } : {}}
              onClick={() => {
                if (!data || !data.ai_org_id) return false;
                if (blurContent) return false;

                showCompanyProfile(data.ai_org_id, data.org_name);
              }}
            >
              {data.org_name}
            </a>
            <span>{data.title}</span>
          </div>
        </BlurredContent>
      );
    };

    const renderRemindMe = () => {
      // TODO: post GTM release, we'll work on being able to see/change/add/remove
      //       reminders that a user has set. Leave this for reference. Replace the
      //       content of "label" in the object below with a call to the function.
      // const check = () => {
      //   return (
      //     <div className='flex flex-row' style={{ justifyContent: 'space-between' }}>
      //       <span>Tomorrow</span>
      //       <TagsIcon width={20} height={20} />
      //     </div>
      //   );
      // }

      const items: MenuProps['items'] = [
        {
          label: 'Remind Me',
          icon: <BellIcon />,
          disabled: true,
          key: -1,
          style: baseMenuStyle,
        },
        { label: 'Tomorrow', key: 1, style: defaultItemStyles },
        { label: '3 Days', key: 3, style: defaultItemStyles },
        { label: '1 Week', key: 7, style: defaultItemStyles },
        { label: '2 Weeks', key: 14, style: lastItemStyles },
      ];

      const style: MenuProps['style'] = {
        padding: 0,
      };

      const onClick: MenuProps['onClick'] = (info) => {
        addReminder(true, info.key, data.b2b_id, data.b2c_id);
      };

      return (
        <Dropdown menu={{ items, onClick, style }} trigger={['click']}>
          <a style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
            <TagsIcon width="20px" height="20px" />
          </a>
        </Dropdown>
      );
    };

    const renderTags = () => {
      const handleOpenChange: DropdownProps['onOpenChange'] = (
        nextOpen,
        info,
      ) => {
        if (blurContent) setTagsOpen(false);

        if (info.source === 'trigger' || nextOpen) {
          setTagsOpen(nextOpen);
        }
      };

      const style: MenuProps['style'] = {
        padding: 0,
      };

      return (
        <Dropdown
          menu={{ items: tagOptions, style }}
          className={blurContent ? BlurredContentClass : ''}
          disabled={blurContent}
          onOpenChange={handleOpenChange}
          open={tagsOpen}
          trigger={['click']}
        >
          <a
            style={{ cursor: blurContent ? 'default' : 'pointer' }}
            onClick={(e) => e.preventDefault()}
          >
            <TagsIcon width="20px" height="20px" />
          </a>
        </Dropdown>
      );
    };

    const renderHeaderActions = () => {
      return (
        <div className="flex flex-row items-start" style={{ gap: 10 }}>
          {/* Remind Me -- TODO: add this post GTM when we're able to re-work the reminder interactions */}
          {/* {renderRemindMe()} */}

          {/* Tags */}
          {renderTags()}

          {/* 
            Export to CRM:
              users can ony have one CRM connected. This will either display the one they have
              or prompt them to connect one. 
          */}
          <AiButton
            type="button"
            buttonType="icon"
            buttonclassName={blurContent ? BlurredContentClass : ''}
            buttonStyle={{
              padding: 0,
              cursor: blurContent ? 'inherit' : 'default',
            }}
            onClick={(e) => {
              if (blurContent) return false;

              e.stopPropagation();
              onCrmClick(data.b2b_id, data.b2c_id);
            }}
          >
            <img
              src={handleCrmIconsAfterIntegration(user.crm)}
              alt={handleCrmLabels(user.crm)}
              width={20}
              height={20}
            />
          </AiButton>
        </div>
      );
    };

    const renderDemographics = () => {
      const gender = getGender(data.gender);
      return (
        <div className="flex items-start flex-row" style={{ gap: 24 }}>
          {gender && (
            <div className="flex items-start grow gap-0 flex-col">
              <span className="ai-opportunity-card-label">Gender</span>
              <span className="ai-opportunity-card-label-value">{gender}</span>
            </div>
          )}
          <div className="flex items-start grow gap-0 flex-col">
            <span className="ai-opportunity-card-label">Age</span>
            <span className="ai-opportunity-card-label-value">{data.age}</span>
          </div>
          <div className="flex items-start grow gap-0 flex-col">
            <span className="ai-opportunity-card-label">Location</span>
            <span className="ai-opportunity-card-label-value">
              {getContactLocation(data.location_city, data.location_state)}
            </span>
          </div>
        </div>
      );
    };

    const renderSavedSearchInfo = () => {
      return <></>;
    };

    const renderTriggerValue = (event: EventTriggerInterface) => {
      return (
        <div className="flex items-start gap-4 flex-col">
          <span className="ai-opportunity-card-label">Trigger Value</span>
          <span className="ai-opportunity-card-label-value">
            {formatValue(event.event_monetary_value)}
          </span>
        </div>
      );
    };

    const renderTriggerContent = (event: EventTriggerInterface) => {
      const hasVal = !!event.event_monetary_value;
      return (
        <div className="flex flex-row" style={{ gap: 24 }}>
          {showTriggerType && renderTrigger(event)}

          {hasVal && renderTriggerValue(event)}

          {renderTriggerDate(event)}
        </div>
      );
    };

    const renderTrigger = (event: EventTriggerInterface) => {
      return (
        <div className="flex items-start gap-4 flex-col">
          <span className="ai-opportunity-card-label">Trigger</span>
          <span className="ai-opportunity-card-label-value trigger-type">
            {formatEventName(event.event_type)}
          </span>
        </div>
      );
    };

    const renderTriggerDate = (event: EventTriggerInterface) => {
      return (
        <div className="flex items-start gap-4 flex-col">
          <span className="ai-opportunity-card-label">Date</span>
          <span className="ai-opportunity-card-label-value">
            {moment(event.event_date).format('MM/DD/YY')}
          </span>
        </div>
      );
    };

    const renderTriggers = (event: EventTriggerInterface) => {
      return (
        <div className={`flex flex-col items-start w-full`} style={{ gap: 16 }}>
          <div
            className="flex grow items-start gap-4 flex-col"
            style={{ flexGrow: 1 }}
          >
            <span className="ai-opportunity-card-label">Wealth Trigger</span>
            <span
              className="ai-opportunity-card-label-value"
              style={{ fontWeight: 500 }}
            >
              {formatEventDescription(event.event_description)}
            </span>
          </div>

          {renderTriggerContent(event)}
        </div>
      );
    };

    const renderRelationshipIntelSeparater = () => {
      return <div className="ai-opportunity-card-rel-itel-sep"></div>;
    };

    const renderRelationshipIntelligence = () => {
      const degree = getDegree(data.degree);
      const introducers = Number.parseInt(data.mutual_count || 0);

      return (
        <div className="flex w-full items-start flex-col gap-8">
          <span className="ai-opportunity-card-label">
            RELATIONSHIP INTELLIGENCE
          </span>
          <div className="flex items-center flex-row" style={{ gap: 12 }}>
            <ConnectionStrength
              strength={
                data.opportunity_score > 200
                  ? 'high'
                  : data.opportunity_score < 119
                    ? 'low'
                    : 'med'
              }
            />

            {degree && renderRelationshipIntelSeparater()}
            {degree && (
              <span className="ai-opportunity-card-label-value">{degree}</span>
            )}

            {introducers > 0 && renderRelationshipIntelSeparater()}
            {introducers > 0 && (
              <span className="ai-opportunity-card-label-value">
                {getIntroducers(introducers)}
              </span>
            )}
          </div>
        </div>
      );
    };

    const renderPremiumSpeedBump = () => {
      return (
        <div
          className="ai-opportunity-card premium-speedbump border-none box-shadow-none absolute left-0 top-0 right-0 bottom-0 items-center"
          style={{
            backgroundColor: 'var(--color-white)',
            padding: '20px 32px',
          }}
        >
          <PaywallSpeedBump
            handleOnClose={() => setShowSpeedBump(false)}
            handleOnUpgradePremium={() =>
              console.log('upgrade premium clicked')
            }
            content="prem_wealth_triggers"
            handleProfileUnlock={() =>
              console.log('upgrade profile unlock clicked')
            }
          />
        </div>
      );
    };

    return (
      <div
        key={data.events.event_id}
        className="ai-opportunity-card gap-20 relative"
      >
        <div className="ai-opportunity-card-header pt-8 pl-8 content-center w-full gap-20 relative">
          {data.logo_url && renderContactImage()}

          <div className="ai-opportunity-card-header-contact grow">
            {renderContactInfo()}
          </div>

          {/* reminder, notification, crm */}
          {renderHeaderActions()}

          {blurContent && renderPremiumContentLocked()}
        </div>

        <div className="ai-opportunity-card-demographics pl-8">
          {renderDemographics()}
        </div>

        {/* <div className='ai-opportunity-card-saved-search w-full'>
          { renderSavedSearchInfo()}
        </div>*/}

        {data.events && (
          <div className="ai-opportunity-card-triggers pl-8 w-full">
            {renderTriggers(data.events)}
          </div>
        )}

        <div className="ai-opportunity-card-rel-int w-full margin-t-auto self-end">
          {renderRelationshipIntelligence()}
        </div>

        {showSpeedBump && renderPremiumSpeedBump()}
      </div>
    );
  }),
);

export default OpportunityCardNew;

import './index.scss';

import {
  Checkbox,
  Collapse,
  Switch,
  Radio,
  message,
  DatePicker,
  Tooltip,
  Tag,
} from 'antd';
import {
  SearchOutlined,
  LinkOutlined,
  CloseOutlined,
  PlayCircleFilled,
  TagsFilled,
  DollarOutlined,
  HolderOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import dayjs from 'dayjs';
import qs from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

import PlusIcon from '../../assets/icons/ai_plus.svg?react';
import MinusIcon from '../../assets/icons/ai_minus.svg?react';
import Professional from '../../assets/icons/ai_professional.svg?react';
import CompanyIcon from '../../assets/icons/ai_company.svg?react';
import ConnectIcon from '../../assets/icons/ai_connect.svg?react';
import IrsIcon from '../../assets/icons/ai_irs.svg?react';
import TriggerIcon from '../../assets/icons/ai_triggers.svg?react';
import TeamIcon from '../../assets/icons/ai_team.svg?react';
import PropertyIcon from '../../assets/icons/ai_property.svg?react';
import AttributeIcon from '../../assets/icons/ai_attribute.svg?react';
import HouseHoldIcon from '../../assets/icons/ai_house_hold.svg?react';
import HelpIcon from '../../assets/icons/help-icon.svg?react';

import {
  ModeledChildPresenceValues,
  companySizeFilter,
  companyType,
  companyViablityRanges,
  fundingRoundList,
  gender,
  interests,
  maritalStatus,
  salaryFilter,
  wealthScoreRanges,
  attributes,
  importSourceList,
  homeOwnerStatusList,
  garageLookup,
  Numbers1To10,
  Properties2To5,
  lotSizeRanges,
  remainingShareValues,
  IrsItems,
  IrsItemsNew,
  importSource,
  storiesList,
  periodList,
  titleRankList,
  propertyTypes,
  propertyValues,
  mappedConnectionsCount,
  credentialsLookup,
  wealthModelItems,
  FunctionFlags,
} from '../../utils/constants';
import {
  getDateFilterLabel,
  getDateFilterValue,
  getLatestMimDateRange,
  getMimEvent,
  getMultipleValueSelected,
  getDateRangeFromPeriod,
  getTeamFileLookup,
  arrayHasValues,
} from '../../utils/general';

import {
  showTriggerFundingRound,
  showTriggerValueSelection,
} from '../../utils/filters';

import filterStates from '../../utils/Statelist';
import { copyUserFilter } from '../../utils/copyUserFilter';
import {
  requestWithAuth,
  requestLookupData,
  updateUserInfo,
} from '../../services/api';

import AgePicker from '../RangePickers/AgePicker';
import NumberRangePicker from '../RangePickers/NumberRangePicker';
import IrsInsightsRangePicker from '../RangePickers/IrsInsightsRangePicker';
import DateRangePicker from '../RangePickers/DateRangePicker';
import ExactDateRangePicker from '../RangePickers/ExactDateRangePicker';
import PostalCodePicker from '../RangePickers/PostalCodePicker';

import CreatableSelect from '../CreatableSelect';
import DataSelect from '../DropdownMenu';
import TreeDataSelect from '../TreeDataSelect';
import LocationSearch from '../LocationSearch';
import TeamMultiCheckbox from '../TeamMultiCheckbox';
import MultiDataSearch from '../MultiDataSearch';
import LocationSlider from '../LocationSlider';
import GoogleSearch from '../GoogleSearch';
import Tags from '../Tags';
import { hasEntitlement } from '../../utils/entitlements';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
const { CheckableTag } = Tag;
const IrsItemsKeys = IrsItems.map((obj) => {
  return obj.value;
});
message.config({
  top: 80,
});

const NAICS_LOOKUP_URL = 'https://s.aidimg.com/cda/naics/naics-v2.json';

const wealthModelValues = wealthModelItems.map((obj) => obj.value);
const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
};

const getImportSourceValue = (importSource) => {
  return importSource.map((source) => source.value);
};
export const CustomPanel = ({
  header,
  title,
  HeaderIcon = null,
  children,
  suffix,
  index,
  handleDrop,
  ...props
}) => {
  const [isHeaderClickable, setIsHeaderClickable] = useState(true);
  return (
    <Collapse.Panel
      collapsible={isHeaderClickable ? 'header' : 'icon'}
      className="panel-header"
      header={
        /** default: The click on header expands the collapse,
         * click/drag on drag handle drags the panel */
        <DragDropContainer
          targetKey="panel"
          dragData={{ title, index }}
          dragClone
          yOnly
          dragHandleClassName="drag-indicator"
          onDragStart={() => {
            //** preventing panel expansion on
            /* dragstart/long click on draghandle */
            setIsHeaderClickable(false);
          }}
          onDrag={() => {
            //** preventing panel expansion of
            /*  target panel  on drag & drop */
            setIsHeaderClickable(false);
          }}
          onDragEnd={() => {
            //** Enabling Panel header click for expansion
            /* only after a time delay, to prevent panel expansion of
            /* target panel at the time instant  of  drag & drop */
            setTimeout(() => {
              setIsHeaderClickable(true);
            }, 100);
          }}
        >
          <DropTarget onHit={(e) => handleDrop(e, index)} targetKey="panel">
            <div className="collapsible-custom-header">
              <HolderOutlined
                className="drag-indicator"
                onClick={() => {
                  //** Enabling Panel header click for expansion
                  /* on click on draghandle as the headerclick
                  /*might be disabled from single click on
                  /*draghandle / failed drag*/
                  setIsHeaderClickable(true);
                }}
              />
              {HeaderIcon ? <HeaderIcon /> : <></>}
              <div
                style={{
                  paddingLeft: HeaderIcon ? '10px' : '0px',
                  width: 'max-content',
                  display: 'flex',
                }}
              >
                <div className="label-main">{header}</div>
                {suffix && (
                  <>
                    &nbsp;
                    <div className="label-sub">{suffix}</div>
                  </>
                )}
              </div>
            </div>
          </DropTarget>
        </DragDropContainer>
      }
      {...props}
    >
      {children}
    </Collapse.Panel>
  );
};
const NewAdvancedFilter = inject(
  'dataStore',
  'modalDataStore',
  'globalDataStore',
)(
  observer((props) => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamLengthArray, setTeamLengthArray] = useState([]);
    const [isPeopleSearchFocused, setisPeopleSearchFocused] = useState(false);
    const [importSourceAllChecked, setImportSourceAllChecked] = useState(false);
    const [fileImportsAllChecked, setFileImportsAllChecked] = useState(false);
    const [showHelpSection, setShowHelpSection] = useState(false);
    const [sharedFileImportsAllChecked, setSharedFileImportsAllChecked] =
      useState(false);
    const [attributesAllCheckedByAdmin, setAttributesAllCheckedByAdmin] =
      useState(false);
    let defaultTabOrder = [
      'Household',
      'Professional',
      'Relationships',
      'Triggers',
      'Company',
      'US Income Tax Insights',
      'Property',
      'Tags',
      'Attributes',
      'Wealth Model',
      'Import Source',
      'Team Members',
    ];

    const location = useLocation();

    const newProfileActive = props.flags && props.flags.dntfd8933NewProfileView;
    const { currentUser } = props.dataStore;
    const hasPremiumTriggers = hasEntitlement(
      currentUser,
      FunctionFlags.premium_wealth_triggers,
    );
    const hasPremiumProperty = hasEntitlement(
      currentUser,
      FunctionFlags.profile_location,
    );
    const hasPremiumWealth = hasEntitlement(
      currentUser,
      FunctionFlags.wealth_properties,
    );

    useEffect(() => {
      if (
        props.dataStore.currentUser.type !== 'lite' &&
        props.dataStore.currentUser.type !== 'inactive'
      ) {
        props.dataStore.resetNetworkFilters();
        fetchTitleRankAggs();
        // props.dataStore.lookUpLists.industries is populated in the header component
        fetchNAICSData();

        const query = qs.parse(location.search, {
          ignoreQueryPrefix: true,
        });
        /**
         * 1. checking if the url is from email link or newsletter ,then only apply the
         *    query params from url to networkFiltres
         * 2. if clicked from RI-Overview graph apply those summaryfilter to networkFilter
         */

        if (
          query &&
          !(Object.keys(query).length === 0 && query.constructor === Object) &&
          (query.type ||
            query.org ||
            query.name ||
            query.date ||
            query.connection_type ||
            query.event_id ||
            query.mimperson_id ||
            query.daily_opportunities_id ||
            query.daily_opportunities_date ||
            query.b2b_id ||
            query.b2c_id ||
            query.org_id ||
            query.org_name ||
            query.mapped_source_b2b_id ||
            query.mapped_source_b2c_id ||
            query.source ||
            query.home_postal_code ||
            query.file_imports)
        ) {
          setNetWorkFilters(query);
          fetchMultipleFilters(false);
        } else if (props.dataStore.summaryFilter) {
          // When clicked from dashboard
          getFilterFromN2SummaryChart();
        } else if (props.dataStore.superConnectorFilter) {
          //When clicked from superconnectors
          getFilterFromSuperConnector();
        } else if (
          location &&
          location.state &&
          location.state.fromPage &&
          location.state.fromPage === 'Status'
        ) {
          props.dataStore.networkFilters.import_source = ['aidentified'];
          props.dataStore.networkFilters.fetchData = true;
        } else if (
          props.globalDataStore.multipleFilters.filterList.length > 0
        ) {
          props.globalDataStore.multipleFilters.filterList.map((filter) => {
            if (filter.is_active) {
              props.dataStore.networkFilters.selectedIdValue = filter.value;
              saveUserFilter(
                filter.filters,
                filter.label,
                filter.is_active,
                filter.email_alert,
                filter.separate_email_alert,
                filter.filter_type,
              );
            }
          });
        } else fetchMultipleFilters(true);
      }

      setShowHelpSection(
        localStorage.getItem('aidentified.pfFinderHelp') &&
          localStorage.getItem('aidentified.pfFinderHelp') === 'true',
      );
      const tabOrder = sessionStorage.getItem('filter-tab-order');

      if (tabOrder) {
        /** This block will execute on refreshing the page */
        const tabOrderArray = tabOrder.split(',');
        if (tabOrderArray && tabOrderArray.length > 0) {
          props.dataStore.currentUser.userTabOrder = tabOrderArray;
          /** newly added filtertabs will not be there for a user who already saved his orders.
           * for that user who don't have the newly added tab we need to add it to the bottom
           */
          if (
            !props.dataStore.currentUser.userTabOrder.includes('Wealth Model')
          ) {
            props.dataStore.currentUser.userTabOrder.push('Wealth Model');
          }
        }
      } else if (props.dataStore.currentUser.userTabOrder.length <= 0) {
        /** This block will execute if the user has no selected tabs, may be a new user */
        props.dataStore.currentUser.userTabOrder = defaultTabOrder;
      } else {
        /** This block will execute on switching to this page, so the filter tab data will be in data store
         *   @defaultTabOrder is used to check for difference in tab order, so that we can call the api only if there is a change on unmount
         */
        if (
          !props.dataStore.currentUser.userTabOrder.includes('Wealth Model')
        ) {
          props.dataStore.currentUser.userTabOrder.push('Wealth Model');
        }
        defaultTabOrder = props.dataStore.currentUser.userTabOrder;
      }
      return () => {
        if (
          JSON.stringify(defaultTabOrder) !==
            JSON.stringify(props.dataStore.currentUser.userTabOrder) &&
          props.dataStore.currentUser.ownerId
        ) {
          updateUserInfo(props.dataStore.currentUser.ownerId, {
            prospect_tab_order: props.dataStore.currentUser.userTabOrder,
          });
          sessionStorage.removeItem('filter-tab-order');
        }
      };
    }, []);

    useEffect(() => {
      /** keep selected filter tabs in a sesssion variable so that,
       *  on unmounting/refreshing component we can update the user selected filtertabs.
       * This is done by fetching the data from session storage on initial useeffect */
      window.sessionStorage.setItem(
        'filter-tab-order',
        props.dataStore.currentUser.userTabOrder,
      );
    }, [props.dataStore.currentUser.userTabOrder]);

    useEffect(() => {
      if (props.dataStore.companyFilter) {
        props.dataStore.resetNetworkFilters();
        Object.entries(props.dataStore.companyFilter).map((filter) => {
          switch (filter[0]) {
            case 'org_name':
            case 'previous_experience_org_name':
              props.dataStore.networkFilters[filter[0]] = [filter[1]];
              break;
            case 'title':
            case 'location_city':
            case 'schools':
              props.dataStore.networkFilters[filter[0]] = [
                { label: filter[1], value: filter[1] },
              ];
              break;
            case 'household_interests':
              props.dataStore.networkFilters.interests = [
                { label: filter[1], value: filter[1] },
              ];
              break;
            case 'home_postal_code':
              props.dataStore.networkFilters.home_postal_code = {
                key: 'exact',
                value: [filter[1], filter[1]],
              };
              break;
            case 'inferred_salary_range':
              // check the item already in networkFilters
              // add only if it doesn't contain
              if (
                props.dataStore.networkFilters[filter[0]] &&
                props.dataStore.networkFilters[filter[0]].some(
                  (obj) => obj.value === filter[1],
                )
              )
                break;
              salaryFilter.map((salary) => {
                if (salary.label === filter[1])
                  props.dataStore.networkFilters.inferred_salary_range.push(
                    salary,
                  );
                return '';
              });
              break;
            case 'industry':
              props.dataStore.networkFilters.industry = [
                { label: filter[1], value: filter[1] },
              ];
              props.dataStore.networkFilters.management_change_date_range = [
                moment().subtract(1, 'years').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD'),
              ];
              break;
            case 'filter_type':
              if (props.dataStore.companyFilter.filterSelected)
                saveUserFilter(
                  props.dataStore.companyFilter.filterSelected.filters,
                  props.dataStore.companyFilter.filterSelected.name,
                  props.dataStore.companyFilter.filterSelected.is_active,
                  props.dataStore.companyFilter.filterSelected.email_alert,
                  props.dataStore.companyFilter.filterSelected
                    .separate_email_alert,
                  props.dataStore.companyFilter.filterSelected.filter_type,
                );
              props.dataStore.networkFilters.filter_type = filter[1];
              props.dataStore.networkFilters.import_source =
                props.dataStore.lookUpLists.import_source;
              props.dataStore.networkFilters.file_imports =
                props.dataStore.networkFilters.filter_type === 'all'
                  ? getImportSourceValue(
                      props.dataStore.lookUpLists.fileImports,
                    )
                  : getImportSourceValue(
                      props.dataStore.lookUpLists.fileImportsWithoutEnrich,
                    );
              props.dataStore.networkFilters.team_file_imports =
                props.dataStore.networkFilters.filter_type === 'all'
                  ? getImportSourceValue(
                      props.dataStore.lookUpLists.teamFileImports,
                    )
                  : props.dataStore.networkFilters.filter_type !==
                      'first_degree'
                    ? getImportSourceValue(
                        props.dataStore.lookUpLists
                          .teamFileImportsWithoutEnrich,
                      )
                    : props.dataStore.networkFilters.filter_type ===
                        'first_degree'
                      ? getImportSourceValue(
                          props.dataStore.lookUpLists.addImportsToTeam,
                        )
                      : [];
              break;
            case 'location_state':
              props.dataStore.networkFilters[filter[0]] = filterStates.filter(
                (state) => {
                  return filter[1] === state.value;
                },
              );
              break;
            case 'title_rank':
              props.dataStore.networkFilters[filter[0]] =
                props.dataStore.lookUpLists.titleRankList.filter((item) => {
                  return filter[1] === item.label;
                });
              break;
            case 'wealth_estimate': {
              if (
                props.dataStore.networkFilters[filter[0]] &&
                props.dataStore.networkFilters[filter[0]].some(
                  (obj) => obj.label === filter[1],
                )
              )
                break;
              const wealthArray = [];
              if (wealthScoreRanges && wealthScoreRanges.length > 0) {
                wealthScoreRanges.map((wealthScore) => {
                  if (wealthScore.label === filter[1])
                    wealthArray.push({
                      value: wealthScore.value,
                      label: wealthScore.label,
                      min: wealthScore.min,
                      max: wealthScore.max,
                    });
                });
              }
              props.dataStore.networkFilters[filter[0]].push(...wealthArray);
              break;
            }
            default:
              break;
          }
        });

        props.dataStore.quick_search = '';
        props.dataStore.networkFilters.fetchData = true;
      }
    }, [props.dataStore.companyFilter]);

    /** When clicking on the Relationship Path people count in group
     *  people by dropdown in people page **/
    useEffect(() => {
      if (
        props.dataStore.groupByValues &&
        props.dataStore.groupByValues.team_member_id
      ) {
        props.dataStore.networkFilters.filter_type = 'second_degree';
        props.dataStore.networkFilters.import_source = [
          'linkedin',
          'icloud',
          'google',
          'outlook',
        ];
        props.dataStore.networkFilters.file_imports = [];
        props.dataStore.networkFilters.team_file_imports = [];
        props.dataStore.teamFilters.team_id_from_summary = '';
        props.dataStore.teamFilters.me_id_from_summary = '';
        updateTeamMembers();
      }
    }, [props.dataStore.groupByValues.team_member_id]);

    /*** When clicking on the Prospect finder link in filters page ***/
    useEffect(() => {
      if (
        props.globalDataStore.multipleFilters.fromFilters &&
        props.globalDataStore.multipleFilters.selectedIdList &&
        props.globalDataStore.multipleFilters.selectedIdList.length > 0
      ) {
        const filterId =
          props.globalDataStore.multipleFilters.selectedIdList[0].value;
        props.dataStore.resetNetworkFilters();
        props.dataStore.networkFilters.selectedIdValue = filterId;
        if (props.globalDataStore.multipleFilters.filterList.length > 0) {
          props.globalDataStore.multipleFilters.filterList.filter((item) => {
            if (item.value === parseInt(filterId)) {
              props.dataStore.networkFilters.selectedIdValue = item.value;
              props.dataStore.networkFilters.prospect_profile_name = item.label;
              saveUserFilter(
                item.filters,
                null,
                null,
                null,
                null,
                item.filter_type,
                false,
              );
            }
          });
        }
      }
    }, [props.globalDataStore.multipleFilters.fromFilters]);

    /** when clicking from view-all click in mapped connections in profile */
    useEffect(() => {
      if (
        props.dataStore.mappedRelationshipsFilter &&
        props.dataStore.mappedRelationshipsFilter.filterName
      ) {
        props.dataStore.resetNetworkFilters();

        const {
          mappedSourceB2bId,
          mappedSourceB2cId,
          filterName,
          mappedSourceInferredSalary,
          mappedSourceWealthEstimate,
          mappedSourceLocationState,
          mappedSourceLocationCity,
          mappedSourceOrgname,
          mappedSourceTitle,
          mappedSourceSchool,
          mappedRelationshipWeights,
          mappedRelationshipTypes,
        } = props.dataStore.mappedRelationshipsFilter;

        props.dataStore.networkFilters.mapped_relationships_filter = {
          mapped_source_b2b_id: mappedSourceB2bId,
          mapped_source_b2c_id: mappedSourceB2cId,
          mapped_source_filter_name: filterName,
          mapped_relationship_types: mappedRelationshipTypes,
          mapped_relationship_weights: mappedRelationshipWeights,
        };

        if (
          mappedSourceInferredSalary &&
          mappedSourceInferredSalary.length > 0
        ) {
          const salary = mappedSourceInferredSalary;
          props.dataStore.networkFilters.inferred_salary_range =
            salaryFilter.filter((item) => {
              return salary.includes(item.value);
            });
        }

        if (
          mappedSourceWealthEstimate &&
          mappedSourceWealthEstimate.length > 0
        ) {
          const wealth =
            props.dataStore.mappedRelationshipsFilter
              .mappedSourceWealthEstimate;
          props.dataStore.networkFilters.wealth_estimate =
            wealthScoreRanges.filter((score) => {
              return wealth.includes(score.value);
            });
        }

        if (mappedSourceLocationState && mappedSourceLocationState.length > 0) {
          props.dataStore.networkFilters.location_state = [
            {
              label: mappedSourceLocationState.toUpperCase(),
              value: mappedSourceLocationState.toUpperCase(),
            },
          ];
        }

        if (mappedSourceLocationCity && mappedSourceLocationCity.length > 0) {
          props.dataStore.networkFilters.location_city = [
            {
              label: mappedSourceLocationCity,
              value: mappedSourceLocationCity,
            },
          ];
        }

        if (mappedSourceOrgname && mappedSourceOrgname.length > 0) {
          props.dataStore.networkFilters.org_name = [
            {
              label: mappedSourceOrgname,
              value: mappedSourceOrgname,
              __isNew__: true,
            },
          ];
        }

        if (mappedSourceTitle && mappedSourceTitle.length > 0) {
          props.dataStore.networkFilters.title = [
            {
              label: mappedSourceTitle,
              value: mappedSourceTitle,
              __isNew__: true,
            },
          ];
        }

        if (mappedSourceSchool && mappedSourceSchool.length > 0) {
          props.dataStore.networkFilters.schools = mappedSourceSchool;
        }

        props.dataStore.resetMappedFilters();

        props.dataStore.quick_search = '';
        props.dataStore.networkFilters.fetchData = true;
      }
    }, [props.dataStore.mappedRelationshipsFilter.filterName]);

    useEffect(() => {
      if (props.dataStore.zipCodeFromProfile) {
        props.dataStore.resetNetworkFilters();
        props.dataStore.networkFilters.home_postal_code =
          props.dataStore.zipCodeFromProfile;
        props.dataStore.quick_search = '';
        props.dataStore.zipCodeFromProfile = '';
        props.dataStore.networkFilters.fetchData = true;
      }
    }, [props.dataStore.zipCodeFromProfile]);

    useEffect(() => {
      if (
        props.dataStore.lookUpLists.team &&
        props.dataStore.lookUpLists.team.length > 0
      )
        updateTeamMembers();
    }, [props.dataStore.lookUpLists.team]);

    useEffect(() => {
      /** When clicked in the dropdown of Groupby - Relationship Path[top_team_members] */
      if (props.dataStore.lookUpLists.isRelationPathSelected) {
        // if already relmap org is applied, then choosing this -> filter should be all,
        // else for all other cases -> filter should be second degree
        if (
          props.dataStore.networkFilters.relmap_org &&
          props.dataStore.networkFilters.relmap_org.length > 0
        ) {
          handleDegreeChange('all');
        } else {
          handleDegreeChange('second_degree');
        }
      }
    }, [props.dataStore.lookUpLists.isRelationPathSelected]);

    useEffect(() => {
      if (props.flags && props.flags.autoAddTeamImports) {
        if (
          props.dataStore.networkFilters.filter_type !== 'first_degree' &&
          props.dataStore.networkFilters.filter_type !== 'all' &&
          !props.dataStore.teamFilters.isAllChecked
        ) {
          //1st arg is handled as events in other calls, hence the structure
          updateTeamMembers({ target: { checked: true } }, true);
        } else if (
          (props.dataStore.networkFilters.filter_type === 'first_degree' ||
            props.dataStore.networkFilters.filter_type === 'all') &&
          props.dataStore.teamFilters.isAllChecked
        ) {
          updateTeamMembers({ target: { checked: false } }, false);
        }
      }
    }, [props.dataStore.networkFilters.filter_type]);

    const fetchTitleRankAggs = () => {
      props.dataStore.lookUpLists.titleRankList = titleRankList;
    };

    const handleShowSavedFilter = (val) => {
      props.onFilterChange(val);
    };

    const fetchNAICSData = () => {
      requestLookupData(NAICS_LOOKUP_URL)
        .then((response) => {
          if (response && response.length > 0) {
            props.dataStore.lookUpLists.naicsList = response;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const fetchMultipleFilters = (isSave) => {
      requestWithAuth('multiple-filters', 'GET', null, null, null)
        .then((response) => {
          const filters = [];
          const allFilterIds = [];
          let selectedIdList = [];
          if (response && response.length > 0) {
            response.map((filter) => {
              /** removing user-created filters those includes
               * 'US Tax Insights Filtervalues' for factset users
               * (as this section is no longer supported for factset users)*/
              if (
                props.dataStore?.currentUser?.slug === 'factset' &&
                filter.filters &&
                IrsItemsKeys.some((key) =>
                  Object.keys(filter.filters).includes(key),
                )
              )
                return;
              else if (filter.name) {
                if (filter.is_active && isSave) {
                  props.dataStore.networkFilters.selectedIdValue = filter.id;
                  selectedIdList = [{ value: filter.id, label: filter.name }];
                  saveUserFilter(
                    filter.filters,
                    filter.name,
                    filter.is_active,
                    filter.email_alert,
                    filter.separate_email_alert,
                    filter.filter_type,
                  );
                }

                filters.push({
                  value: filter.id,
                  label: filter.name,
                  is_active: filter.is_active,
                  email_alert: filter.email_alert,
                  separate_email_alert: filter.separate_email_alert,
                  filters: filter.filters,
                  filter_type: filter.filter_type,
                  created_at: filter.created_at,
                  profile_count: filter.profile_count,
                });

                allFilterIds.push({
                  value: filter.id,
                  label: filter.name,
                });
              }
            });
          }
          props.globalDataStore.multipleFilters.filterList = filters;
          props.globalDataStore.multipleFilters.allFilterIds = allFilterIds;

          /** if no default filter then add all filters, for opportunities */
          if (selectedIdList.length === 0) {
            props.globalDataStore.multipleFilters.selectedIdList = [
              { value: -1, label: 'All Prospect Filters' },
              ...props.globalDataStore.multipleFilters.allFilterIds,
            ];
          } else {
            props.globalDataStore.multipleFilters.selectedIdList =
              selectedIdList;
          }

          props.globalDataStore.masterFiltersOnSelect.isSaveClicked = false;
          const query = qs.parse(location.search, {
            ignoreQueryPrefix: true,
          });
          if (query)
            window.history.replaceState(null, null, window.location.pathname);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const saveUserFilter = (
      filters,
      label,
      isActive,
      email_alert,
      separate_email_alert,
      filterType,
      fetchData,
    ) => {
      handleShowSavedFilter(false);
      if (filterType) props.dataStore.networkFilters.filter_type = filterType;

      props.dataStore.importSourceKeys.isImportSourceSaved = false;
      props.dataStore.importSourceKeys.isImportSourceChecked = false;

      const dataStoreFilters = copyUserFilter(
        filters,
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        label,
        isActive,
        email_alert,
        separate_email_alert,
      );

      props.dataStore.networkFilters = cloneDeep(dataStoreFilters);

      /** For handling redirections specially on the keys import_source,
       * file_imports and team_file_imports. Extra conditions added in addition to the common method */
      if (filters.import_source) {
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.file_imports) {
        props.dataStore.networkFilters.filterFileImports = filters.file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.file_imports = filters.file_imports;
        } else {
          const files = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          props.dataStore.lookUpLists.fileImportsWithoutEnrich &&
            props.dataStore.networkFilters.filterFileImports &&
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.team_file_imports) {
        props.dataStore.networkFilters.filterTeamFileImports =
          filters.team_file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.team_file_imports =
            filters.team_file_imports;
        } else {
          const teamLookupToChoose = getTeamFileLookup(
            props.dataStore.networkFilters.filter_type,
            props.dataStore.lookUpLists.addImportsToTeam,
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
          );
          const files = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          teamLookupToChoose &&
            props.dataStore.networkFilters.filterTeamFileImports &&
            teamLookupToChoose.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterTeamFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.team_file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (!props.dataStore.importSourceKeys.isImportSourceSaved) {
        /** Consider the default imports(importSource, file imports, teamfile imports) scenario
         * based on the degree only if there is no imports(importSource, file imports, teamfile imports) selected within filter */
        if (filterType === 'all') {
          props.dataStore.networkFilters.import_source = [];
          props.dataStore.networkFilters.file_imports = [];
          props.dataStore.networkFilters.team_file_imports = [];
        } else {
          props.dataStore.networkFilters.import_source =
            props.dataStore.lookUpLists.import_source;
          props.dataStore.networkFilters.file_imports =
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((item) => {
              if (item.value) return item.value;
            });
          const teamFileLookupToChoose = getTeamFileLookup(
            props.dataStore.networkFilters.filter_type,
            props.dataStore.lookUpLists.addImportsToTeam,
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
          );
          props.dataStore.networkFilters.team_file_imports =
            teamFileLookupToChoose.map((item) => {
              if (item.value) return item.value;
            });
        }
      }

      if (!fetchData) props.dataStore.networkFilters.fetchData = true;
    };

    const setNetWorkFilters = (query) => {
      if (query.b2b_id || query.b2c_id) {
        setTimeout(() => {
          const { profileModal } = props.modalDataStore;
          profileModal.b2bId = query.b2b_id;
          profileModal.b2cId = query.b2c_id;
          profileModal.mimpersonId = '';
          profileModal.eventsId = '';
          profileModal.fromQuery = true;
          profileModal.isVisible = true;
        }, 10);
      }

      if (query.org_id && query.org_name) {
        // click from extension to open company profile
        setTimeout(() => {
          const { companyProfileModal } = props.modalDataStore;
          companyProfileModal.id = query.org_id;
          companyProfileModal.orgName = query.org_name;
          companyProfileModal.fromQuery = true;
          companyProfileModal.isVisible = true;
        }, 10);
      }

      if (query.mapped_source_b2b_id || query.mapped_source_b2c_id) {
        /** click from mapped relationships in plugin  */
        props.dataStore.networkFilters.mapped_relationships_filter.mapped_source_filter_name = `${query.mapped_source_name}'s Mapped Connections`;
        props.dataStore.networkFilters.mapped_relationships_filter.mapped_source_b2b_id =
          query.mapped_source_b2b_id;
        props.dataStore.networkFilters.mapped_relationships_filter.mapped_source_b2c_id =
          query.mapped_source_b2c_id ? parseInt(query.mapped_source_b2c_id) : 0;
        if (query.mappedSearch) {
          const mappedSearchBody = JSON.parse(query.mappedSearch);
          const {
            inferred_salary_range,
            wealth_estimate,
            mapped_relationship_weights,
            mapped_relationship_types,
            title,
            org_name,
            home_city,
            home_state,
          } = mappedSearchBody;

          if (inferred_salary_range && inferred_salary_range.length > 0) {
            props.dataStore.networkFilters.inferred_salary_range =
              salaryFilter.filter((item) => {
                return inferred_salary_range.includes(item.value);
              });
          }

          if (wealth_estimate && wealth_estimate.length > 0) {
            const wealth = wealth_estimate.map((score) => {
              return score.range.wealth_estimate.gte;
            });
            props.dataStore.networkFilters.wealth_estimate =
              wealthScoreRanges.filter((score) => {
                return wealth.includes(score.min);
              });
          }

          if (
            mapped_relationship_weights &&
            mapped_relationship_weights.length > 0
          ) {
            props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_weights =
              mapped_relationship_weights;
          }
          if (
            mapped_relationship_types &&
            mapped_relationship_types.length > 0
          ) {
            props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_types =
              mapped_relationship_types;
          }

          if (home_state) {
            props.dataStore.networkFilters.location_state = [
              {
                label: home_state.toUpperCase(),
                value: home_state.toUpperCase(),
              },
            ];
          }

          if (home_city) {
            props.dataStore.networkFilters.location_city = [
              {
                label: home_city,
                value: home_city,
              },
            ];
          }

          if (org_name) {
            props.dataStore.networkFilters.org_name = [
              {
                label: org_name,
                value: org_name,
                __isNew__: true,
              },
            ];
          }

          if (title) {
            props.dataStore.networkFilters.title = [
              {
                label: title,
                value: title,
                __isNew__: true,
              },
            ];
          }
        }
      }

      Object.keys(query).map((key) => {
        switch (key) {
          case 'connection_type':
            props.dataStore.networkFilters.filter_type = query.connection_type;
            break;
          case 'type': {
            const type = query.type.toLowerCase();
            props.dataStore.networkFilters.latest_mim_event_type =
              getMimEvent(type);
            break;
          }
          case 'org': {
            props.dataStore.networkFilters.org_name = [
              {
                label: query.org,
                value: query.orgId || query.org,
              },
            ];
            break;
          }
          case 'name':
            props.dataStore.networkFilters.full_name = query.name;
            break;
          case 'date': {
            const date = query.date.toLowerCase();
            const dateFilter = getDateFilterLabel(date);
            const valueDate = getDateFilterValue(dateFilter);
            const dateRange = getLatestMimDateRange(valueDate);
            const rangeStartEndDates = [];
            rangeStartEndDates.push(moment(dateRange.gte).format('YYYY-MM-DD'));
            rangeStartEndDates.push(moment(dateRange.lte).format('YYYY-MM-DD'));
            props.dataStore.networkFilters.m2_date = rangeStartEndDates;
            break;
          }
          case 'event_id':
          case 'mimperson_id':
          case 'opportunities_person_name':
          case 'opportunities_event_name':
          case 'daily_opportunities_id':
          case 'daily_opportunities_date':
          case 'filter_name':
            props.dataStore.networkFilters[key] = query[key];
            break;
          case 'location_city':
          case 'schools':
          case 'title':
            props.dataStore.networkFilters[key] = [
              { label: query[key], value: query[key] },
            ];
            break;
          case 'previous_experience_org_name':
            props.dataStore.networkFilters.previous_experience_org_name = [
              {
                label: query.previous_experience_org_name,
                value: query.prevOrgId || query.previous_experience_org_name,
              },
            ];
            break;
          case 'inferred_salary':
            props.dataStore.networkFilters.inferred_salary_range =
              salaryFilter.filter((salary) => {
                return salary.label === query.inferred_salary;
              });
            break;
          case 'location_state':
            props.dataStore.networkFilters.location_state = filterStates.filter(
              (state) => {
                return state.value === query.location_state;
              },
            );
            break;
          case 'title_rank':
            setTimeout(() => {
              props.dataStore.networkFilters.title_rank =
                props.dataStore.lookUpLists.titleRankList.filter((item) => {
                  return item.label === query.title_rank;
                });
              // since waiting for lookup api title rank should be updated after this mapping and call api.
              props.dataStore.networkFilters.fetchData = true;
            }, 10000);
            break;
          case 'industry':
            props.dataStore.networkFilters.industry = [
              { label: query.industry, value: query.industry },
            ];
            props.dataStore.networkFilters.management_change_date_range = [
              moment().subtract(1, 'years').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
            ];
            break;
          case 'source':
            // setTimeout(() => {
            props.dataStore.networkFilters.import_source = [query.source];
            // }, 500);
            break;
          case 'home_postal_code':
            props.dataStore.networkFilters.home_postal_code = {
              key: 'exact',
              value: [query.home_postal_code, query.home_postal_code],
            };
            break;
          case 'file_imports':
            props.dataStore.networkFilters.file_imports = [
              parseInt(query.file_imports),
            ];
            break;

          default:
            break;
        }
      });

      // props.history.replace();
      if (!Object.keys(query).includes('title_rank'))
        props.dataStore.networkFilters.fetchData = true;
    };

    const updateTeamMembers = (e, fromAll) => {
      const ids = []; // Team wise selected member ids(Array of array).
      let checkedArray = [];
      let checkedArrayNew = [];
      props.dataStore.lookUpLists.uiTeamIds = [];
      const { team } = props.dataStore.lookUpLists;
      const me = {};
      let memberList = [];
      const { email } = props.dataStore.currentUser;
      const teamDataList = [];

      if (team) {
        // mapping response.results
        team.map((teamItem) => {
          memberList = [];
          const teamMembers = teamItem.users;
          // this array will have the initial length of all teams
          teamLengthArray.push(teamMembers.length);
          const memberIdList = [];
          /**  mapping teamItem.users */
          teamMembers.map((members) => {
            memberIdList.push(members.id);
            if (members.email === email) {
              props.dataStore.currentUser.ownerId = members.id;
              props.dataStore.lookUpLists.ownerId = members.id;
              me.value = members.id;
              me.label = 'Me';
            } else {
              const obj = {};
              obj.value = members.id;
              obj.label = `${members.first_name} ${members.last_name}`;
              memberList.push(obj);
            }
            return '';
          });
          if (memberList) {
            memberList.sort(compare);
            if (Object.keys(me).length !== 0) memberList.unshift(me);
          }

          const teamObj = {};
          teamObj.id = teamItem.id;
          teamObj.name = teamItem.name;
          teamObj.users = memberList;
          teamDataList.push(teamObj);

          if (props.dataStore.teamFilters.team_id_from_summary) {
            /** If a team is selected in team dropdown of Dashboard
             * Check if the team selected is the current team,
             * 1 => if so, check the click from teamgraph then add only that member id to this team
             *      or if clicked from non team graph add all members of this team
             * 2 => if this is not the selected team,
             *       add me to this team if clicked from non team graph
             *       add [] if clicked from non team graph- meaning not a member in this team
             */

            if (
              props.dataStore.teamFilters.team_id_from_summary === teamItem.id
            ) {
              props.dataStore.networkFilters.auto_second_degree = true;
              if (props.dataStore.teamFilters.team_ids.length !== 1) {
                /** Dashboard - choose a team in dropdown - click chart other than team.
                   All members of the selected team should be checked and Me in other teams */
                ids.push(memberIdList);

                if (team.length === 1) {
                  props.dataStore.teamFilters.isAllChecked = true;
                } else {
                  props.dataStore.teamFilters.isAllChecked = false;
                }
              } else {
                /** Dashboard - choose a team in dropdown - click a bar in team chart */
                props.dataStore.teamFilters.isAllChecked = false;
                /** add the selected member */
                ids.push(props.dataStore.teamFilters.team_ids);
              }
            } else if (props.dataStore.teamFilters.team_ids.length !== 1) {
              /** Dashboard - choose a team in dropdown - click chart other than team.
                  Then Me should be added in all teams */
              ids.push([props.dataStore.currentUser.ownerId]);
              props.dataStore.teamFilters.isAllChecked = false;
            } else {
              ids.push([]);
            }
          } else if (props.dataStore.teamFilters.me_id_from_summary) {
            /** Dashboard: Clicked me in dropdown and clicked my bar from team
             * only one member will be checked, so all checkbox should be unchecked.*/
            props.dataStore.teamFilters.isAllChecked = false;
            ids.push([props.dataStore.teamFilters.me_id_from_summary]);
          } else if (props.dataStore.groupByValues.team_member_id) {
            /** Click from insights relationship path.
             *  only one member will be checked, so all checkbox should be unchecked. */
            props.dataStore.teamFilters.isAllChecked = false;
            ids.push([props.dataStore.groupByValues.team_member_id]);
          } else if (fromAll) {
            /** All checkbox in New Advanced  Filter Team Members */
            props.dataStore.teamFilters.isAllChecked = e.target.checked;
            if (e.target.checked) {
              ids.push(memberIdList);
            } else {
              ids.push([]);
            }
          } else {
            /**  default case : all of them will be checked , so will pass all of their ids */
            ids.push([]);
            props.dataStore.teamFilters.isAllChecked = false;
          }
          props.dataStore.lookUpLists.uiTeamIds.push(memberIdList);
          return '';
        });
      }
      /* Checked ids in UI */
      props.dataStore.teamFilters.uiTeamIds = ids;
      setTeamMembers(teamDataList);
      checkedArray = ids.length > 0 ? ids.flat() : [];
      checkedArrayNew = Array.from(new Set(checkedArray));
      props.dataStore.teamFilters.checkedTeamIds = checkedArray;
      props.dataStore.teamFilters.team_ids = checkedArrayNew.filter(Number);
      props.dataStore.lookUpLists.teamIds =
        props.dataStore.lookUpLists.uiTeamIds.length > 0
          ? props.dataStore.lookUpLists.uiTeamIds.flat()
          : [];

      props.dataStore.networkFilters.fetchData = true;
      props.dataStore.groupByValues.team_member_id = '';
    };

    const getFilterFromSuperConnector = () => {
      const { filterId } = props.dataStore.superConnectorFilter;
      // props.dataStore.networkFilters.selectedIdValue = filterId;
      if (props.globalDataStore.multipleFilters.filterList.length > 0) {
        props.globalDataStore.multipleFilters.filterList.filter((item) => {
          if (item.value === parseInt(filterId)) {
            props.dataStore.networkFilters.selectedIdValue = item.value;
            saveUserFilter(
              item.filters,
              null,
              null,
              null,
              null,
              item.filter_type,
              false,
            );
          }
        });
      }
    };

    const getFilterFromN2SummaryChart = () => {
      // if clicked from chart=> keep master filter only if radiobutton is on,
      // otherwise reset network filter since it contains master
      const filterId = props.dataStore.summaryFilter.selectedId;
      if (filterId !== -1) {
        props.dataStore.networkFilters.selectedIdValue = filterId;
        if (props.globalDataStore.multipleFilters.filterList.length > 0) {
          props.globalDataStore.multipleFilters.filterList.map((filter) => {
            if (filterId === filter.value) {
              saveUserFilter(
                filter.filters,
                filter.label,
                filter.is_active,
                filter.email_alert,
                filter.separate_email_alert,
                filter.filter_type,
                true,
              );
            }
          });
        }
      }

      const {
        id,
        item,
        ownerTypeId,
        import_source,
        file_imports,
        team_file_imports,
        quickSearchRI,
        allTeamFiles = [],
        isBoxClicked,
        filterType,
        opportunitiesPeriod,
        m2_date,
      } = props.dataStore.summaryFilter;

      if (opportunitiesPeriod) {
        /** Click from opportunities box in Dashboard */

        props.dataStore.networkFilters.m2_date =
          getDateRangeFromPeriod(opportunitiesPeriod);
        props.dataStore.networkFilters.filter_type = filterType;
        props.dataStore.networkFilters.import_source = import_source || [];
        props.dataStore.networkFilters.file_imports = file_imports || [];
        props.dataStore.networkFilters.team_file_imports =
          team_file_imports || [];
        /** Avoid adding team in updateTeamMembers function */
        props.dataStore.teamFilters.team_id_from_summary = -1;
        // if (
        //   props.dataStore.lookUpLists.teamMemberIds &&
        //   props.dataStore.lookUpLists.teamMemberIds.length > 0
        // )
        //   props.dataStore.teamFilters.team_ids =
        //     props.dataStore.lookUpLists.teamMemberIds;
        // reset summary filter
        props.dataStore.summaryFilter = null;
        props.dataStore.networkFilters.fetchData = true;
        return;
      }

      if (quickSearchRI) {
        props.dataStore.quick_search = quickSearchRI;
      }
      props.dataStore.teamFilters.me_id_from_summary = '';
      props.dataStore.teamFilters.team_id_from_summary = '';

      if (typeof ownerTypeId === 'number') {
        props.dataStore.teamFilters.team_id_from_summary = ownerTypeId;
        if (props.dataStore.lookUpLists.team) {
          const memberList = [];
          props.dataStore.lookUpLists.team.map((team) => {
            if (team.id === ownerTypeId) {
              team.users.map((members) => {
                memberList.push(members.id);
              });
            }
          });
          props.dataStore.teamFilters.team_ids = memberList;
        }
      } else {
        props.dataStore.teamFilters.team_id_from_summary = '';
        switch (ownerTypeId) {
          case 'first':
            props.dataStore.teamFilters.team_ids = [
              props.dataStore.currentUser.ownerId,
            ];
            props.dataStore.networkFilters.filter_type = 'first_degree';
            props.dataStore.teamFilters.me_id_from_summary =
              props.dataStore.currentUser.ownerId;
            break;
          case 'second':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'second_degree';
            break;
          case 'combined':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'combined';
            break;
          case 'all':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'all';
            break;
          default:
            break;
        }
      }

      props.dataStore.networkFilters.import_source = import_source || [];
      props.dataStore.networkFilters.file_imports = file_imports || [];
      props.dataStore.networkFilters.team_file_imports =
        team_file_imports || [];

      switch (id) {
        case 'industry':
        case 'location_city':
        case 'schools':
        case 'location_state':
          // case 'event_org_name':
          // check the item already in networkFilters
          // add only if it doesn't contain
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          props.dataStore.networkFilters[id].push({
            label: item,
            value: item,
          });
          break;
        case 'home_postal_code':
          props.dataStore.networkFilters.home_postal_code = {
            key: 'exact',
            value: [item, item],
          };
          break;

        case 'org_name':
        case 'title_rank':
          // check the item already in networkFilters
          // add only if it doesn't contain
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some(
              (obj) => obj.value === item.value,
            )
          )
            break;
          props.dataStore.networkFilters[id].push(item);
          break;
        case 'inferred_salary_range':
          // check the item already in networkFilters
          // add only if it doesn't contain
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          salaryFilter.map((salary) => {
            if (salary.label === item)
              props.dataStore.networkFilters.inferred_salary_range.push(salary);
            return '';
          });
          break;

        case 'stock_performance':
          // check the item already in networkFilters
          // add only if it doesn't contain
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          companyViablityRanges.map((employee) => {
            if (employee.label === item)
              props.dataStore.networkFilters.stock_performance.push(employee);
            return '';
          });
          break;

        case 'interests': {
          // check the item already in networkFilters
          // add only if it doesn't contain
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          const interestsVal = interests.find((obj) => obj.label === item);
          if (interestsVal)
            props.dataStore.networkFilters.interests.push({
              label: item,
              value: interestsVal.value,
            });
          break;
        }
        case 'tags': {
          /**  check the item already in networkFilters
                         add only if it doesn't contain */
          const tagObject = props.dataStore.userTags.find((obj) => {
            return obj.tag === item;
          });
          if (
            !props.dataStore.networkFilters.tags_checked ||
            !Array.isArray(props.dataStore.networkFilters.tags_checked)
          )
            props.dataStore.networkFilters.tags_checked = [];
          else if (
            props.dataStore.networkFilters.tags_checked &&
            props.dataStore.networkFilters.tags_checked.some(
              (obj) => obj.value === tagObject.id,
            )
          )
            break;
          props.dataStore.networkFilters.tags_checked.push(tagObject.id);
          break;
        }
        case 'team_ids':
          props.dataStore.teamFilters.team_ids = item;
          if (isBoxClicked) break;
          if (ownerTypeId) {
            if (item[0] === props.dataStore.currentUser.ownerId) {
              /** team graph of mine */
              props.dataStore.networkFilters.team_file_imports = [];
            } else {
              /** team graph of others */
              props.dataStore.networkFilters.file_imports = [];

              props.dataStore.networkFilters.team_file_imports = allTeamFiles
                .filter((file) => file.owner_id === item[0])
                .reduce((acc, curr) => {
                  return [...acc, curr.id[0]];
                }, []);
            }
          }
          break;
        case 'trigger_by_count': {
          const filterKey = 'latest_mim_event_type';
          if (
            props.dataStore.networkFilters[filterKey] &&
            props.dataStore.networkFilters[filterKey].some(
              (obj) => obj.value === item,
            )
          )
            break;

          props.dataStore.networkFilters[filterKey].push(...getMimEvent(item));

          break;
        }
        case 'top_wealth_estimate': {
          const filterKeyWealth = 'wealth_estimate';
          if (
            props.dataStore.networkFilters[filterKeyWealth] &&
            props.dataStore.networkFilters[filterKeyWealth].some(
              (obj) => obj.label === item,
            )
          )
            break;
          const wealthArray = [];
          if (wealthScoreRanges && wealthScoreRanges.length > 0) {
            wealthScoreRanges.map((wealthScore) => {
              if (wealthScore.label.toLowerCase() === item.toLowerCase())
                wealthArray.push({
                  value: wealthScore.value,
                  label: wealthScore.label,
                  min: wealthScore.min,
                  max: wealthScore.max,
                });
            });
          }
          props.dataStore.networkFilters[filterKeyWealth].push(...wealthArray);
          break;
        }
        case 'client_inv_org_id':
          props.dataStore.groupByValues.org_name = [item];
          props.dataStore.networkFilters.filter_type = 'all';
          // props.dataStore.networkFilters.latest_mim_event_type = props.dataStore.lookUpLists.eventList

          break;
        case 'client_inv_trigger':
          props.dataStore.groupByValues.latest_mim_event_type =
            getMultipleValueSelected(props.dataStore.lookUpLists.eventList, [
              item,
            ]);
          props.dataStore.networkFilters.filter_type = 'all';
          break;
        case 'triggers_overview':
          props.dataStore.networkFilters.latest_mim_event_type =
            getMultipleValueSelected(props.dataStore.lookUpLists.eventList, [
              item,
            ]);
          props.dataStore.networkFilters.m2_date = m2_date;
          break;

        case 'triggers_view_box':
          props.dataStore.networkFilters.latest_mim_event_type =
            props.dataStore.lookUpLists.eventList;
          props.dataStore.networkFilters.m2_date = m2_date;
          break;
        default:
          // props.dataStore.networkFilters[id] = item;
          break;
      }

      // reset summary filter
      props.dataStore.summaryFilter = null;
      props.dataStore.networkFilters.fetchData = true;
    };

    const clearText = (id) => {
      onSearchitemSlected(id, '');
      document.getElementById(id).value = '';
    };

    const resetMapZoom = () => {
      if (props.dataStore.lookUpLists.map) {
        props.dataStore.lookUpLists.map.flyTo({
          zoom: 3,
          center: [-90.039891, 42.058919],
          speed: 2,
        });
      }
    };

    const showPersonProfile = (e, b2bId, b2cId) => {
      e.preventDefault();
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }
      const { profileModal } = props.modalDataStore;
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;
    };

    const onSearchitemSlected = (id, item) => {
      if (
        item === '' &&
        (!props.dataStore.networkFilters[id] ||
          props.dataStore.networkFilters[id] === '')
      )
        return;
      if (id !== 'full_name') handleShowSavedFilter(true);
      props.dataStore.networkFilters[id] = item;
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onLocationSelected = (id, latLng, address) => {
      props.dataStore.networkFilters.slider_radius = 10;
      if (
        latLng === undefined &&
        props.dataStore.networkFilters[id].geo === undefined &&
        address === '' &&
        props.dataStore.networkFilters[id].address === ''
      )
        return;

      handleShowSavedFilter(true);
      props.dataStore.networkFilters[id] = {
        geo: latLng,
        address,
      };

      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3 &&
        !latLng
      ) {
        resetMapZoom();
      } else if (latLng && props.dataStore.lookUpLists.map) {
        props.dataStore.lookUpLists.map.flyTo({
          center: latLng,
          zoom: 6,
          speed: 1,
        });
      } else {
        props.dataStore.networkFilters.fetchData = true;
      }
    };

    const onLocationSliderChanged = (radius) => {
      props.dataStore.networkFilters.slider_radius =
        !radius || radius === 0 ? 0.1 : radius;
    };

    const onLocationSliderChangedComplete = () => {
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3 &&
        !props.dataStore.networkFilters['home_search'].geo
      ) {
        resetMapZoom();
      } else {
        props.dataStore.networkFilters.fetchData = true;
      }
    };

    const onRangeSelected = (id, value) => {
      handleShowSavedFilter(true);
      props.dataStore.networkFilters[id] = value;
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      props.dataStore.networkFilters.fetchData = true;
    };

    const onDropDownItemSlected = (selectedItems, id) => {
      handleShowSavedFilter(true);
      if (id === 'latest_mim_event_type') {
        if (selectedItems && selectedItems.some((el) => el.value === 'all')) {
          const eventsArrayType = props.dataStore.lookUpLists.eventList.filter(
            (e) =>
              e.value !== 'all' &&
              (!e.isPremium || (hasPremiumTriggers && e.isPremium)),
          );
          props.dataStore.networkFilters.latest_mim_event_type =
            eventsArrayType;
        } else {
          props.dataStore.networkFilters.latest_mim_event_type = selectedItems;
        }
      } else {
        props.dataStore.networkFilters[id] = selectedItems;
      }

      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onRadioChange = (e) => {
      handleShowSavedFilter(true);
      props.dataStore.networkFilters[e.target.name] = e.target.value === 'all';

      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      props.dataStore.networkFilters.fetchData = true;
    };

    const onPropertyAttributesChecked = (e) => {
      props.dataStore.networkFilters[e.target.id] = !!e.target.checked;
      handleShowSavedFilter(true);
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onTagsChange = (key, checkedValues) => {
      props.dataStore.networkFilters[key] = checkedValues;
      handleShowSavedFilter(true);
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onWealthModalChecked = (checkedValues) => {
      props.dataStore.networkFilters.wealth_model_checked = checkedValues;
      handleShowSavedFilter(true);
      props.dataStore.networkFilters.fetchData = true;
    };

    const onAttributesChecked = (checkedValues) => {
      props.dataStore.networkFilters.attributes_checked = checkedValues;
      handleShowSavedFilter(true);
      if (props.dataStore.currentUser.isAdmin) {
        /** Total length will be excess by one as nuestar id is added as an
         * alternative to household (having same key).
         */
        let attributesLength = attributes.length - 1;

        if (!props.flags.verifiedAttributes)
          attributesLength = attributesLength - 5;

        setAttributesAllCheckedByAdmin(
          checkedValues.length === attributesLength,
        );
      }
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onAttributesSwitch = (checked) => {
      props.dataStore.networkFilters.attribute_function = !!checked;
      if (
        props.dataStore.networkFilters.attributes_checked &&
        props.dataStore.networkFilters.attributes_checked.length > 0
      ) {
        props.dataStore.networkFilters.fetchData = true;
      }
    };

    const onInterestsSwitch = (checked) => {
      props.dataStore.networkFilters.all_interests = !!checked;
      if (
        props.dataStore.networkFilters.interests &&
        props.dataStore.networkFilters.interests.length > 0
      ) {
        props.dataStore.networkFilters.fetchData = true;
      }
    };

    const onDateRangeChange = (value, id) => {
      handleShowSavedFilter(true);
      if (id === 'm2_date') {
        props.dataStore.networkFilters[id] = value;
        if (
          props.dataStore.lookUpLists.map &&
          props.dataStore.lookUpLists.map.getZoom() > 3
        ) {
          resetMapZoom();
        } else {
          props.dataStore.networkFilters.fetchData = true;
        }
      }
      if (id === 'home_purchase_date' || id === 'home_sale_date') {
        props.dataStore.networkFilters[id] = value;
        props.dataStore.networkFilters.fetchData = true;
      }
    };

    const onYearChange = (date, dateString) => {
      if (date) {
        props.dataStore.networkFilters.effective_year_built = date;
      } else {
        props.dataStore.networkFilters.effective_year_built = '';
      }

      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onCheckBoxChanged = (
      selectedItems,
      id,
      checkedArray,
      arrayCheckFromCheckbox,
    ) => {
      // handleShowSavedFilter(true);
      const arrayCheck = Array.from(new Set(arrayCheckFromCheckbox));
      if (arrayCheck && arrayCheck[0] === true && arrayCheck.length === 1) {
        props.dataStore.teamFilters.isAllChecked = true;
      } else {
        props.dataStore.teamFilters.isAllChecked = false;
      }

      props.dataStore.teamFilters.checkedTeamIds = checkedArray;
      props.dataStore.teamFilters.team_ids = selectedItems;
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onImportFileAllChecked = (e) => {
      props.dataStore.importSourceKeys.isImportSourceChecked = true;

      switch (e.target.id) {
        case 'import_source_all':
          if (e.target.checked) {
            props.dataStore.networkFilters.import_source =
              props.dataStore.lookUpLists.import_source;
            setImportSourceAllChecked(true);
          } else {
            props.dataStore.networkFilters.import_source = [];
            setImportSourceAllChecked(false);
          }

          break;
        case 'file_imports_all':
          if (e.target.checked) {
            props.dataStore.networkFilters.file_imports =
              props.dataStore.networkFilters.filter_type === 'all'
                ? getImportSourceValue(props.dataStore.lookUpLists.fileImports)
                : getImportSourceValue(
                    props.dataStore.lookUpLists.fileImportsWithoutEnrich,
                  );
            setFileImportsAllChecked(true);
          } else {
            props.dataStore.networkFilters.file_imports = [];
            setFileImportsAllChecked(false);
          }

          break;
        case 'team_file_imports_all':
          if (e.target.checked) {
            props.dataStore.networkFilters.team_file_imports =
              props.dataStore.networkFilters.filter_type === 'all'
                ? getImportSourceValue(
                    props.dataStore.lookUpLists.teamFileImports,
                  )
                : getImportSourceValue(
                    getTeamFileLookup(
                      props.dataStore.networkFilters.filter_type,
                      props.dataStore.lookUpLists.addImportsToTeam,
                      props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
                    ),
                  );
            setSharedFileImportsAllChecked(true);
          } else {
            props.dataStore.networkFilters.team_file_imports = [];
            setSharedFileImportsAllChecked(false);
          }
          break;

        default:
          break;
      }
      handleShowSavedFilter(true);
      props.dataStore.networkFilters.fetchData = true;
    };

    const onIrsFilterChange = (checkedVal) => {
      props.dataStore.networkFilters.irs_tax_insights.key = checkedVal[0];
      handleShowSavedFilter(true);
      props.dataStore.networkFilters.fetchData = true;
    };

    const showTaxDescriptionModal = () => {
      props.modalDataStore.taxDescriptionModalVisible = true;
    };

    const showWealthModalVisible = (e) => {
      e.stopPropagation();
      props.modalDataStore.wealthModalVisible = true;
    };

    const onIrsInput = (e, values) => {
      if (values && values.length > 1) {
        props.dataStore.networkFilters.irs_tax_insights.gte = values[0];
        props.dataStore.networkFilters.irs_tax_insights.lte = values[1];
      }
      if (
        props.dataStore.networkFilters.irs_tax_insights &&
        props.dataStore.networkFilters.irs_tax_insights.key &&
        props.dataStore.networkFilters.irs_tax_insights.lte &&
        props.dataStore.networkFilters.irs_tax_insights.gte
      ) {
        handleShowSavedFilter(true);
        props.dataStore.networkFilters.fetchData = true;
      }
    };

    /**
     * OnChange listner method import source check box group
     * @param {*} checkedValues
     */
    const onSourceSelected = (checkedValues, name) => {
      handleShowSavedFilter(true);
      props.dataStore.importSourceKeys.isImportSourceChecked = true;
      try {
        props.dataStore.networkFilters[name] = checkedValues;
        props.dataStore.networkFilters.fetchData = true;
        switch (name) {
          case 'import_source':
            if (
              props.dataStore.networkFilters.import_source &&
              props.dataStore.networkFilters.import_source.length ===
                props.dataStore.lookUpLists.import_source.length
            )
              setImportSourceAllChecked(true);
            break;
          case 'file_imports':
            if (
              props.dataStore.networkFilters.filter_type === 'all'
                ? props.dataStore.networkFilters.file_imports &&
                  props.dataStore.networkFilters.file_imports.length ===
                    props.dataStore.lookUpLists.fileImports.length
                : props.dataStore.networkFilters.file_imports &&
                  props.dataStore.networkFilters.file_imports.length ===
                    props.dataStore.lookUpLists.fileImportsWithoutEnrich.length
            )
              setFileImportsAllChecked(true);
            break;
          case 'team_file_imports': {
            const teamFileLookupToChoose = getTeamFileLookup(
              props.dataStore.networkFilters.filter_type,
              props.dataStore.lookUpLists.addImportsToTeam,
              props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
              props.dataStore.lookUpLists.teamFileImports,
            );
            if (
              props.dataStore.networkFilters.team_file_imports &&
              props.dataStore.networkFilters.team_file_imports.length ===
                teamFileLookupToChoose.length
            )
              setSharedFileImportsAllChecked(true);
            break;
          }
          default:
            break;
        }
      } catch (err) {
        console.error(err);
      }
    };
    const renderSharedCSVOptions = () => {
      const lookup =
        props.dataStore.networkFilters.filter_type === 'all' ||
        (props.flags && props.flags.csvImportsAndDegrees)
          ? props.dataStore.lookUpLists.teamFileImports
          : getTeamFileLookup(
              props.dataStore.networkFilters.filter_type,
              props.dataStore.lookUpLists.addImportsToTeam,
              props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
            );

      return lookup.map((sharedCsv) => {
        return (
          <>
            <Checkbox value={sharedCsv.value}>
              <div className="chekbox-label">{sharedCsv.label}</div>
              {sharedCsv.owner && (
                <div>
                  <span
                    style={{
                      color: 'var(--color-grey-dark)',
                      fontSize: '11px',
                    }}
                  >
                    by
                  </span>{' '}
                  <span
                    className="link"
                    onClick={(e) =>
                      showPersonProfile(e, sharedCsv.b2bId, sharedCsv.b2cId)
                    }
                  >
                    {sharedCsv.owner}
                  </span>
                </div>
              )}
            </Checkbox>
            <br />
          </>
        );
      });
    };

    const setFileImports = (
      lookupList,
      CheckedList,
      lookupListTeam,
      CheckedListTeam,
    ) => {
      const files = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      lookupList &&
        CheckedList &&
        lookupList.map((file) => {
          if (file && file.value && CheckedList.includes(file.value))
            files.push(file.value);
        });
      props.dataStore.networkFilters.file_imports = files;
      if (props.dataStore.networkFilters.filter_type !== 'first_degree') {
        const teamFiles = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        lookupListTeam &&
          CheckedListTeam &&
          lookupListTeam.map((file) => {
            if (file && file.value && CheckedListTeam.includes(file.value))
              teamFiles.push(file.value);
          });
        props.dataStore.networkFilters.team_file_imports = teamFiles;
      }
    };

    const handleDegreeChange = (value) => {
      props.dataStore.networkFilters.filter_type = value;
      if (props.dataStore.networkFilters.filter_type === 'all') {
        if (props.dataStore.importSourceKeys.isImportSourceSaved) {
          const files = [];
          const teamFiles = [];
          files.push(...props.dataStore.networkFilters.filterFileImports);
          teamFiles.push(
            ...props.dataStore.networkFilters.filterTeamFileImports,
          );
          if (props.dataStore.importSourceKeys.isImportSourceChecked) {
            files.push(...props.dataStore.networkFilters.file_imports);
            teamFiles.push(...props.dataStore.networkFilters.team_file_imports);
          }
          setFileImports(
            props.dataStore.lookUpLists.fileImports,
            files,
            props.dataStore.lookUpLists.teamFileImports,
            teamFiles,
          );
        } else {
          props.dataStore.networkFilters.import_source = [];
          props.dataStore.networkFilters.file_imports = [];
          props.dataStore.networkFilters.team_file_imports = [];
          props.dataStore.importSourceKeys.isImportSourceChecked = false;
        }
      } else {
        const teamFileLookupToChoose = getTeamFileLookup(
          props.dataStore.networkFilters.filter_type,
          props.dataStore.lookUpLists.addImportsToTeam,
          props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
        );
        if (
          props.dataStore.importSourceKeys.isImportSourceSaved ||
          props.dataStore.importSourceKeys.isImportSourceChecked
        ) {
          const files = [];
          const teamFiles = [];
          if (props.dataStore.importSourceKeys.isImportSourceSaved) {
            files.push(...props.dataStore.networkFilters.filterFileImports);
            teamFiles.push(
              ...props.dataStore.networkFilters.filterTeamFileImports,
            );
          }
          if (props.dataStore.importSourceKeys.isImportSourceChecked) {
            files.push(...props.dataStore.networkFilters.file_imports);
            teamFiles.push(...props.dataStore.networkFilters.team_file_imports);
          }
          setFileImports(
            props.dataStore.lookUpLists.fileImportsWithoutEnrich,
            files,
            teamFileLookupToChoose,
            teamFiles,
          );
        } else {
          props.dataStore.networkFilters.import_source = importSource;
          props.dataStore.networkFilters.file_imports =
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((item) => {
              if (item.value) return item.value;
            });
          props.dataStore.networkFilters.team_file_imports =
            teamFileLookupToChoose.map((item) => {
              if (item.value) return item.value;
            });
        }
      }
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onRelationshipProfileSelected = (value) => {
      if (value && value.indexOf(',') > -1) {
        const res = value.split(',');
        props.dataStore.networkFilters.mapped_relationships_filter.mapped_source_filter_name =
          res[2] ? res[2] : '';
        props.dataStore.networkFilters.mapped_relationships_filter.mapped_source_b2b_id =
          res[0];
        props.dataStore.networkFilters.mapped_relationships_filter.mapped_source_b2c_id =
          res[1];
      } else {
        props.dataStore.networkFilters.mapped_relationships_filter = {};
      }

      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const findAttributes = () => {
      let attributesAllowed = props.dataStore.currentUser.isAdmin
        ? attributes
        : attributes.slice(0, -4);

      if (!props.flags.verifiedAttributes) {
        attributesAllowed = attributesAllowed.filter((el) => {
          return (
            el.label.split(' ')[0] !== 'AI' ||
            el.label === 'AI Inspected Home Address' ||
            el.label === 'AI Inspected Home Owner Status'
          );
        });
      }

      if (props.flags.neustarId) {
        attributesAllowed = attributesAllowed.filter(
          (el) => el.label !== 'Household',
        );
      } else {
        attributesAllowed = attributesAllowed.filter(
          (el) => el.label !== 'Neustar ID',
        );
      }

      return attributesAllowed;
    };

    const handleDrop = (e, index) => {
      //e.stopPropagation();
      swap(e.dragData.index, index, e.dragData);
    };
    const swap = (fromIndex, toIndex, dragData) => {
      const newItem = dragData.title;
      const newItems = [...props.dataStore.currentUser.userTabOrder];
      newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, newItem);
      props.dataStore.currentUser.userTabOrder = [...newItems];
    };

    const findPanel = (title, index) => {
      switch (title) {
        case 'Household':
          return (
            <CustomPanel
              index={index}
              title={title}
              header="Household"
              // suffix="Attributes"
              HeaderIcon={() => <HouseHoldIcon className="grey-icons" />}
              handleDrop={handleDrop}
            >
              <div>
                <>
                  <div>
                    <div
                      style={{
                        color: 'var(--color-grey-dark)',
                        paddingBottom: '5px',
                      }}
                    >
                      Home Search
                    </div>
                    <Radio.Group
                      name="all_homes"
                      onChange={onRadioChange}
                      value={
                        props.dataStore.networkFilters.all_homes
                          ? 'all'
                          : 'primary'
                      }
                    >
                      <Radio
                        style={{
                          color: 'var(--color-grey-dark)',
                        }}
                        value="primary"
                      >
                        Primary
                      </Radio>
                      <Radio
                        style={{
                          color: 'var(--color-grey-dark)',
                        }}
                        // defaultChecked = {true}
                        value="all"
                      >
                        All
                      </Radio>
                    </Radio.Group>
                  </div>
                  <CreatableSelect
                    id="home_address_suggest"
                    placeholder="Home Street Address"
                    isMulti
                    className="relative"
                    from="network"
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                  {props.flags && props.flags.excludeHomeAddress && (
                    <CreatableSelect
                      id="exc_home_address_suggest"
                      placeholder="Exclude Home Address"
                      isMulti
                      className="relative"
                      from="network"
                      onSelect={() => handleShowSavedFilter(true)}
                    />
                  )}

                  <div style={{ marginTop: '10px' }}>
                    <MultiDataSearch
                      id="location_city"
                      placeholder="Home City"
                      isMulti
                      from="network"
                      // TODO: remove this onSelect when changing flag check
                      onSelect={() => handleShowSavedFilter(true)}
                    />
                  </div>

                  <div className="with-info-icon" style={{ marginTop: '10px' }}>
                    <LocationSearch
                      id="home_search"
                      value={props.dataStore.networkFilters.home_search}
                      placeholder="Geo Home City"
                      onLocationSelected={onLocationSelected}
                      from="network"
                      isDisabled={props.dataStore.networkFilters.all_homes}
                    />
                    <HelpIcon
                      id="info-home_search"
                      className="info-icon"
                      height="14px"
                      width="14px"
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <br />
                    <LocationSlider
                      radius={props.dataStore.networkFilters.slider_radius}
                      onChange={onLocationSliderChanged}
                      onAfterChange={onLocationSliderChangedComplete}
                      isDisabled={
                        props.dataStore.networkFilters['home_search']
                          .address === '' ||
                        props.dataStore.networkFilters.all_homes
                      }
                    />
                  </div>
                </>

                <div style={{ marginBottom: '10px' }}>
                  <DataSelect
                    id="location_state"
                    value={props.dataStore.networkFilters.location_state}
                    dropDownList={filterStates}
                    placeholder="State"
                    isMulti
                    onItemSelected={onDropDownItemSlected}
                  />
                </div>
                <>
                  <MultiDataSearch
                    id="county"
                    placeholder="County"
                    isMulti
                    isDisabled={props.dataStore.networkFilters.all_homes}
                    from="network"
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </>
                <>
                  <div
                    className={'rangepicker-placeholder-active'}
                    style={{ marginTop: '10px' }}
                  >
                    Zip Code
                  </div>
                  <PostalCodePicker
                    id="home_postal_code"
                    zipCode={props.dataStore.networkFilters.home_postal_code}
                    onValueSelected={onRangeSelected}
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '20px',
                  }}
                >
                  <label className="filter-item-placeholder">
                    Apply Interests
                  </label>
                  <Switch
                    size="small"
                    checkedChildren="ALL"
                    unCheckedChildren="ANY"
                    checked={props.dataStore.networkFilters.all_interests}
                    onChange={onInterestsSwitch}
                  />
                </div>
                <div className="with-info-icon">
                  <DataSelect
                    id="interests"
                    value={props.dataStore.networkFilters.interests}
                    isMulti
                    dropDownList={interests}
                    placeholder="Modeled Interests"
                    onItemSelected={onDropDownItemSlected}
                  />
                  <HelpIcon
                    id="info-interests"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <div className="rangepicker-placeholder-active">Age</div>
                  <AgePicker
                    id="age"
                    age={props.dataStore.networkFilters.age}
                    onValueSelected={onRangeSelected}
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </div>
                <DataSelect
                  id="gender"
                  value={props.dataStore.networkFilters.gender}
                  dropDownList={gender}
                  placeholder="Sex"
                  isMulti={false}
                  onItemSelected={onDropDownItemSlected}
                  closeMenuOnSelect
                />
                <div className="with-info-icon">
                  <DataSelect
                    id="marital_status"
                    value={props.dataStore.networkFilters.marital_status}
                    dropDownList={maritalStatus}
                    placeholder="Modeled Marital Status"
                    isMulti={false}
                    onItemSelected={onDropDownItemSlected}
                    closeMenuOnSelect
                  />
                  <HelpIcon
                    id="info-marital-status"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>
                <div className="with-info-icon">
                  <DataSelect
                    id="presence_of_children"
                    value={props.dataStore.networkFilters.presence_of_children}
                    dropDownList={ModeledChildPresenceValues}
                    placeholder="Modeled Presence of Children"
                    closeMenuOnSelect
                    onItemSelected={onDropDownItemSlected}
                  />
                  <HelpIcon
                    id="info-presence-of-children"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>
              </div>
            </CustomPanel>
          );
        case 'Professional':
          return (
            <CustomPanel
              title={title}
              index={index}
              header="Professional"
              // suffix="Attributes"
              HeaderIcon={() => <Professional className="grey-icons" />}
              handleDrop={handleDrop}
            >
              <>
                <div className="with-info-icon">
                  <DataSelect
                    id="inferred_salary_range"
                    value={props.dataStore.networkFilters.inferred_salary_range}
                    isMulti
                    disabled={newProfileActive && !hasPremiumWealth}
                    dropDownList={salaryFilter}
                    placeholder="Inferred Income"
                    onItemSelected={onDropDownItemSlected}
                  />
                  <HelpIcon
                    id="info-inferred-salary"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>

                <div className="with-info-icon">
                  <DataSelect
                    id="wealth_estimate"
                    value={props.dataStore.networkFilters.wealth_estimate}
                    isMulti
                    isBeta={false}
                    dropDownList={wealthScoreRanges}
                    placeholder="Wealth Segment"
                    onItemSelected={onDropDownItemSlected}
                    disabled={
                      (newProfileActive && !hasPremiumWealth) ||
                      (props.dataStore.networkFilters.wealth_estimate_range &&
                        props.dataStore.networkFilters.wealth_estimate_range
                          .value &&
                        props.dataStore.networkFilters.wealth_estimate_range
                          .value !== '')
                        ? true
                        : false
                    }
                  />
                  <HelpIcon
                    id="info-wealth-estimate"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>
                {props.flags && props.flags.rangeForWealthSegment && (
                  <div style={{ marginTop: '10px' }}>
                    <div className="rangepicker-placeholder-active">
                      Wealth Segment Range
                    </div>
                    <NumberRangePicker
                      id="wealth_estimate_range"
                      placeholder="Wealth Segment Range"
                      number={
                        props.dataStore.networkFilters.wealth_estimate_range
                      }
                      onValueSelected={onRangeSelected}
                      onSelect={() => handleShowSavedFilter(true)}
                      disabled={
                        (newProfileActive && !hasPremiumWealth) ||
                        (props.dataStore.networkFilters.wealth_estimate &&
                          props.dataStore.networkFilters.wealth_estimate
                            .length > 0)
                          ? true
                          : false
                      }
                    />
                  </div>
                )}

                <div className="with-info-icon">
                  <DataSelect
                    id="remaining_share_value_sum"
                    value={
                      props.dataStore.networkFilters.remaining_share_value_sum
                    }
                    isMulti
                    disabled={newProfileActive && !hasPremiumWealth}
                    isBeta={false}
                    dropDownList={remainingShareValues}
                    placeholder="Remaining Share Balance"
                    onItemSelected={onDropDownItemSlected}
                  />
                  <HelpIcon
                    id="info-remaining-share"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>

                <>
                  <div style={{ marginTop: '10px' }}>
                    <CreatableSelect
                      id="org_name"
                      placeholder="Company"
                      isMulti
                      from="network"
                      onSelect={() => handleShowSavedFilter(true)}
                    />
                  </div>

                  <CreatableSelect
                    id="title"
                    placeholder="Title"
                    isMulti
                    from="network"
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </>
                <DataSelect
                  id="title_rank"
                  value={props.dataStore.networkFilters.title_rank}
                  dropDownList={props.dataStore.lookUpLists.titleRankList}
                  placeholder="Title Group"
                  isMulti
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="credentials"
                  value={props.dataStore.networkFilters.credentials}
                  dropDownList={credentialsLookup}
                  placeholder="Credentials"
                  isMulti
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="exc_title_rank"
                  value={props.dataStore.networkFilters.exc_title_rank}
                  dropDownList={props.dataStore.lookUpLists.titleRankList}
                  placeholder="Exclude Title Group"
                  isMulti
                  onItemSelected={onDropDownItemSlected}
                />

                <CreatableSelect
                  id="exc_title"
                  placeholder="Exclude Title"
                  isMulti
                  from="network"
                  onSelect={() => handleShowSavedFilter(true)}
                />
                <DataSelect
                  id="title_role"
                  value={props.dataStore.networkFilters.title_role}
                  dropDownList={props.dataStore.lookUpLists.titleRole}
                  onItemSelected={onDropDownItemSlected}
                  isMulti
                  placeholder="Function"
                />

                <div style={{ marginTop: '10px' }}>
                  <MultiDataSearch
                    id="previous_experience_org_name"
                    placeholder="Previous Companies"
                    isMulti
                    from="network"
                    // TODO: remove this onSelect when changing flag check
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <CreatableSelect
                    id="exc_current_experience"
                    placeholder="Exclude Companies"
                    isMulti
                    from="network"
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <div className="rangepicker-placeholder-active">
                    Years of Experience at Current Employer
                  </div>
                  <NumberRangePicker
                    id="current_org_years"
                    placeholder="Years of Experience"
                    number={props.dataStore.networkFilters.current_org_years}
                    onValueSelected={onRangeSelected}
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <div className="rangepicker-placeholder-active">
                    Years of Career Experience
                  </div>
                  <NumberRangePicker
                    id="total_years_of_experience"
                    placeholder="Years of Career Experience "
                    number={
                      props.dataStore.networkFilters.total_years_of_experience
                    }
                    onValueSelected={onRangeSelected}
                    onSelect={() => handleShowSavedFilter(true)}
                  />
                </div>

                <CreatableSelect
                  id="schools"
                  placeholder="Schools"
                  isMulti
                  from="network"
                  onSelect={() => handleShowSavedFilter(true)}
                />
              </>
            </CustomPanel>
          );
        case 'Relationships':
          return (
            <CustomPanel
              title={title}
              index={index}
              header="Relationships"
              HeaderIcon={() => (
                <LinkOutlined
                  style={{ fontSize: '19px' }}
                  className="grey-icons"
                />
              )}
              handleDrop={handleDrop}
            >
              <div>
                <div className="degree-connections-new">
                  <div>
                    <span
                      style={{
                        fontSize: '10px',
                        color: 'var(--color-font-grey)',
                      }}
                    >
                      INTRODUCTION OPPORTUNITIES{' '}
                    </span>
                    <HelpIcon
                      id="info-degrees"
                      className="info-icon"
                      height="12px"
                      width="12px"
                    />
                  </div>
                  <div
                    className={
                      (props.dataStore.lookUpLists.isSummaryPage &&
                        props.dataStore.lookUpLists.isRelationPathSelected) ||
                      (props.dataStore.networkFilters.relmap_org &&
                        props.dataStore.networkFilters.relmap_org.length > 0) ||
                      (props.flags.csvImportsAndDegrees &&
                        props.dataStore.networkFilters.filter_type === 'all' &&
                        ((props.dataStore.networkFilters.file_imports &&
                          props.dataStore.networkFilters.file_imports.length >
                            0) ||
                          (props.dataStore.networkFilters.team_file_imports &&
                            props.dataStore.networkFilters.team_file_imports
                              .length > 0)))
                        ? 'tags-container tags-container-disabled'
                        : 'tags-container'
                    }
                  >
                    <CheckableTag
                      className="tag1"
                      key={'first_degree'}
                      checked={
                        props.dataStore.networkFilters.filter_type ===
                          'first_degree' ||
                        props.dataStore.networkFilters.filter_type ===
                          'combined'
                      }
                      onChange={(checked) =>
                        props.handleDegreeConnectionsChange(
                          'first_degree',
                          checked,
                        )
                      }
                    >
                      <span>Your Network</span>
                      <span
                        style={{
                          fontSize: '10px',
                          color: 'var(--color-font-grey)',
                        }}
                      >
                        {' '}
                        1st
                      </span>
                    </CheckableTag>

                    <CheckableTag
                      className="tag2"
                      key={'second_degree'}
                      checked={
                        props.dataStore.networkFilters.filter_type ===
                          'second_degree' ||
                        props.dataStore.networkFilters.filter_type ===
                          'combined'
                      }
                      onChange={(checked) =>
                        props.handleDegreeConnectionsChange(
                          'second_degree',
                          checked,
                        )
                      }
                    >
                      <span>Get Introduced </span>
                      <span
                        style={{
                          fontSize: '10px',
                          color: 'var(--color-font-grey)',
                        }}
                      >
                        {' '}
                        2nd
                      </span>
                    </CheckableTag>

                    <CheckableTag
                      className="tag3"
                      key={'all'}
                      checked={
                        props.dataStore.networkFilters.filter_type === 'all'
                      }
                      onChange={(checked) =>
                        props.handleDegreeConnectionsChange('all', checked)
                      }
                    >
                      <span>Everyone</span>
                    </CheckableTag>
                  </div>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <DataSelect
                    id="number_of_mapped_connections"
                    styleChange
                    value={
                      props.dataStore.networkFilters
                        .number_of_mapped_connections
                    }
                    dropDownList={mappedConnectionsCount}
                    placeholder="# of Mapped Relationships"
                    isMulti
                    from="network"
                    onItemSelected={onDropDownItemSlected}
                  />
                </div>
                <label className="placeholder-active">
                  Person’s Relationships
                </label>
                <div
                  className="search-filter"
                  style={{
                    paddingTop: '0px',
                  }}
                >
                  <GoogleSearch
                    from="filter"
                    value={
                      props.dataStore.networkFilters.mapped_relationships_filter
                        .mapped_source_filter_name
                    }
                    onSearch={(val) =>
                      (props.dataStore.networkFilters.mapped_relationships_filter.mapped_source_filter_name =
                        val)
                    }
                    onSelect={onRelationshipProfileSelected}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <label className="placeholder-active">
                    Employee Relationship Mapping
                  </label>
                  <div
                    style={{
                      marginTop: '0px',
                    }}
                    className="with-info-icon"
                  >
                    <MultiDataSearch
                      id="relmap_org"
                      placeholder="Company Name"
                      isMulti
                      from="network"
                      onSelect={() => handleShowSavedFilter(true)}
                    />
                    <HelpIcon
                      id="info-relmap-org"
                      className="info-icon"
                      height="14px"
                      width="14px"
                    />
                  </div>
                </div>
              </div>
            </CustomPanel>
          );
        case 'Triggers':
          return (
            <CustomPanel
              index={index}
              handleDrop={handleDrop}
              title={title}
              header={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  Triggers
                  <HelpIcon
                    id="info-triggers"
                    className="info-icon"
                    height="14px"
                    width="14px"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.modalDataStore.eventHelpModalVisible = true;
                    }}
                  />
                </div>
              }
              HeaderIcon={() => <TriggerIcon className="grey-icons" />}
              // suffix="Attributes"
            >
              <>
                <div style={{ marginBottom: '10px' }}>
                  <DataSelect
                    id="latest_mim_event_type"
                    value={props.dataStore.networkFilters.latest_mim_event_type}
                    dropDownList={props.dataStore.lookUpLists.eventList}
                    formatOptionLabel={(data) => {
                      // this can add the 'premium key' icons to the fitler to indicate that the user
                      // does not have access to the fitler. if the user has PREM access or the
                      // new profiel view is not active, then the filter will display as normal.
                      return (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data.unsafeHtml || data.label,
                          }}
                        />
                      );
                    }}
                    placeholder="Triggers"
                    isMulti
                    onItemSelected={onDropDownItemSlected}
                  />
                </div>

                {/* funding round should only display once an event trigger is selected. */}
                {showTriggerFundingRound(
                  props.dataStore.networkFilters.latest_mim_event_type,
                ) && (
                  <div style={{ marginTop: '10px' }}>
                    <DataSelect
                      id="funding_round"
                      value={props.dataStore.networkFilters.funding_round}
                      dropDownList={fundingRoundList}
                      placeholder="Funding Round"
                      isMulti
                      onItemSelected={onDropDownItemSlected}
                    />
                  </div>
                )}

                {/* trigger value should only display once an event trigger is selected. */}
                {showTriggerValueSelection(
                  props.dataStore.networkFilters.latest_mim_event_type,
                ) && (
                  <div style={{ marginBottom: '10px' }}>
                    <DataSelect
                      id="latest_mim_monetary_value"
                      value={
                        props.dataStore.networkFilters.latest_mim_monetary_value
                      }
                      isMulti
                      isBeta={false}
                      dropDownList={wealthScoreRanges}
                      placeholder="Value"
                      onItemSelected={onDropDownItemSlected}
                    />
                  </div>
                )}

                {/* date period selection should only display once an event trigger is selected. */}
                {arrayHasValues(
                  props.dataStore.networkFilters.latest_mim_event_type,
                ) && (
                  <div style={{ marginTop: '10px' }}>
                    <DataSelect
                      id="period"
                      value={props.dataStore.networkFilters.period}
                      dropDownList={periodList}
                      placeholder="Period"
                      isMulti={false}
                      onItemSelected={onDropDownItemSlected}
                      disabled={
                        !props.dataStore.networkFilters.period &&
                        props.dataStore.networkFilters.m2_date &&
                        props.dataStore.networkFilters.m2_date.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                )}

                {/* date range selection should only display once an event trigger is selected. */}
                {arrayHasValues(
                  props.dataStore.networkFilters.latest_mim_event_type,
                ) && (
                  <div style={{ marginTop: '10px' }}>
                    <div className={'rangepicker-placeholder-active'}>
                      Time Frame
                    </div>
                    <DateRangePicker
                      id="m2_date"
                      value={props.dataStore.networkFilters.m2_date}
                      onCalendarChange={onDateRangeChange}
                      disabled={
                        props.dataStore.networkFilters.period &&
                        props.dataStore.networkFilters.period !== '' &&
                        props.dataStore.networkFilters.period.value !== ''
                          ? true
                          : false
                      }
                    />
                  </div>
                )}
              </>
            </CustomPanel>
          );
        case 'Company':
          return (
            <CustomPanel
              index={index}
              title={title}
              handleDrop={handleDrop}
              header="Company"
              HeaderIcon={() => (
                <CompanyIcon width={18} height={16} className="grey-icons" />
              )}
            >
              <>
                <DataSelect
                  id="industry"
                  styleChange
                  value={props.dataStore.networkFilters.industry}
                  dropDownList={props.dataStore.lookUpLists.industries}
                  placeholder="Industries"
                  isMulti
                  from="network"
                  isSearchable
                  // TODO: remove this onSelect when changing flag check
                  onItemSelected={onDropDownItemSlected}
                />
                <DataSelect
                  id="exc_industry"
                  styleChange
                  value={props.dataStore.networkFilters.exc_industry}
                  dropDownList={props.dataStore.lookUpLists.industries}
                  placeholder="Exclude Industries"
                  isMulti
                  from="network"
                  isSearchable
                  // TODO: remove this onSelect when changing flag check
                  onItemSelected={onDropDownItemSlected}
                />

                <div style={{ marginTop: '10px' }}>
                  <TreeDataSelect
                    id="naics_codes"
                    styleChange
                    dropDownList={props.dataStore.lookUpLists.naicsList}
                    value={props.dataStore.networkFilters.naics_codes}
                    placeholder="NAICS"
                    isMulti
                    from="network"
                    isSearchable
                    // TODO: remove this onSelect when changing flag check
                    onItemSelected={onDropDownItemSlected}
                  />
                </div>

                {(props.dataStore.lookUpLists.isPeoplePage ||
                  props.dataStore.lookUpLists.isSummaryPage) && (
                  <div className="with-info-icon">
                    <DataSelect
                      id="stock_performance"
                      value={props.dataStore.networkFilters.stock_performance}
                      isMulti
                      dropDownList={companyViablityRanges}
                      placeholder="Company Viability"
                      onItemSelected={onDropDownItemSlected}
                    />
                    <HelpIcon
                      id="info-stock_performance"
                      className="info-icon"
                      height="14px"
                      width="14px"
                    />
                  </div>
                )}

                <DataSelect
                  id="employee_count"
                  value={props.dataStore.networkFilters.employee_count}
                  isMulti
                  dropDownList={companySizeFilter}
                  placeholder="Company Headcount"
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="company_type"
                  value={props.dataStore.networkFilters.company_type}
                  dropDownList={companyType}
                  placeholder="Company Type"
                  isMulti={false}
                  onItemSelected={onDropDownItemSlected}
                  isClearable={false}
                  closeMenuOnSelect
                />
              </>
            </CustomPanel>
          );
        case 'US Income Tax Insights':
          if (
            props.dataStore.currentUser &&
            props.dataStore.currentUser.slug &&
            props.dataStore.currentUser.slug === 'factset'
          )
            return <></>;
          return (
            <CustomPanel
              index={index}
              title={title}
              handleDrop={handleDrop}
              header={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  US Income Tax Insights
                  <HelpIcon
                    id="info-irs-it-insights"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>
              }
              HeaderIcon={() => <IrsIcon className="irs-icon" />}
            >
              <>
                <div style={{ color: 'var(--color-dark)', fontWeight: 'bold' }}>
                  Step 1: Enter $ Range:
                </div>
                <div className="irs-range">
                  <IrsInsightsRangePicker
                    irsInsightsPicker
                    id="irs_tax_insights"
                    placeholder="Enter $ Range"
                    number={props.dataStore.networkFilters.irs_tax_insights}
                    onValueSelected={onIrsInput}
                  />
                </div>
                <div
                  style={{
                    color: 'var(--color-dark)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  Step 2: Select 1 Attribute to Filter:
                  <HelpIcon
                    className="info-icon"
                    height="14px"
                    width="14px"
                    onClick={showTaxDescriptionModal}
                  />
                </div>
                <div className="irs-checkbox">
                  <CheckboxGroup
                    options={
                      props.flags && props.flags.dntfd8933NewProfileView
                        ? IrsItemsNew
                        : IrsItems
                    }
                    value={
                      props.dataStore.networkFilters.irs_tax_insights
                        ? props.dataStore.networkFilters.irs_tax_insights.key
                        : []
                    }
                    onChange={onIrsFilterChange}
                    disabled={
                      !props.dataStore.networkFilters.irs_tax_insights.gte ||
                      !props.dataStore.networkFilters.irs_tax_insights.lte
                    }
                  />
                </div>
              </>
            </CustomPanel>
          );
        case 'Property':
          return (
            <CustomPanel
              index={index}
              title={title}
              handleDrop={handleDrop}
              header="Property"
              HeaderIcon={() => <PropertyIcon className="grey-icons" />}
            >
              <div className="property-attributes">
                <DataSelect
                  id="norm_home_owner"
                  value={props.dataStore.networkFilters.norm_home_owner}
                  disabled={newProfileActive && !hasPremiumProperty}
                  isMulti
                  dropDownList={homeOwnerStatusList}
                  placeholder="Home Owner Status"
                  onItemSelected={onDropDownItemSlected}
                />
                <DataSelect
                  id="number_of_owned_properties"
                  styleChange
                  value={
                    props.dataStore.networkFilters.number_of_owned_properties
                  }
                  dropDownList={Properties2To5}
                  placeholder="Multiple Properties"
                  isMulti
                  from="network"
                  // isSearchable
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="property_type"
                  value={props.dataStore.networkFilters.property_type}
                  isMulti
                  disabled={newProfileActive && !hasPremiumProperty}
                  dropDownList={propertyTypes}
                  placeholder="Property Type"
                  onItemSelected={onDropDownItemSlected}
                  from="network"
                />

                <DataSelect
                  id="home_value_estimate"
                  value={props.dataStore.networkFilters.home_value_estimate}
                  isMulti
                  disabled={newProfileActive && !hasPremiumProperty}
                  dropDownList={propertyValues}
                  placeholder="Property Value"
                  onItemSelected={onDropDownItemSlected}
                  from="network"
                />

                <div
                  className={'rangepicker-placeholder-active'}
                  style={{ marginTop: '7px' }}
                >
                  Home Purchase Date
                </div>
                <ExactDateRangePicker
                  placeholder="Home Purchase Date"
                  id="home_purchase_date"
                  disabled={newProfileActive && !hasPremiumProperty}
                  value={props.dataStore.networkFilters.home_purchase_date}
                  onCalendarChange={onDateRangeChange}
                />
                <>
                  <div
                    className={'rangepicker-placeholder-active'}
                    style={{ marginTop: '7px' }}
                  >
                    Home Sale Date
                  </div>
                  <ExactDateRangePicker
                    placeholder="Home Sale Date"
                    id="home_sale_date"
                    disabled={newProfileActive && !hasPremiumProperty}
                    value={props.dataStore.networkFilters.home_sale_date}
                    onCalendarChange={onDateRangeChange}
                  />{' '}
                </>

                <DataSelect
                  id="total_number_of_rooms"
                  styleChange
                  value={props.dataStore.networkFilters.total_number_of_rooms}
                  dropDownList={Numbers1To10}
                  placeholder="Total Number of Rooms"
                  isMulti
                  from="network"
                  // isSearchable
                  // TODO: remove this onSelect when changing flag check
                  onItemSelected={onDropDownItemSlected}
                />
                <DataSelect
                  id="lotsize_square_feet"
                  styleChange
                  value={props.dataStore.networkFilters.lotsize_square_feet}
                  dropDownList={lotSizeRanges}
                  placeholder="Lot Square Feet"
                  isMulti
                  from="network"
                  // isSearchable
                  onItemSelected={onDropDownItemSlected}
                />
                <div>
                  {/* <div
        style={{
          color: 'var(--color-grey-dark)',
          marginBottom: '7px',
          marginTop: '10px',
        }}
      > */}
                  <div
                    className={'rangepicker-placeholder-active'}
                    style={{
                      marginBottom: '',
                      marginTop: '10px',
                    }}
                  >
                    Effective Year Built
                  </div>
                  <RangePicker
                    id="effective_year_built"
                    className={'date-range-picker date-range-picker-new'}
                    // placeholder="Effective Year Built"
                    onChange={onYearChange}
                    picker="year"
                    value={
                      props.dataStore.networkFilters.effective_year_built &&
                      props.dataStore.networkFilters.effective_year_built
                        .length > 0
                        ? props.dataStore.networkFilters.effective_year_built
                        : null
                    }
                    disabledDate={(current) =>
                      current && current > dayjs().endOf('day')
                    }
                  />
                </div>

                <DataSelect
                  id="number_of_bedrooms"
                  styleChange
                  value={props.dataStore.networkFilters.number_of_bedrooms}
                  dropDownList={Numbers1To10}
                  placeholder="Number of Bedrooms"
                  isMulti
                  from="network"
                  // isSearchable
                  // TODO: remove this onSelect when changing flag check
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="number_of_baths"
                  styleChange
                  value={props.dataStore.networkFilters.number_of_baths}
                  dropDownList={Numbers1To10}
                  placeholder="Number of Baths"
                  isMulti
                  from="network"
                  // isSearchable
                  // TODO: remove this onSelect when changing flag check
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="number_of_partial_baths"
                  styleChange
                  value={props.dataStore.networkFilters.number_of_partial_baths}
                  dropDownList={Numbers1To10}
                  placeholder="Number of Partial Baths"
                  isMulti
                  from="network"
                  // isSearchable
                  // TODO: remove this onSelect when changing flag check
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="number_of_stories"
                  styleChange
                  value={props.dataStore.networkFilters.number_of_stories}
                  dropDownList={storiesList}
                  placeholder="Number of Stories"
                  isMulti
                  from="network"
                  // isSearchable
                  // TODO: remove this onSelect when changing flag check
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="number_of_units"
                  styleChange
                  value={props.dataStore.networkFilters.number_of_units}
                  dropDownList={Numbers1To10}
                  placeholder="Number of Units"
                  isMulti
                  from="network"
                  // isSearchable
                  onItemSelected={onDropDownItemSlected}
                />

                <DataSelect
                  id="garage_cars"
                  styleChange
                  value={props.dataStore.networkFilters.garage_cars}
                  dropDownList={garageLookup}
                  placeholder="Garage"
                  isMulti
                  from="network"
                  // isSearchable
                  onItemSelected={onDropDownItemSlected}
                />

                {/* <Checkbox
                            id="is_basement_included"
                            style={{ marginTop: '10px', display: 'flex' }}
                            checked={
                                props.dataStore.networkFilters.is_basement_included
                            }
                            onChange={onPropertyAttributesChecked}
                        >
                            Basement
                        </Checkbox> */}

                <Checkbox
                  id="is_pool_included"
                  style={{ marginTop: '10px', display: 'flex' }}
                  checked={props.dataStore.networkFilters.is_pool_included}
                  onChange={onPropertyAttributesChecked}
                >
                  Pool
                </Checkbox>

                <Checkbox
                  id="is_elevator_included"
                  style={{ marginTop: '10px', display: 'flex' }}
                  checked={props.dataStore.networkFilters.is_elevator_included}
                  onChange={onPropertyAttributesChecked}
                >
                  Elevator
                </Checkbox>
              </div>
            </CustomPanel>
          );
        case 'Tags':
          if (props.flags && props.flags.tagFolders) return <></>;

          return (
            <CustomPanel
              index={index}
              title={title}
              handleDrop={handleDrop}
              className="tags panel-header"
              header={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  Tags
                  <Tooltip
                    placement="bottom"
                    color="var(--color-white)"
                    title={
                      <span style={{ color: 'var(--color-black)' }}>
                        Tags can be utilized for segmentation purposes, allowing
                        for effective organization and management of your
                        prospects and clients. Use Tags in accordance with your
                        corporate guidelines.
                      </span>
                    }
                  >
                    <HelpIcon
                      id="info-tags"
                      className="info-icon"
                      height="14px"
                      width="14px"
                    />
                  </Tooltip>
                </div>
              }
              HeaderIcon={() => <TagsFilled className="grey-icons" />}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <div
                  style={{
                    marginRight: '8px',
                    marginBottom: '10px',
                  }}
                >
                  <Tags />
                </div>
              </div>
              <CheckboxGroup
                style={{ width: '100%', paddingTop: 10 }}
                // options={props.dataStore.userTags}
                value={props.dataStore.networkFilters.tags_checked}
                onChange={(checkedValues) =>
                  onTagsChange('tags_checked', checkedValues)
                }
                // className={
                //   attributesAllCheckedByAdmin ? 'checkbox-color-change' : ''
                // }
              >
                {props.dataStore.userTags.map(({ id, tag }) => {
                  return (
                    <div
                      className={`tags-row ${
                        tag === 'Suppress' && 'suppress-row'
                      }`}
                      style={
                        (tag === 'Suppress' &&
                          props.dataStore.currentUser.hideSuppressTag) ||
                        (tag?.toLowerCase() === 'moneta contact' &&
                          props.dataStore.currentUser.hideMonetaContactTag)
                          ? {
                              display: 'none',
                            }
                          : {}
                      }
                    >
                      <Checkbox value={id}>{tag}</Checkbox>
                      {tag === 'Suppress' && (
                        <Tooltip
                          placement="bottom"
                          color="var(--color-white)"
                          title={
                            <span style={{ color: 'var(--color-black)' }}>
                              Suppress Tag removes profile from search results,
                              filters, daily opportunities and email exports.
                            </span>
                          }
                        >
                          <HelpIcon
                            id="suppress"
                            height="14px"
                            width="14px"
                            className="info-icon"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Tooltip>
                      )}
                      {tag !== 'Suppress' && (
                        <Tags id={id} tagLabel={tag} isUpdate />
                      )}
                    </div>
                  );
                })}
              </CheckboxGroup>
              {props.dataStore.sharedTags &&
                props.dataStore.sharedTags.length > 0 && (
                  <div style={{ marginTop: '5px' }}>
                    <label className="label-main">Shared Tags</label>
                    <CheckboxGroup
                      style={{ width: '100%', paddingTop: 10 }}
                      value={props.dataStore.networkFilters.shared_tags}
                      onChange={(checkedValues) =>
                        onTagsChange('shared_tags', checkedValues)
                      }
                    >
                      {props.dataStore.sharedTags.map((tag) => {
                        return (
                          <div
                            style={
                              (tag.tag_name === 'Suppress' &&
                                props.dataStore.currentUser.hideSuppressTag) ||
                              (tag.tag_name?.toLowerCase() ===
                                'moneta contact' &&
                                props.dataStore.currentUser
                                  .hideMonetaContactTag)
                                ? {
                                    display: 'none',
                                  }
                                : {}
                            }
                          >
                            <Checkbox value={tag.tag_id}>
                              {tag.tag_name}
                              {tag.tag_owner_name && (
                                <div>
                                  <span
                                    style={{
                                      color: 'var(--color-grey-dark)',
                                      fontSize: '11px',
                                    }}
                                  >
                                    by
                                  </span>{' '}
                                  <span
                                    className="link"
                                    onClick={(e) =>
                                      showPersonProfile(
                                        e,
                                        tag.tag_owner_b2b_id,
                                        tag.tag_owner_b2c_id,
                                      )
                                    }
                                  >
                                    {tag.tag_owner_name}
                                  </span>
                                </div>
                              )}
                            </Checkbox>
                          </div>
                        );
                      })}
                    </CheckboxGroup>
                  </div>
                )}
            </CustomPanel>
          );
        case 'Attributes':
          return (
            <CustomPanel
              index={index}
              title={title}
              handleDrop={handleDrop}
              className="attributes panel-header"
              header="Attributes"
              HeaderIcon={() => <AttributeIcon className="grey-icons" />}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <div
                  style={{
                    marginRight: '8px',
                    marginBottom: '10px',
                  }}
                >
                  <Switch
                    size="small"
                    checkedChildren="ALL"
                    unCheckedChildren="ANY"
                    checked={props.dataStore.networkFilters.attribute_function}
                    onChange={onAttributesSwitch}
                  />
                </div>
                <label>All</label>
                <HelpIcon
                  id="info-attributes"
                  className="info-icon"
                  height="14px"
                  width="14px"
                />
              </div>
              <Checkbox.Group
                style={{ width: '100%', paddingTop: 10 }}
                options={findAttributes()}
                value={
                  props.dataStore.networkFilters.attributes_checked &&
                  props.dataStore.networkFilters.attributes_checked.length > 0
                    ? [...props.dataStore.networkFilters.attributes_checked]
                    : []
                }
                onChange={(checkedValues) => onAttributesChecked(checkedValues)}
                className={
                  attributesAllCheckedByAdmin ? 'checkbox-color-change' : ''
                }
              />
            </CustomPanel>
          );
        case 'Wealth Model':
          return (
            <CustomPanel
              index={index}
              title={title}
              handleDrop={handleDrop}
              className="wealth-model panel-header"
              header={
                <div
                  style={{
                    wordBreak: 'break-word',
                    maxWidth: '170px',
                    lineHeight: '18px',
                    padding: '1px',
                  }}
                >
                  Wealth & Income Components
                  <HelpIcon
                    id="info-wealth-model"
                    className="info-icon"
                    height="14px"
                    width="14px"
                    style={{ marginLeft: '10px' }}
                    onClick={showWealthModalVisible}
                  />
                </div>
              }
              suffix={
                (!newProfileActive ||
                  (newProfileActive && hasPremiumTriggers)) && (
                  <div
                    style={{
                      right: '51px',
                      position: 'absolute',
                      top: 'calc(50% - 11px)',
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Checkbox
                      className="team-all-check"
                      checked={
                        props.dataStore.networkFilters.wealth_model_checked &&
                        props.dataStore.networkFilters.wealth_model_checked
                          .length === wealthModelValues.length
                      }
                      onChange={(e) => {
                        if (e.target.checked)
                          onWealthModalChecked(wealthModelValues);
                        else onWealthModalChecked([]);
                      }}
                    />
                  </div>
                )
              }
              HeaderIcon={() => <DollarOutlined className="grey-icons" />}
            >
              <Checkbox.Group
                style={{ width: '100%', paddingTop: 10 }}
                options={wealthModelItems.map((item) => ({
                  ...item,
                  disabled: newProfileActive && !hasPremiumTriggers,
                }))}
                value={
                  props.dataStore.networkFilters.wealth_model_checked &&
                  props.dataStore.networkFilters.wealth_model_checked.length > 0
                    ? [...props.dataStore.networkFilters.wealth_model_checked]
                    : []
                }
                onChange={(checkedValues) =>
                  onWealthModalChecked(checkedValues)
                }
              />
            </CustomPanel>
          );

        case 'Import Source':
          if (
            !props.dataStore.networkFilters.relmap_org ||
            props.dataStore.networkFilters.relmap_org.length <= 0
          )
            return (
              <CustomPanel
                index={index}
                title={title}
                handleDrop={handleDrop}
                header="Import Source"
                className="import-source panel-header"
                HeaderIcon={() => <ConnectIcon className="grey-icons" />}
              >
                <>
                  <div>
                    <div className="flex-header">
                      <label className="label-main">Relationships</label>
                      <Checkbox
                        id="import_source_all"
                        checked={
                          props.dataStore.networkFilters.import_source &&
                          props.dataStore.networkFilters.import_source
                            .length ===
                            props.dataStore.lookUpLists.import_source.length
                        }
                        onChange={onImportFileAllChecked}
                      />
                    </div>
                    <Checkbox.Group
                      style={{ width: '100%', paddingTop: '10px' }}
                      options={importSourceList}
                      defaultValue={props.dataStore.lookUpLists.import_source}
                      value={props.dataStore.networkFilters.import_source}
                      onChange={(checkedValues) =>
                        onSourceSelected(checkedValues, 'import_source')
                      }
                      className={
                        importSourceAllChecked ? 'checkbox-color-change' : ''
                      }
                    />
                  </div>
                  {props.dataStore.lookUpLists.fileImports &&
                    props.dataStore.lookUpLists.fileImports.length > 0 && (
                      <div style={{ paddingTop: 20 }}>
                        <div className="flex-header">
                          <label className="label-main">CSV Files</label>
                          <Checkbox
                            id="file_imports_all"
                            checked={
                              props.dataStore.networkFilters.filter_type ===
                              'all'
                                ? props.dataStore.networkFilters.file_imports &&
                                  props.dataStore.networkFilters.file_imports
                                    .length ===
                                    props.dataStore.lookUpLists.fileImports
                                      .length
                                : props.dataStore.networkFilters.file_imports &&
                                  props.dataStore.networkFilters.file_imports
                                    .length ===
                                    props.dataStore.lookUpLists
                                      .fileImportsWithoutEnrich.length
                            }
                            onChange={onImportFileAllChecked}
                            disabled={
                              props.flags &&
                              props.flags.csvImportsAndDegrees &&
                              props.dataStore.networkFilters.filter_type !==
                                'all'
                            }
                            className={
                              props.flags &&
                              props.flags.csvImportsAndDegrees &&
                              props.dataStore.networkFilters.filter_type !==
                                'all'
                                ? 'transparent-checkbox'
                                : ''
                            }
                          />
                        </div>

                        <Checkbox.Group
                          style={{ width: '100%', paddingTop: 10 }}
                          options={
                            props.dataStore.networkFilters.filter_type ===
                              'all' ||
                            (props.flags && props.flags.csvImportsAndDegrees)
                              ? props.dataStore.lookUpLists.fileImports
                              : props.dataStore.lookUpLists
                                  .fileImportsWithoutEnrich
                          }
                          value={props.dataStore.networkFilters.file_imports}
                          onChange={(checkedValues) =>
                            onSourceSelected(checkedValues, 'file_imports')
                          }
                          disabled={
                            props.flags &&
                            props.flags.csvImportsAndDegrees &&
                            props.dataStore.networkFilters.filter_type !== 'all'
                          }
                          className={
                            props.flags &&
                            props.flags.csvImportsAndDegrees &&
                            props.dataStore.networkFilters.filter_type !== 'all'
                              ? 'transparent-checkbox'
                              : fileImportsAllChecked
                                ? 'checkbox-color-change'
                                : ''
                          }
                        />
                      </div>
                    )}
                  {!props.dataStore.currentUser.hideSharedCsvFiles &&
                    props.dataStore.lookUpLists.teamFileImports &&
                    props.dataStore.lookUpLists.teamFileImports.length > 0 && (
                      <div style={{ paddingTop: 20 }}>
                        <div className="flex-header">
                          <label className="label-main">Shared CSV Files</label>
                          <Checkbox
                            id="team_file_imports_all"
                            disabled={
                              props.flags &&
                              props.flags.csvImportsAndDegrees &&
                              props.dataStore.networkFilters.filter_type !==
                                'all'
                            }
                            className={
                              props.flags &&
                              props.flags.csvImportsAndDegrees &&
                              props.dataStore.networkFilters.filter_type !==
                                'all'
                                ? 'transparent-checkbox'
                                : ''
                            }
                            checked={
                              props.dataStore.networkFilters
                                .team_file_imports &&
                              props.dataStore.networkFilters.team_file_imports
                                .length ===
                                getTeamFileLookup(
                                  props.dataStore.networkFilters.filter_type,
                                  props.dataStore.lookUpLists.addImportsToTeam,
                                  props.dataStore.lookUpLists
                                    .teamFileImportsWithoutEnrich,
                                  props.dataStore.lookUpLists.teamFileImports,
                                ).length
                            }
                            onChange={onImportFileAllChecked}
                          />
                        </div>
                        <div className="team-file">
                          <Checkbox.Group
                            style={{ width: '100%', paddingTop: 10 }}
                            value={
                              props.dataStore.networkFilters.team_file_imports
                            }
                            onChange={(checkedValues) =>
                              onSourceSelected(
                                checkedValues,
                                'team_file_imports',
                              )
                            }
                            disabled={
                              props.flags &&
                              props.flags.csvImportsAndDegrees &&
                              props.dataStore.networkFilters.filter_type !==
                                'all'
                            }
                            className={
                              props.flags &&
                              props.flags.csvImportsAndDegrees &&
                              props.dataStore.networkFilters.filter_type !==
                                'all'
                                ? 'transparent-checkbox'
                                : sharedFileImportsAllChecked
                                  ? 'checkbox-color-change'
                                  : ''
                            }
                          >
                            {renderSharedCSVOptions()}
                          </Checkbox.Group>
                        </div>
                      </div>
                    )}
                </>
              </CustomPanel>
            );
          break;

        case 'Team Members':
          if (
            !props.dataStore.networkFilters.relmap_org ||
            props.dataStore.networkFilters.relmap_org.length <= 0
          )
            return (
              <CustomPanel
                index={index}
                title={title}
                handleDrop={handleDrop}
                header={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    Team Members
                    <HelpIcon
                      id="info-team-members"
                      className="info-icon"
                      height="14px"
                      width="14px"
                    />
                  </div>
                }
                suffix={
                  props.dataStore.lookUpLists.team &&
                  props.dataStore.teamFilters.uiTeamIds &&
                  props.dataStore.teamFilters.uiTeamIds.length > 0 ? (
                    <div
                      style={{ right: '51px', position: 'absolute' }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Checkbox
                        className="team-all-check"
                        checked={props.dataStore.teamFilters.isAllChecked}
                        onChange={(e) => updateTeamMembers(e, true)}
                      />
                    </div>
                  ) : null
                }
                HeaderIcon={() => <TeamIcon className="grey-icons" />}
              >
                <div style={{ height: 'max-content' }}>
                  <div>
                    {props.dataStore.lookUpLists.team &&
                    props.dataStore.teamFilters.uiTeamIds &&
                    props.dataStore.teamFilters.uiTeamIds.length > 0 ? (
                      <TeamMultiCheckbox
                        id="members"
                        defaultTeamLengthArray={teamLengthArray}
                        checkboxList={teamMembers}
                        defaultList={props.dataStore.teamFilters.uiTeamIds}
                        onChecked={onCheckBoxChanged}
                      />
                    ) : (
                      <div
                        style={{
                          textAlign: 'center',
                          marginBottom: 20,
                          color: 'var(--color-column)',
                        }}
                      >
                        <TeamIcon fill="var(--color-column)" />
                        <br />
                        You&apos;re not currently part of any team.
                      </div>
                    )}
                  </div>
                </div>{' '}
              </CustomPanel>
            );
          return;
        default:
          break;
      }
    };

    return (
      <>
        <div
          className="advanced-filter-container"
          id="advanced-filter-parent"
          style={{ width: props.width, transition: 'width 500ms' }}
        >
          <Collapse
            expandIconPosition="end"
            // defaultActiveKey={['1', '2']}
            expandIcon={({ isActive }) =>
              isActive ? (
                <MinusIcon
                  style={{
                    fill: 'var(--color-primary)',
                    width: '16px',
                    height: '40px',
                  }}
                />
              ) : (
                <PlusIcon
                  style={{
                    color: 'var(--color-header-text)',
                    width: '16px',
                    height: '28px',
                  }}
                />
              )
            }
            className="filter-scroll-container"
            style={{
              height: 'calc(100vh - 153px)',
              marginTop: '20px',
              marginBottom: '30px',
            }}
            // Make the collapse borderless and its background transparent
            ghost
          >
            {showHelpSection && (
              <div className="help-section-wrapper">
                <div className="help-section">
                  <div className="help-image" />
                  <div className="help-section-right">
                    Prospect Finder
                    <span className="close-icon">
                      <CloseOutlined
                        onClick={() => {
                          setShowHelpSection(false);
                          localStorage.setItem(
                            'aidentified.pfFinderHelp',
                            'false',
                          );
                        }}
                      />
                    </span>
                    <div className="play-icon" id="prospect-finder-help">
                      <PlayCircleFilled />
                      <span className="help-text">Finder Help</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="first-filter-section">
              <div className="people-search-field-parent">
                <DebounceInput
                  debounceTimeout={500}
                  autoComplete="off"
                  id="full_name"
                  className="people-search-field"
                  placeholder="Name"
                  type="text"
                  value={props.dataStore.networkFilters.full_name}
                  onChange={(e) =>
                    onSearchitemSlected('full_name', e.target.value)
                  }
                  onFocus={() => {
                    setisPeopleSearchFocused(true);
                  }}
                  onBlur={() => {
                    setisPeopleSearchFocused(false);
                  }}
                />

                {props.dataStore.networkFilters.full_name &&
                  props.dataStore.networkFilters.full_name !== '' &&
                  !isPeopleSearchFocused && (
                    <div
                      id="full_name_network_close"
                      onClick={() => clearText('full_name')}
                    >
                      x
                    </div>
                  )}
                {isPeopleSearchFocused && (
                  <SearchOutlined
                    id="full_name_network_search"
                    onClick={() => clearText('full_name')}
                  />
                )}
              </div>
              <MultiDataSearch
                id="location_city"
                placeholder="Home City"
                isMulti
                from="network"
                // TODO: remove this onSelect when changing flag check
                onSelect={() => {
                  delete props.dataStore.table_search['location_city'];
                  handleShowSavedFilter(true);
                }}
              />
              <DataSelect
                id="location_state"
                value={props.dataStore.networkFilters.location_state}
                dropDownList={filterStates}
                placeholder="State"
                isMulti
                onItemSelected={(selectedItems, id) => {
                  delete props.dataStore.table_search['location_state'];
                  onDropDownItemSlected(selectedItems, id);
                }}
              />
              <div style={{ marginTop: '10px' }}>
                <CreatableSelect
                  id="org_name"
                  placeholder="Company"
                  isMulti
                  from="network"
                  onSelect={() => {
                    delete props.dataStore.table_search['org_name'];
                    handleShowSavedFilter(true);
                  }}
                />
              </div>
              <CreatableSelect
                id="title"
                placeholder="Title"
                isMulti
                from="network"
                onSelect={() => {
                  delete props.dataStore.table_search['title'];
                  handleShowSavedFilter(true);
                }}
              />
              <CreatableSelect
                id="schools"
                placeholder="Schools"
                isMulti
                from="network"
                onSelect={() => handleShowSavedFilter(true)}
              />
              <>
                <div className="with-info-icon">
                  <DataSelect
                    id="inferred_salary_range"
                    value={props.dataStore.networkFilters.inferred_salary_range}
                    isMulti
                    disabled={newProfileActive && !hasPremiumWealth}
                    dropDownList={salaryFilter}
                    placeholder="Inferred Income"
                    onItemSelected={(selectedItems, id) => {
                      delete props.dataStore.table_search[
                        'inferred_salary_range'
                      ];
                      onDropDownItemSlected(selectedItems, id);
                    }}
                  />
                  <HelpIcon
                    id="info-inferred-salary"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>

                <div className="with-info-icon">
                  <DataSelect
                    id="wealth_estimate"
                    value={props.dataStore.networkFilters.wealth_estimate}
                    isMulti
                    isBeta={false}
                    dropDownList={wealthScoreRanges}
                    placeholder="Wealth Segment"
                    onItemSelected={(selectedItems, id) => {
                      delete props.dataStore.table_search['wealth_estimate'];
                      onDropDownItemSlected(selectedItems, id);
                    }}
                    disabled={
                      (newProfileActive && !hasPremiumWealth) ||
                      (props.dataStore.networkFilters.wealth_estimate_range &&
                        props.dataStore.networkFilters.wealth_estimate_range
                          .value &&
                        props.dataStore.networkFilters.wealth_estimate_range
                          .value !== '')
                        ? true
                        : false
                    }
                  />
                  <HelpIcon
                    id="info-wealth-estimate"
                    className="info-icon"
                    height="14px"
                    width="14px"
                  />
                </div>
              </>
            </div>

            {props.dataStore.currentUser.userTabOrder.map((item, index) => {
              return <>{findPanel(item, index)}</>;
            })}
          </Collapse>
        </div>
        <div style={{ width: props.width }} />
      </>
    );
  }),
);

export default withLDConsumer()(NewAdvancedFilter);

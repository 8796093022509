import { action, makeObservable, observable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

class ModalDataStore {
  constructor() {
    makeObservable(this, {
      profileModal: observable,
      companyProfileModal: observable,
      eventHelpModalVisible: observable,
      taxDescriptionModalVisible: observable,
      wealthModalVisible: observable,
      paywallSpeedBump: observable,
      trackModal: observable,
      filterModal: observable,
      //   importPercentage: computed,
      resetAll: action,
      resetProfileModal: action,
      resetCompanyProfileModal: action,
      resetFilterModal: action,
    });
  }

  profileModal = {
    isVisible: false,
    fromQuery: false,
    b2bId: null,
    b2cId: null,
    mimpersonId: null,
    eventsId: null,
    fetchOnChange: false,
    onDelete: false,
    // to handle admin export from profile mapped connection
    mappedRelationshipsFilter: {
      filterName: '',
      mappedSourceB2bId: '',
      mappedSourceB2cId: '',
      mappedSourceInferredSalary: [],
      mappedSourceWealthEstimate: [],
      mappedSourceLocationState: '',
      mappedSourceLocationCity: '',
      mappedSourceOrgname: '',
      mappedSourceTitle: '',
      mappedSourceSchool: [],
      mappedRelationshipWeights: [],
      mappedRelationshipTypes: [],
      pagination: {},
    },
  };

  companyProfileModal = {
    isVisible: false,
    fromQuery: false,
    id: 0,
    orgName: '',
  };

  filterModal = {
    isVisible: false,
    from: false,
  };

  paywallSpeedBump = {
    visible: false,
    content: 'none',
  };
  eventHelpModalVisible = false;
  taxDescriptionModalVisible = false;
  wealthModalVisible = false;

  trackModal = [];

  resetFilterModal() {
    this.filterModal = {
      isVisible: false,
      from: false,
    };
  }

  resetProfileModal(updateTrackModal = false) {
    if (updateTrackModal && this.trackModal && this.trackModal.length > 0) {
      const currentModal = this.trackModal.pop();
      if (currentModal && currentModal.modal === 'profile') {
        currentModal.data = cloneDeep(this.profileModal);
      }
      this.trackModal.push({ ...currentModal });
    }

    this.profileModal = {
      isVisible: false,
      // isMutualClick: false,
      b2bId: null,
      b2cId: null,
      mimpersonId: null,
      eventsId: null,
      fetchOnChange: false,
      fetchProfileData: false,
      onDelete: false,
      mappedRelationshipsFilter: {
        filterName: '',
        mappedSourceB2bId: '',
        mappedSourceB2cId: '',
        mappedSourceInferredSalary: [],
        mappedSourceWealthEstimate: [],
        mappedSourceLocationState: '',
        mappedSourceLocationCity: '',
        mappedSourceOrgname: '',
        mappedSourceTitle: '',
        mappedSourceSchool: [],
        mappedRelationshipWeights: [],
        mappedRelationshipTypes: [],
        pagination: {},
      },
    };
  }

  resetCompanyProfileModal() {
    this.companyProfileModal = {
      isVisible: false,
      id: 0,
    };
  }

  resetPaywalSpeedbumpModal() {
    this.paywallSpeedBump = {
      visible: false,
      content: 'none',
    };
  }

  resetAll() {
    this.resetCompanyProfileModal();
    this.resetFilterModal();
    this.resetProfileModal();
    this.resetPaywalSpeedbumpModal();
    this.trackModal = [];
  }
}
export default ModalDataStore;

import './index.css';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';
import { usePostHog } from 'posthog-js/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
// import { getMessaging, getToken } from 'firebase/messaging';

import ConnectIcon from '../../assets/icons/ai_connect.svg?react';
import FilterIcon from '../../assets/icons/ai_filters.svg?react';
import InsightsIcon from '../../assets/icons/ai_ri.svg?react';
import ProfileIcon from '../../assets/icons/ai_profile.svg?react';
import ProspectFinderIcon from '../../assets/icons/ai_prospect_finder.svg?react';
import AlertIcon from '../../assets/icons/ai_alert_icon.svg?react';

// import { firebaseApp } from '../../utils/fireBase';
import { formatEventName, getDomain } from '../../utils/general';
import { showConfirm } from '../../utils/popup';
import {
  titleRankList,
  industryTypes,
  FunctionFlags,
} from '../../utils/constants';
import { setAuthority } from '../../utils/authority';
import { getCrmNames } from '../../utils/crmMethods';
import { updateUserInfo, requestWithAuth } from '../../services/api';
import GoogleSearch from '../GoogleSearch';
import NavigationBar from '../NavigationBar';
import {
  hasEntitlement,
  isPremiumWealthTrigger,
} from '../../utils/entitlements';
import { PremiumLabel } from '../AiFunctionalComponents';

const { VITE_FIREBASE_KEY } = import.meta.env;
let hidden;
let visibilityChange;

document.addEventListener('DOMContentLoaded', function (event) {
  /**keys for different browsers/versions **/
  if (document.hidden !== undefined) {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (document.mozHidden !== undefined) {
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
  } else if (document.msHidden !== undefined) {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (document.webkitHidden !== undefined) {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  } else if (document.oHidden !== undefined) {
    hidden = 'oHidden';
    visibilityChange = 'ovisibilitychange';
  }
});

function initialisePendo(user) {
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function in your authentication promise handler or callback when your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.
  window.pendo.initialize({
    visitor: {
      id: user.ownerId, // Required if user is logged in, default creates anonymous ID
      email: user.email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: user.full_name, // Recommended if using Pendo Feedback
      user_type: user.type,
      white_label: user.slug,
      industry: user.industry,
      account_rep: user.account_rep?.full_name,
      title: user.title,
      referrer_code: user.referrer_code,
      crm: getCrmNames(user.crm),
      credits: user.creditCount,
      company: user.company || '',
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: user.company || '',
      //   id: 'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      //   // name:         // Optional
      //   // is_paying:    // Recommended if using Pendo Feedback
      //   // monthly_value:// Recommended if using Pendo Feedback
      //   // planLevel:    // Optional
      //   // planPrice:    // Optional
      //   // creationDate: // Optional

      //   // You can add any additional account level key-values here,
      //   // as long as it's not one of the above reserved names.
    },
  });
}

const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const labelA = a.first_name.toUpperCase();
  const labelB = b.first_name.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
};

const compareLabels = (a, b) => {
  const labelA = a.label.toUpperCase(); // ignore upper and lowercase
  const labelB = b.label.toUpperCase(); // ignore upper and lowercase
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  // labels must be equal
  return 0;
};

const Header = inject('dataStore')(
  inject('modalDataStore')(
    inject('globalDataStore')(
      observer(({ columns, data, ...props }) => {
        let slug = 'app';
        let source;

        const { logout } = useAuth0();
        const posthog = usePostHog();
        const flags = useFlags();

        const hostArray = window.location.host.split('.');
        if (hostArray && hostArray.length > 0 && hostArray[0]) {
          slug = hostArray[0];
        }
        const isBlueridge = slug === 'blueridge';

        const { currentUser } = props.dataStore;
        const hasPremiumTriggers = hasEntitlement(
          currentUser,
          FunctionFlags.premium_wealth_triggers,
        );

        const { pathname } = useLocation();
        const navigate = useNavigate();
        useEffect(() => {
          window.scroll(window.scrollX, 0);
        }, [pathname]);

        const [displayMenu, setDisplayMenu] = useState(false);
        const hideDropdownMenu = () => {
          setDisplayMenu(false);
        };

        // side effect when the user profile drop down is clicked
        useEffect(() => {
          if (displayMenu) document.addEventListener('click', hideDropdownMenu);
          return () => document.removeEventListener('click', hideDropdownMenu);
        }, [displayMenu]);

        const getUnseenNotificationCount = () => {
          requestWithAuth('notification-count', 'GET', null, null, null)
            .then((response) => {
              if (response && response.notification_count)
                props.globalDataStore.notificationCount =
                  response.notification_count;
            })
            .catch((error) => {
              console.log(error);
            });
        };

        function handleVisibilityChange() {
          if (!document[hidden]) {
            if (
              props.dataStore.currentUser.type !== 'lite' &&
              props.dataStore.currentUser.type !== 'inactive'
            )
              getUnseenNotificationCount();
          }
        }

        const fetchUserTags = () => {
          requestWithAuth('tags', 'GET', null, null, null).then((response) => {
            if (response && response.length) {
              props.dataStore.userTags = response;
            }
          });
        };

        const fetchTagFolders = () => {
          requestWithAuth('tag-folder', 'GET', null, null, null).then(
            (response) => {
              if (response) {
                props.dataStore.lookUpLists.userTagFolders = response;
              }
            },
          );
        };

        const fetchSharedTags = () => {
          requestWithAuth(
            'field-lookup?key=shared_tags',
            'GET',
            null,
            null,
            null,
          ).then((response) => {
            if (response && response.result && response.result.length) {
              props.dataStore.sharedTags = response.result;
            }
          });
        };

        const fetchFileImports = () => {
          requestWithAuth(
            'field-lookup?key=file_imports',
            'GET',
            null,
            null,
            null,
          )
            .then((response) => {
              if (response && response.result && response.result.length) {
                const filesWithoutEnrich = [];
                props.dataStore.lookUpLists.fileImports = response.result.map(
                  (file) => {
                    if (file && !file.data_enrichment_only) {
                      filesWithoutEnrich.push({
                        label: file.import_name,
                        value: file.id,
                        isEnrich: file.data_enrichment_only,
                      });
                    }
                    return {
                      label: file.import_name,
                      value: file.id,
                      isEnrich: file.data_enrichment_only,
                    };
                  },
                );
                props.dataStore.lookUpLists.fileImportsWithoutEnrich =
                  filesWithoutEnrich;
              }

              props.dataStore.lookUpLists.fileImports.sort(compareLabels);
              props.dataStore.lookUpLists.fileImportsWithoutEnrich.sort(
                compareLabels,
              );

              if (props.dataStore.networkFilters.filter_type !== 'all') {
                if (!props.dataStore.importSourceKeys.isImportSourceSaved) {
                  props.dataStore.networkFilters.file_imports =
                    props.dataStore.lookUpLists.fileImportsWithoutEnrich.map(
                      (item) => {
                        if (item.value) return item.value;
                      },
                    );
                } else {
                  const files = [];
                  props.dataStore.lookUpLists.fileImportsWithoutEnrich.map(
                    (file) => {
                      if (
                        file &&
                        file.value &&
                        props.dataStore.networkFilters.file_imports.includes(
                          file.value,
                        )
                      )
                        files.push(file.value);
                    },
                  );
                  props.dataStore.networkFilters.file_imports = files;
                }
                props.dataStore.networkFilters.fetchData = true;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        };

        const fetchTitleRole = () => {
          requestWithAuth('people-grouping-new', 'POST', {
            page_size: 100,
            from: 0,
            filter_type: 'all',
            key: 'title_role',
            sort_order: 'asc',
            sort_by: '_key',
          }).then((response) => {
            if (response && response.results && response.results.length > 0) {
              const titleRoles = response.results.filter(
                (item) => item && item.key,
              );
              props.dataStore.lookUpLists.titleRole = titleRoles.map((item) => {
                return {
                  value: item.key,
                  label: item.key.replace('_', ' ').toTitleCase(),
                };
              });
            }
          });
        };
        const fetchSharedFileImports = () => {
          requestWithAuth(
            'field-lookup?key=team_file_imports',
            'GET',
            null,
            null,
            null,
          )
            .then((response) => {
              if (response && response.result && response.result.length) {
                const filesWithoutEnrich = [];
                const addImportsToTeam = [];
                props.dataStore.lookUpLists.teamFileImports =
                  response.result.map((file) => {
                    if (file && file.add_import_profiles_to_team) {
                      addImportsToTeam.push({
                        label: file.import_name,
                        value: file.id,
                        isEnrich: file.data_enrichment_only,
                        owner: file.owner_name,
                        ownerId: file.owner_id,
                        b2bId: file.owner_b2b_id,
                        b2cId: file.owner_b2c_id,
                      });
                    }
                    if (file && !file.data_enrichment_only) {
                      filesWithoutEnrich.push({
                        label: file.import_name,
                        value: file.id,
                        isEnrich: file.data_enrichment_only,
                        owner: file.owner_name,
                        ownerId: file.owner_id,
                        b2bId: file.owner_b2b_id,
                        b2cId: file.owner_b2c_id,
                      });
                    }
                    return {
                      label: file.import_name,
                      value: file.id,
                      isEnrich: file.data_enrichment_only,
                      owner: file.owner_name,
                      ownerId: file.owner_id,
                      b2bId: file.owner_b2b_id,
                      b2cId: file.owner_b2c_id,
                    };
                  });
                props.dataStore.lookUpLists.addImportsToTeam = addImportsToTeam;
                props.dataStore.lookUpLists.teamFileImportsWithoutEnrich =
                  filesWithoutEnrich;

                props.dataStore.lookUpLists.addImportsToTeam.sort(
                  compareLabels,
                );
                props.dataStore.lookUpLists.teamFileImports.sort(compareLabels);
                props.dataStore.lookUpLists.teamFileImportsWithoutEnrich.sort(
                  compareLabels,
                );
              }
              if (
                (!props.dataStore.networkFilters.team_file_imports ||
                  props.dataStore.networkFilters.team_file_imports.length <=
                    0) &&
                props.dataStore.networkFilters.filter_type !== 'all'
              ) {
                const lookupToChoose =
                  props.dataStore.networkFilters.filter_type === 'first_degree'
                    ? props.dataStore.lookUpLists.addImportsToTeam
                    : props.dataStore.lookUpLists.teamFileImportsWithoutEnrich;

                if (!props.dataStore.importSourceKeys.isImportSourceSaved) {
                  props.dataStore.networkFilters.team_file_imports =
                    lookupToChoose.map((item) => {
                      if (item.value) return item.value;
                    });
                } else {
                  const teamFiles = [];

                  lookupToChoose.map((file) => {
                    if (
                      file &&
                      file.value &&
                      props.dataStore.networkFilters.team_file_imports.includes(
                        file.value,
                      )
                    )
                      teamFiles.push(file.value);
                  });
                  props.dataStore.networkFilters.team_file_imports = teamFiles;
                }

                props.dataStore.networkFilters.fetchData = true;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        };

        const fetchTeamMembers = () => {
          props.dataStore.lookUpLists.teamAccounts = [];
          props.dataStore.lookUpLists.teamMemberIds = [];
          const obj = {};
          obj.name = 'Me';
          obj.id = null;
          props.dataStore.lookUpLists.teamAccounts.push(obj);
          requestWithAuth('accounts', 'GET', { page_size: 100 }, null, null)
            .then((response) => {
              if (response && response.results && response.results.length > 0) {
                props.dataStore.lookUpLists.team = response.results;
                response.results.map((team) => {
                  const object = {};
                  object.name = team.name;
                  object.id = team.id;
                  props.dataStore.lookUpLists.teamAccounts.push(object);
                  // mapping team.users to get team member ids
                  if (team.users && team.users.length)
                    team.users.map((members) => {
                      props.dataStore.lookUpLists.teamMemberIds.push(
                        members.id,
                      );
                      props.dataStore.lookUpLists.teamMembers.push(members);
                    });
                });
                // remove duplicate ids
                props.dataStore.lookUpLists.teamMemberIds = [
                  ...new Set(props.dataStore.lookUpLists.teamMemberIds),
                ];
                props.dataStore.lookUpLists.teamMembers =
                  props.dataStore.lookUpLists.teamMembers.filter(
                    (value, index, self) =>
                      index === self.findIndex((t) => t.id === value.id),
                  );
                props.dataStore.lookUpLists.teamMembers.sort(compare);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        };

        /* api for getting items
              in title rank dropdown */
        const fetchTitleRankAggs = () => {
          props.dataStore.lookUpLists.titleRankList = titleRankList;
        };

        const renderPremiumTriggerLabel = (event) => {
          return (
            <div className="ai-prem-icon-inline">
              <span>{event}</span>
              <PremiumLabel
                icon={true}
                iconColor="premium-green"
                bgColor="transparent"
                label={false}
                outline={false}
              />
            </div>
          );
        };

        /* api for getting items in events drop down */
        const fetchEventsAggs = () => {
          const eventTypes = [];
          requestWithAuth(
            'field-lookup',
            'GET',
            { key: 'event_types' },
            null,
            null,
          )
            .then((response) => {
              if (response && response.result && response.result.length) {
                let events = response.result;

                if (!flags || !flags.dntfd8665AttomHomeListings) {
                  events = events.filter((e) => e !== 'property listing');
                }

                // keep this within the scope of the response checking because there can be a
                // race condition where this evaluates to false when the flag is active.
                const newProfileActive = flags && flags.dntfd8933NewProfileView;

                events.map((event) => {
                  if (event) {
                    const eventName = formatEventName(event);
                    const premiumTrigger = isPremiumWealthTrigger(event);

                    const unsafeHtml =
                      newProfileActive && premiumTrigger && !hasPremiumTriggers
                        ? renderToString(renderPremiumTriggerLabel(eventName))
                        : eventName;

                    eventTypes.push({
                      value: event,
                      label: eventName,
                      isPremium: newProfileActive && premiumTrigger,
                      unsafeHtml: unsafeHtml,
                      isDisabled: newProfileActive
                        ? premiumTrigger && !hasPremiumTriggers
                        : false,
                      text: eventName,
                    });
                  }
                  return '';
                });
              }
              eventTypes.unshift({
                unsafeHtml: 'All',
                value: 'all',
                isDisabled: false,
                isPremium: false,
                label: 'All',
                text: 'All',
              });
              props.dataStore.lookUpLists.eventList = eventTypes;
            })
            .catch((error) => {
              console.log(error);
            });
        };

        const fetchIndustries = () => {
          const industries = [];
          industryTypes.map((industry) => {
            industries.push({
              value: industry,
              label: industry.toTitleCase(),
            });
          });
          props.dataStore.lookUpLists.industries = industries;
        };

        // XX: 2024-11-08 dkoch: push notifications are not working -- it needs
        // the service worker to be set up, which can be done after the app
        // is moved off of create-react-app. Also, the send wealth notifications
        // script in the API project needs to be tested.
        //
        // useEffect(() => {
        //   if (
        //     props.dataStore.currentUser.type !== 'lite' &&
        //     props.dataStore.currentUser.type !== 'inactive'
        //   ) {
        //     const messaging = getMessaging(firebaseApp);
        //     getToken(messaging, {
        //       vapidKey: VITE_FIREBASE_KEY,
        //     })
        //       .then((currentToken) => {
        //         if (currentToken) {
        //           // Send the token to server and update the UI if necessary
        //           requestWithAuth(
        //             'device-registration',
        //             'POST',
        //             { is_enabled: 1, platform: 'web' },
        //             { token: currentToken },
        //             null,
        //           )
        //             .then((response) => {})
        //             .catch((error) => {
        //               console.log(error);
        //             });
        //         } else {
        //           // Show permission request UI
        //           console.log(
        //             'No registration token available. Request permission to generate one.',
        //           );
        //         }
        //       })
        //       .catch((err) => {
        //         console.log('An error occurred while retrieving token. ', err);
        //       });
        //   }

        useEffect(() => {
          initialisePendo(props.dataStore.currentUser);
        }, []);

        useEffect(() => {
          if (!props.dataStore.currentUser.isExistingUser) {
            const values = {
              is_first_time: true,
            };
            updateUserInfo(props.dataStore.currentUser.ownerId, values).then(
              () => {
                props.dataStore.currentUser.isReg = true;
                props.dataStore.currentUser.isExistingUser = true;
              },
            );
          }

          const { CancelToken } = axios;
          source = CancelToken.source();
          document.addEventListener(
            visibilityChange,
            handleVisibilityChange,
            false,
          );

          if (props.dataStore.currentUser.type !== 'inactive')
            fetchTeamMembers();

          if (
            props.dataStore.currentUser.type !== 'lite' &&
            props.dataStore.currentUser.type !== 'inactive'
          ) {
            getUnseenNotificationCount();
            fetchIndustries();
            fetchTitleRankAggs();
            fetchEventsAggs();
            fetchFileImports();
            fetchTitleRole();
            fetchSharedFileImports();
            fetchUserTags();
            fetchTagFolders();
            fetchSharedTags();
          }
          return () => {
            document.removeEventListener('click', hideDropdownMenu);
            document.removeEventListener(
              visibilityChange,
              handleVisibilityChange,
            );
            if (source) source.cancel('Operation canceled by the user.');
          };
        }, [props.dataStore.currentUser.type]);

        const handleSubscriptionCancel = () => {
          requestWithAuth('maxio-cancel-trial', 'POST', null, null, null).then(
            (response) => {
              if (response && response.code === 200) {
                if (response.message) message.success(response.message);
              } else if (response && response.errorCode)
                message.error(response.errorCode, 10);
            },
          );
        };

        /** Method to call usertype api to save people table column order and  prospect finder filter tabs order
         * It was not saving if we signout from prospect finder table
         */
        const saveUserSelections = () => {
          if (pathname === '/prospect-finder/people') {
            updateUserInfo(props.dataStore.currentUser.ownerId, {
              prospect_tab_order: props.dataStore.currentUser.userTabOrder,
              prospect_header_order:
                props.dataStore.currentUser.userSelectedColumns.filter(
                  (item) =>
                    item !== 'relationship_status' &&
                    item !== 'relationship_type',
                ),
            });
          }
        };

        // method to handle sign out action
        const signout = () => {
          saveUserSelections();

          localStorage.removeItem('tempNetworkFilters');
          localStorage.removeItem('crm_source');
          localStorage.removeItem('redirectionPath');
          localStorage.removeItem('aidentified.pfFinderHelp');
          localStorage.removeItem('aidentified.theme');
          setAuthority('guest');
          props.dataStore.reset();
          props.globalDataStore.reset();
          props.globalDataStore.resetMultipleFilters();
          posthog.reset();
          logout({
            returnTo: `${getDomain()}/user/login`,
          });
        };

        const getLogoClassName = () => {
          switch (slug) {
            case 'homescout':
              return 'homescout-logo-icon';
            case 'nexis':
              return 'nexis-logo-icon';
            case 'blueridge':
              return 'blueridge-logo-icon';
            default:
              return 'logo-icon';
          }
        };

        return (
          <>
            <div
              className={
                flags && flags.dntfd8933NewProfileView
                  ? 'header-parent-new no-print'
                  : 'header-parent no-print'
              }
            >
              <header>
                <div className="logo">
                  <NavLink to="/relationship-intelligence">
                    <img
                      src={getComputedStyle(document.body).getPropertyValue(
                        '--url-logo',
                      )}
                      className={getLogoClassName()}
                      alt="logo"
                    />
                  </NavLink>
                </div>

                <GoogleSearch
                  value={props.dataStore.quick_search}
                  from={
                    flags && flags.dntfd8933NewProfileView ? 'header' : null
                  }
                />

                {flags && flags.dntfd8933NewProfileView ? (
                  <NavigationBar />
                ) : (
                  <>
                    <NavLink
                      to="/relationship-intelligence"
                      className={({ isActive }) =>
                        isActive ? 'navigation selected-nav' : 'navigation'
                      }
                    >
                      <InsightsIcon />
                      <span className="navigation-text">
                        Relationship Intelligence
                      </span>
                    </NavLink>

                    <NavLink
                      to="/prospect-finder"
                      className={({ isActive }) =>
                        isActive ? 'navigation selected-nav' : 'navigation'
                      }
                    >
                      <ProspectFinderIcon />
                      <span className="navigation-text">Prospect Finder</span>
                    </NavLink>
                    <NavLink
                      to="/savedsearches"
                      className={({ isActive }) =>
                        isActive ? 'navigation selected-nav' : 'navigation'
                      }
                    >
                      <FilterIcon />
                      <span className="navigation-text">Saved Searches</span>
                    </NavLink>
                    <NavLink
                      to="/connect"
                      className={({ isActive }) =>
                        isActive ? 'navigation selected-nav' : 'navigation'
                      }
                    >
                      <div>
                        <ConnectIcon />
                        <div className="right-text">Import/Export</div>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/notifications"
                      className={({ isActive }) =>
                        isActive ? 'navigation selected-nav' : 'navigation'
                      }
                    >
                      <div>
                        {props.globalDataStore.notificationCount > 0 && (
                          <div className="number-of-notifications">
                            {props.globalDataStore.notificationCount > 99
                              ? '99+'
                              : props.globalDataStore.notificationCount}
                          </div>
                        )}
                        <AlertIcon />
                        <div className="right-text">Notifications</div>
                      </div>
                    </NavLink>
                    <div
                      onClick={(e) => {
                        props.modalDataStore.resetAll();
                        setDisplayMenu(true);
                        e.stopPropagation();
                      }}
                      className="user-div"
                    >
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            height: '25px',
                          }}
                        >
                          <ProfileIcon
                            style={{
                              position: 'absolute',
                              zIndex: 1,
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </div>
                        <div className="current-user">Settings</div>
                      </div>
                    </div>
                  </>
                )}
              </header>
            </div>
            {displayMenu && (
              <div className="user-menu">
                <ul>
                  <li
                    className="user-menu-list"
                    onClick={() => {
                      navigate('/account/profile');
                    }}
                  >
                    <div>Profile</div>
                  </li>
                  <li
                    className="user-menu-list"
                    onClick={() => {
                      navigate('/account/security');
                    }}
                  >
                    <div>Security</div>
                  </li>
                  {/** if nexis_admin is
                   * undefined/null => normal user
                     true/false => nexis user */}
                  {typeof props.dataStore.currentUser.nexis_admin !=
                    'boolean' &&
                    !props.dataStore.currentUser.isCorporate &&
                    !props.dataStore.currentUser.isAdmin &&
                    props.dataStore.currentUser.type !== 'lite' &&
                    props.dataStore.currentUser.type !== 'inactive' &&
                    !isBlueridge && (
                      <>
                        {props.dataStore.currentUser.billingPortalUrl ? (
                          <li className="user-menu-list">
                            <a
                              href={
                                props.dataStore.currentUser.billingPortalUrl
                              }
                              target="_blank"
                              style={{ color: 'var(--color-black)' }}
                              rel="noreferrer"
                            >
                              <div>Subscription</div>
                            </a>
                          </li>
                        ) : (
                          <li
                            className="user-menu-list"
                            onClick={() => {
                              navigate('/account/plans');
                            }}
                          >
                            <div>Plans</div>
                          </li>
                        )}
                        {props.dataStore.currentUser.billingPortalUrl &&
                          props.dataStore.currentUser.isTrial && (
                            <li
                              className="user-menu-list"
                              onClick={() => {
                                showConfirm({
                                  className: 'small-popup',
                                  closable: true,
                                  content: <h4>Do you want to do this?</h4>,
                                  okText: 'Yes',
                                  onOk: () => {
                                    handleSubscriptionCancel();
                                  },
                                });
                              }}
                            >
                              <div>Cancel Subscription</div>
                            </li>
                          )}
                      </>
                    )}

                  <li
                    className="user-menu-list"
                    onClick={() => {
                      navigate('/account/credits');
                    }}
                  >
                    <div>Credits</div>
                  </li>

                  <li className="user-menu-list">
                    <a
                      href={
                        typeof props.dataStore.currentUser.nexis_admin ===
                        'boolean'
                          ? 'https://supportcenter.lexisnexis.com/app/wealth_navigator'
                          : 'https://support.aidentified.com/'
                      }
                      style={{ color: 'initial' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>Support</div>
                    </a>
                  </li>
                  <li className="user-menu-list">
                    <a
                      href={'https://www.aidentified.com/privacy-policy/ '}
                      style={{ color: 'initial' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>Privacy</div>
                    </a>
                  </li>

                  <li className="user-menu-list">
                    <a
                      href={
                        props.dataStore.currentUser.isNorthWesternMutual
                          ? 'https://www.aidentified.com/nm-terms-conditions '
                          : 'https://www.aidentified.com/terms-conditions '
                      }
                      style={{ color: 'initial' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>Terms &amp; Conditions</div>
                    </a>
                  </li>

                  {typeof props.dataStore.currentUser.nexis_admin ===
                    'boolean' && (
                    <li className="user-menu-list">
                      <a
                        href="https://supportcenter.lexisnexis.com/app/forms/wealth_navigator"
                        style={{ color: 'initial' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div>Contact Us</div>
                      </a>
                    </li>
                  )}
                  <li className="user-menu-list" onClick={signout}>
                    <div>Sign Out</div>
                  </li>
                </ul>
              </div>
            )}
          </>
        );
      }),
    ),
  ),
);

export default Header;
